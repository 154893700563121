/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/


body {
	font-family: 'Roboto', sans-serif;
	color: #555555;
	background-color: #f5f5f5;
}
h1 { 
    font-size: 36px;
  }
  
  h2 {
    font-size: 28px;
  }
  
  h3 {
    font-size: 24px;
  }
  
  h4 {
    font-size: 20px;
  }
  
  h5 {
    font-size: 18px;
  }
  
  h6 {
    font-size: 16px;
  }
  
  b,
  strong {
    font-weight: 700;
  }

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000000;
}

a {
	color: #000000;
	transition: all .5s ease;
}

a:hover {
	color: #F58220;
	text-decoration: none;
}

.link-two {
	color: #000000;
	transition: all .5s ease;
}
a.navbar-brand.text-brand img {
	width: 180px;
}
li{ list-style: none;}
.link-two:hover {
	text-decoration: underline;
	color: #000000;
}

.form-control { border:1px solid #ced4da; background: #fff;}

.link-one {
	color: #000000;
	transition: all .5s ease;
}

.link-one:hover {
	color: #000000;
	text-decoration: none;
}

.link-icon {
	color: #000000;
	font-weight: 500;
}

.link-icon span {
	font-size: 14px;
	padding-left: 4px;
	vertical-align: middle;
}

.link-a {
	color: #ffffff;
	text-decoration: none;
}

.myselectclass::-ms-expand{display:none}
.myselectclass{-webkit-appearance:none;background:url(https://www.franchiseindia.com/images/drop-down-icon.png) 95% center no-repeat #fbfbfb!important}
.myselectclasscat{-webkit-appearance:none;text-indent:0;background:url(https://www.franchiseindia.com/images/drop-down-icon.png) 95% center no-repeat #fff!important}

::-webkit-scrollbar{-webkit-appearance:none;width:7px;background:#e5e5e5}
::-webkit-scrollbar-thumb{border-radius:4px;background-color:#bababa;-webkit-box-shadow:0 0 1px #bababa}
::-webkit-input-placeholder{color:#666; font-size: 14px;}
:-moz-placeholder{color:#666;font-size: 14px;}
:-ms-input-placeholder{color:#666;font-size: 14px;}
:placeholder-shown{color:#666;font-size: 14px;}
.form-control { font-size: 14px;color:#666; height: 45px; }
.height70 { height: 70px;}
@media (min-width: 768px) {
	.link-a {
		font-size: .9rem;
	}
}

@media (min-width: 992px) {
	.link-a {
		font-size: 1rem;
	}
}

.link-a:hover {
	color: #ffffff;
	text-decoration: none;
}

.link-a span {
	font-size: 18px;
	vertical-align: middle;
	margin-left: 5px;
}

@media (min-width: 768px) {
	.link-a span {
		font-size: 1.5rem;
	}
}

@media (min-width: 992px) {
	.link-a span {
		font-size: 18px;
	}
}

.text-brand {
	color: #000000;
	font-size: 2rem;
	font-weight: 600;
}

@media (max-width: 767px) {
	.text-brand {
		font-size: 1.8rem;
	}
}

@media (min-width: 1230px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1216px!important;
}
}




.color-a {
	color: #000000;
}

.color-b {
	color: #F58220;
}

.color-d {
	color: #adadad;
}

.color-text-a {
	color: #555555;
}

.no-margin {
	margin: 0;
}


/*------/ Nav Pills  /------*/

.nav-pills-a.nav-pills .nav-link {
	color: #000000;
	position: relative;
	font-weight: 600;
}

.nav-pills-a.nav-pills .nav-link.active {
	background-color: transparent;
}

.nav-pills-a.nav-pills .nav-link.active:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 100%;
	height: 2px;
	background-color: #F58220;
	z-index: 2;
}


/*------/ Bg Image /------*/

.bg-image {
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: center center;
}


/*------/ List a /------*/

.list-a {
	display: inline-block;
	line-height: 2;
	padding: 0;
	list-style: none;
}

.list-a li {
	position: relative;
	width: 50%;
	float: left;
	padding-left: 25px;
	padding-right: 5px;
}

@media (min-width: 992px) {
	.list-a li {
		width: 33.333%;
	}
}

.list-a li:before {
	content: '';
	width: 10px;
	height: 2px;
	position: absolute;
	background-color: #313131;
	top: 15px;
	left: 0;
}


/*------/ Pagination-a /------*/

.pagination-a .pagination .page-link {
	margin: 0 .2rem;
	border-color: transparent;
	padding: .5rem .8rem;
	color: #000000;
}

.pagination-a .pagination .page-link:hover,
.pagination-a .pagination .page-link:active,
.pagination-a .pagination .page-link:focus {
	background-color: #F58220;
}

.pagination-a .pagination .page-link span {
	font-size: 1.2rem;
}

.pagination-a .pagination .next .page-link {
	padding: .4rem .9rem;
}

.pagination-a .pagination .page-item:last-child .page-link {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.pagination-a .pagination .page-item:first-child .page-link {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.pagination-a .pagination .page-item.disabled .page-link {
	padding: .4rem .9rem;
	color: #555555;
}

.pagination-a .pagination .page-item.active .page-link {
	background-color: #F58220;
}

.a {
	color: #F58220;
	font-size: 1.3rem;
}


/*------/ Icon Box /------*/

.icon-box .icon-box-icon {
	display: table-cell;
	vertical-align: top;
	font-size: 36px;
	color: #000000;
	width: 50px;
	padding-top: 8px;
}

.icon-box .icon-box-content {
	padding-top: 18px;
}


/*------/ Space Padding /------*/

.section-t8 {
	padding-top: 8rem;
}

@media (max-width: 767px) {
	.section-t8 {
		padding-top: 4rem;
	}
}

.section-t4 {
	padding-top: 4rem;
}

@media (max-width: 767px) {
	.section-t4 {
		padding-top: 2rem;
	}
}

.section-t3 {
	padding-top: 3rem;
}
.section-t2 {
	padding-top: 2rem;
}

@media (max-width: 767px) {
	.section-t2 {
		padding-top: 2rem;
	}
}

@media (max-width: 767px) {
	.section-md-t3 {
		padding-top: 3rem;
	}
	
}

.section-b2 {
	padding-bottom: 2rem;
}

.section-tb85 {
	padding: 8rem 0 5rem 0;
}

@media (max-width: 767px) {
	.section-tb85 {
		padding: 4rem 0 2.5rem 0;
	}
}


/*------/ Title /------*/

.title-wrap {
	padding-bottom: 2rem;
}

.title-a {
	font-size: 2.6rem;
	font-weight: 600;
}

.title-link {
	font-size: 1.2rem;
	font-weight: 300;
	padding-top: 1.2rem;
}

.title-link a {
	color: #313131;
}

.title-link span {
	font-size: 20px;
	padding-left: 4px;
	vertical-align: middle;
}

.title-box-d {
	padding-bottom: 1.8rem;
	margin-bottom: 1rem;
	position: relative;
}

.title-box-d .title-d {
	font-weight: 600;
	font-size: 2rem;
}

.title-box-d .title-d:after {
	content: '';
	position: absolute;
	width: 70px;
	height: 4px;
	background-color: #F58220;
	bottom: 20px;
	left: 0;
}


/*------/ Display Table & Table Cell /------*/

.display-table {
	width: 100%;
	height: 100%;
	display: table;
}

.table-cell {
	display: table-cell;
	vertical-align: middle;
}


/*------/ Ul Resect /------*/

.ul-resect ul,
.list-a ul,
.box-comments ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}


/*------/ Overlay /------*/

.overlay-a {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
}

.overlay {
	position: absolute;
	top: 0;
	left: 0px;
	padding: 0;
	height: 100%;
	width: 100%;
}


/*------/ Owl carousel /------*/

.owl-theme .owl-dots {
	text-align: center;
	margin-top: 18px;
}

.owl-theme .owl-dots .owl-dot {
	display: inline-block;
	    border: none;
    outline: none;
}

.owl-theme .owl-dots .owl-dot span {
	width: 16px;
	height: 9px;
	margin: 5px 5px;
	background: #000000;
	border: 0px solid #000000;
	display: block;
	transition: all 0.6s ease-in-out;
	cursor: pointer;
	border-radius: 0px;
}

.owl-theme .owl-dots .owl-dot:hover span {
	background-color: #F58220;
}

.owl-theme .owl-dots .owl-dot.active span {
	background-color: #F58220;
	width: 35px;
}

.owl-arrow .owl-nav {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1.8rem;
}

.owl-arrow .owl-nav .owl-prev,
.owl-arrow .owl-nav .owl-next {
	display: inline-block;
	transition: all 0.6s ease-in-out;
	color: #000000;
}

.owl-arrow .owl-nav .owl-prev.disabled,
.owl-arrow .owl-nav .owl-next.disabled {
	transition: all 0.6s ease-in-out;
	color: #adadad;
}

.owl-arrow .owl-nav .owl-next {
	margin-left: 15px;
}

.nav-arrow-a .owl-arrow .owl-nav {
	font-size: 1.8rem;
	margin-top: -43px !important;
	padding-right: 0px;
}

.nav-arrow-a .owl-arrow .owl-nav .owl-next {
	margin-left: 40px;
}

.nav-arrow-b .owl-arrow .owl-nav {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1.2rem;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev,
.nav-arrow-b .owl-arrow .owl-nav .owl-next {
	padding: .7rem 1.5rem !important;
	display: inline-block;
	transition: all 0.6s ease-in-out;
	color: #ffffff;
	background-color: #F58220;
	opacity: .9;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev.disabled,
.nav-arrow-b .owl-arrow .owl-nav .owl-next.disabled {
	transition: all 0.6s ease-in-out;
	color: #ffffff;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev:hover,
.nav-arrow-b .owl-arrow .owl-nav .owl-next:hover {
	background-color: #26a356;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-next {
	margin-left: 0px;
}


/*------/ Socials /------*/

.socials-a .list-inline-item:not(:last-child) {
	margin-right: 25px;
}


/* Back to top button */

.back-to-top {
	position: fixed;
	display: none;
	background: #F58220;
	color: #fff;
	width: 44px;
	height: 44px;
	text-align: center;
	line-height: 1;
	font-size: 16px;
	border-radius: 50%;
	right: 15px;
	bottom: 15px;
	transition: background 0.5s;
	z-index: 11;
}

.back-to-top i {
	padding-top: 12px;
	color: #fff;
}

@media (max-width: 768px) {
	.back-to-top {
		bottom: 15px;
	}
}


/* Prelaoder */

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #fff;
}

#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #f2f2f2;
	border-top: 6px solid #F58220;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1s linear infinite;
	animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


/*======================================
//--//-->   BUTTON
======================================*/

.btn {
	transition: .5s ease;
}

.btn.btn-a,
.btn.btn-b {
	border-radius: 0;
	padding: 1rem 3rem;
	letter-spacing: .05rem;
}

.btn.btn-a {
	background-color: #000000;
	color: #ffffff;
}

.btn.btn-a:hover {
	background-color: #F58220;
	color: #000000;
}

.btn.btn-b {
	background-color: #F58220;
	color: #000000;
}

.btn.btn-b:hover {
	background-color: #000000;
	color: #ffffff;
}

.btn.btn-b-n {
	background-color: #F5822000;
	color: #000000;
	border-radius: 0;
}

.btn.btn-b-n:hover {
	background-color: #F58220;
	color: #ffffff;
}


/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/

.navbar-default {
	transition: all .5s ease-in-out;
	background-color: #ffffff;
	padding-top: 19px;
	padding-bottom: 19px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
}

.navbar-default .nav-search {
	color: #000000;
	font-size: 1.5rem;
}

.navbar-default.navbar-reduce {
	box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
}

.navbar-default.navbar-trans,
.navbar-default.navbar-reduce {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.navbar-default.navbar-trans .nav-item,
.navbar-default.navbar-reduce .nav-item {
	position: relative;
	padding-right: 10px;
	padding-bottom: 8px;
	margin-left: 0;
}

@media (min-width: 768px) {
	.navbar-default.navbar-trans .nav-item,
	.navbar-default.navbar-reduce .nav-item {
		margin-left: 15px;
		margin-right: 10px;
		padding: 0;
	}
}

.headblk .navbar-default.navbar-trans .nav-link,
.headblk .navbar-default.navbar-reduce .nav-link {
	font-size: 14px;
	color: #231F20;
	/* font-weight: 600; */
	letter-spacing: 0.030em;
	transition: all 0.1s ease-in-out;
	position: relative;
	padding-left: 0;
	padding-right: 0;
}


.headblk .navbar-expand-lg .navbar-collapse {    padding-left: 0px;}





.navbar-default.navbar-trans .nav-link:before,
.navbar-default.navbar-reduce .nav-link:before {
	content: '';
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 100%;
	height: 2px;
	z-index: 0;
	background-color: #F58220;
	transform: scaleX(0);
	transform-origin: right;
	transition: transform .2s ease-out, opacity .2s ease-out 0.3s;
}

.navbar-default.navbar-trans .nav-link:hover,
.navbar-default.navbar-reduce .nav-link:hover {
	color: #000000;
}

.navbar-default.navbar-trans .nav-link:hover:before,
.navbar-default.navbar-reduce .nav-link:hover:before {
	transform: scaleX(1);
	transform-origin: left;
}

.navbar-default.navbar-trans .show>.nav-link:before,
.navbar-default.navbar-trans .active>.nav-link:before,
.navbar-default.navbar-trans .nav-link.show:before,
.navbar-default.navbar-trans .nav-link.active:before,
.navbar-default.navbar-reduce .show>.nav-link:before,
.navbar-default.navbar-reduce .active>.nav-link:before,
.navbar-default.navbar-reduce .nav-link.show:before,
.navbar-default.navbar-reduce .nav-link.active:before {
	transform: scaleX(1);
}

.navbar-default.navbar-trans .nav-link:before {
	background-color: #F58220;
}

.navbar-default.navbar-trans .nav-link:hover {
	color: #000000;
}

.navbar-default.navbar-trans .show>.nav-link,
.navbar-default.navbar-trans .active>.nav-link,
.navbar-default.navbar-trans .nav-link.show,
.navbar-default.navbar-trans .nav-link.active {
	color: #000000;
}

.navbar-default.navbar-reduce {
	transition: all .5s ease-in-out;
	padding-top: 19px;
	padding-bottom: 19px;
}

.navbar-default.navbar-reduce .nav-link {
	color: #000000;
}

.navbar-default.navbar-reduce .nav-link:before {
	background-color: #F58220;
}

.navbar-default.navbar-reduce .nav-link:hover {
	color: #000000;
}

.navbar-default.navbar-reduce .show>.nav-link,
.navbar-default.navbar-reduce .active>.nav-link,
.navbar-default.navbar-reduce .nav-link.show,
.navbar-default.navbar-reduce .nav-link.active {
	color: #000000;
}

.navbar-default.navbar-reduce .navbar-brand {
	color: #000000;
}

.navbar-default .dropdown .dropdown-menu {
	border-top: 0;
	border-left: 4px solid #F58220;
	border-right: 0;
	border-bottom: 0;
	transform: translate3d(0px, -40px, 0px);
	opacity: 0;
	filter: alpha(opacity=0);
	visibility: hidden;
	transition: all 0.5s cubic-bezier(0.3, 0.65, 0.355, 1) 0s, opacity 0.31s ease 0s, height 0s linear 0.36s;
	margin: 0;
	border-radius: 0;
	padding: 12px 0;
}

.bex-bussiness-section h4 { font-size: 20px; line-height: 24px;  color: #231F20;}
.bex-bussiness-section p.shotxt{ font-size: 14px; line-height: 20px; color: #616161;
    padding: 0 25px;
}

.bex-features-section-main { padding: 7px;}

.stxt{color: #231F20;  opacity: 0.60;  font-size: 12px; line-height: 14px;}
.shead { font-size: 16px; line-height: 18px; font-weight: 400; color: #231F20;}


/* Start here  old css for my account */

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
    padding: 15px;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel-heading > .dropdown .dropdown-toggle {
    color: inherit;
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}
.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
    color: inherit;
}
.panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
    margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.list-group + .panel-footer,
.panel-heading + .list-group .list-group-item:first-child {
    border-top-width: 0;
}
.panel > .panel-collapse > .table,
.panel > .table,
.panel > .table-responsive > .table {
    margin-bottom: 0;
}
.panel > .panel-collapse > .table caption,
.panel > .table caption,
.panel > .table-responsive > .table caption {
    padding-right: 15px;
    padding-left: 15px;
}
.panel > .table-responsive:first-child > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child {
    border-top-left-radius: 3px;
}
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child {
    border-top-right-radius: 3px;
}
.panel > .table-responsive:last-child > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
}
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
    border-top: 1px solid #ddd;
}
.panel > .table > tbody:first-child > tr:first-child td,
.panel > .table > tbody:first-child > tr:first-child th {
    border-top: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
    border: 0;
}
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
    border-left: 0;
}
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
    border-right: 0;
}
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th {
    border-bottom: 0;
}
.panel > .table-responsive {
    margin-bottom: 0;
    border: 0;
}
.panel-group {
    margin-bottom: 20px;
}
.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}
.panel-group .panel + .panel {
    margin-top: 5px;
}
.panel-group .panel-heading {
    border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .list-group,
.panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: 1px solid #ddd;
}
.panel-group .panel-footer {
    border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
    border-bottom: 1px solid #ddd;
}
.panel-default {
    border-color: #ddd;
}
.panel-default > .panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ddd;
}
.panel-default > .panel-heading .badge {
    color: #f5f5f5;
    background-color: #333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ddd;
}
.panel-primary {
    border-color: #337ab7;
}
.panel-primary > .panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #337ab7;
}
.panel-primary > .panel-heading .badge {
    color: #337ab7;
    background-color: #fff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #337ab7;
}
.panel-success {
    border-color: #d6e9c6;
}
.panel-success > .panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #d6e9c6;
}
.panel-success > .panel-heading .badge {
    color: #dff0d8;
    background-color: #3c763d;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #d6e9c6;
}
.panel-info {
    border-color: #bce8f1;
}
.panel-info > .panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #bce8f1;
}
.panel-info > .panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #bce8f1;
}
.panel-warning {
    border-color: #faebcc;
}
.panel-warning > .panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #faebcc;
}
.panel-warning > .panel-heading .badge {
    color: #fcf8e3;
    background-color: #8a6d3b;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #faebcc;
}
.panel-danger {
    border-color: #ebccd1;
}
.panel-danger > .panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ebccd1;
}
.panel-danger > .panel-heading .badge {
    color: #f2dede;
    background-color: #a94442;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ebccd1;
}
.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
    padding-bottom: 75%;
}

.myAccount .cat-list .edit-business ul {
    display: none;
}


.myAccount .tab-content.cat-list {background: #fff;}



.myAccount .cat-list .edit-business .open > .dropdown-menu {
    display: block !important;
}
.myAccount .cat-list .edit-business .b-edit {
    border: 1px solid #1b9f84;
    margin-top: 20px;
    text-decoration: none;
    color: #1b9f84;
    padding: 2px 0 2px 5px;
    display: block;
    width: 70px;
}
.myAccount .cat-list .edit-business .b-edit .caret {
    float: right;
    margin-top: 8px;
    margin-right: 5px;
}
.myAccount .cat-list ul li .investment-price.mdy-flt {
    clear: both;
    float: left;
}
.myAccount .cat-list .edit-business ul li {
    margin: 0;
    padding-bottom: 0;
    border: 0;
}
.myAccount .cat-list .edit-business .dropdown-menu {
    left: inherit;
    right: 0;
    border-radius: 0;
}
.myAccount .paymentsec .price.free {
    padding: 23px 0 28px;
}
.form-sec .paymentsec.active .paymentplan .recom {
    font-size: 12px;
    color: #1b9f84;
}
.form-sec .paymentsec .price .sub-desc {
    color: #666;
    font-size: 14px;
    margin-top: -5px;
}
.form-sec .paymentsec .price span {
    color: #666;
    display: inline-block;
}
.form-sec .paymentsec .price span.amnt {
    color: #333;
    font-weight: 600;
}
.form-sec .paymentsec .price span.mo {
    font-size: 22px;
}
.form-sec .paymentsec .features {
    font-size: 14px;
    height: 225px;
    color: #666;
    border: 1px solid #e5e5e5;
    background-color: #fafafa;
}
.form-sec .paymentsec.inves .features {
    height: 775px;
}
.myAccount .paymentsec.inves .features {
    height: auto;
    min-height: 420px;
}
.myAccount .paymentsec.inves .features.height-modi {
    min-height: auto;
    padding-bottom: 20px;
}
.form-sec .paymentsec .features .add-plus {
    padding: 5px 15px 5px 24px;
    background-color: #f4f4f4;
    font-size: 14px;
    border-bottom: 1px solid #e5e5e5;
    text-align: center;
}
.form-sec .paymentsec .features ul {
    padding: 0 15px 0 24px;
}
.form-sec .paymentsec .features ul li {
    padding-top: 10px;
    line-height: 17px;
}
.form-sec .payment-plan .col-md-3 {
    padding: 0 10px;
}
.form-sec .bdr-btm {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 20px;
}
.form-sec .terms {
    padding-top: 20px;
    font-size: 16px;
    color: #666;
}
.form-sec .terms a {
    color: #1b9f84;
    text-decoration: underline;
}
.form-sec .submitfrm .btn-blue {
    margin-top: 15px;
    font-size: 30px;
    padding: 9px 38px;
    margin-bottom: 25px;
}
.myAccount .primiumtag {
    left: 2px;
}
.myAccount .recommendations .primiumtag {
    left: 5px;
}
.recommendations .primiumtag img {
    max-width: 60%;
}
.myAccount {
    margin-top: 120px;
}
.myAccount .col-md-3.mdy-width-23 {
    width: 23%;
}
.myAccount .col-md-6.mdy-width-49 {
    width: 49.3%;
}
.myAccount .col-md-3.mdy-width-27 {
    width: 27.7%;
}
.myAccount .col-md-9.mdy-width-77 {
    width: 77%;
}
.myAccount .investor-profile {
    position: relative;
}
.myAccount .investor-profile img {
    width: 100%;
}
.myAccount .investor-profile .band {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
}
.myAccount .investor-profile .band .social {
    float: left;
}
.myAccount .investor-profile .band .camera-icn {
    float: right;
    cursor: pointer;
}
.myAccount .investor-profile .camera-icn {
    right: 10px;
}
.myAccount .investor-profile .camera-icn .file-upload {
    width: 30px;
    height: 24px;
    overflow: hidden;
    position: absolute;
    opacity: 0;
    bottom: 13px;
    cursor: pointer;
}
.myAccount .investor.menu-verify .social {
    float: right;
}
.myAccount .investor.menu-verify .social .facebook,
.myAccount .investor.menu-verify .social .linkedin,
.myAccount .investor.menu-verify .social .twitter {
    background-color: #1e1e1e;
    transition: all 0.5s ease;
    font-size: 14px;
    display: table;
    float: left;
    color: #fff;
    width: 22px;
    height: 22px;
    text-align: center;
    vertical-align: middle;
    margin-left: 1px;
}
.myAccount .investor.menu-verify .social a.active,
.myAccount .investor.menu-verify .social a:hover {
    background-color: #1b9f84;
}

.myAccount .left-menu {    width: 240px;
    background: #fff;
    padding: 10px;}

.recommendations {  background: #fff;}



.myAccount .bdr {
    border: 1px solid #e7e7e7;
        background: #fff;
        
}
.myAccount .col-md-3.mdy-width-27 .bdr { width: 300px;  }



.myAccount .investor-detail {
    display: block;
    padding: 20px;
    color: #333;
}
.myAccount .investor-detail .location {
    font-weight:400;
    font-size: 14px;
    padding-bottom: 5px;
}
.myAccount .investor-detail .location i {
    color: #1b9f84;
}
.myAccount .investor-detail .name {
    font-weight:700;
    font-size: 25px;
    line-height: 18px;
}
.myAccount .investor-detail .designation {
    color: #666;
    font-size: 16px;
    line-height: 18px;
    padding-top: 5px;
}

.myAccount .img-responsive { width: 100%;}

.myAccount .investor-detail .contact {
    font-size: 14px;
    color: #666;
    line-height: 16px;
    padding-top: 15px;
}
.myAccount .investor-detail .contact.padrm {
    padding-top: 0;
}
.myAccount .investor-detail .contact.padrm .btm-pad {
    padding-top: 0;
    margin-left: -4px;
}
.myAccount .investor-detail .contact.padrm .btm-pad select {
    border: 0;
    width: 100%;
    background: #dfdfdf;
        padding: 5px 10px;
    font-size: 14px;
    margin-top: 5px;
}
.bdr-detail,
.myAccount .cat-list ul li,
.myAccount .completed-profile {
    border-bottom: 1px solid #e7e7e7;
}

.profile-detail .edit-pfl {
    position: absolute;
    right: 23px;
    top: 6px;
    color: #adadad;
}

.left-menu  { position: relative;}

.myAccount .investor-detail .contact div {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}
.myAccount .investor-detail .upgrade {
    display: block;
    margin-top: 20px;
    text-align: center;
    padding: 7px 0;
    background-color: #1b9f84;
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    margin-bottom: 15px;
}
.myAccount .investor-detail .upgrade.edit {
    padding: 3px 0;
    width: 80px;
}
.myAccount .completed-profile {
    display: block;
    padding: 20px 1px;
    border-top: 1px solid #e7e7e7;
    overflow: hidden;
}
.myAccount .completed-profile .progress {
    margin-bottom: 0;
    background-color: #ccc;
    border-radius: 0;
    height: 8px;
}
.myAccount .completed-profile .progress-bar-info {
    background-color: #1b9f84;
}
.myAccount .completed-profile .profile-completion {
    color: #666;
    font-size: 16px;
    padding-bottom: 5px;
}
.myAccount .completed-profile .profile-completion span {
    color: #1b9f84;
}
.myAccount .sidebar-menu {
    clear: both;
    display: block;
    padding: 20px 0 0;
}
.myAccount .sidebar-menu .intraction-list {
    display: none;
}
.myAccount .sidebar-menu ul {
    list-style: none;
    padding: 0;
}
.myAccount .sidebar-menu ul li a {
    display: block;
    color: #333;
    font-size: 14px;
    line-height: 14px;
    padding: 10px 5px;
    text-decoration: none;
}
.myAccount .sidebar-menu ul li a img {
    padding-right: 8px;
    vertical-align: top;
}
.myAccount .sidebar-menu ul li a .badge {
    float: right;
    background-color: #1b9f84;
    border-radius: 2px;
    padding: 4px;
    color: #fff;
}
.myAccount .sidebar-menu ul li a .fa {
    float: right;
    font-size: 12px;
    padding-top: 5px;
}
.myAccount .sidebar-menu ul li ul li a {
    border-left: 4px solid #1b9f84;
    padding: 10px 20px 10px 45px;
}
.myAccount .sidebar-menu ul li a.active,
.myAccount .sidebar-menu ul li a:hover {
    background-color: #ececec;
}
.myAccount .sidebar-menu ul li a .fa-chevron-down,
.myAccount .sidebar-menu ul li a.active .fa-chevron-up {
    display: block;
}
.myAccount .sidebar-menu ul li a .fa-chevron-up,
.myAccount .sidebar-menu ul li a.active .fa-chevron-down {
    display: none;
}
.myAccount .cat-list .cat-sec-ttl {
 font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 16px;
    padding: 18px 20px;
    border-bottom: 1px solid #e7e7e7;
}
.myAccount .cat-list ul {
    display: block;
    list-style: none;
    margin: 0;
    color: #333;
        padding: 0;
}
.myAccount .cat-list ul li {
    display: block;
    margin: 20px 20px 0;
    padding-bottom: 20px;
}
.myAccount .cat-list ul li:last-child,
.myAccount .cat-list.recommendations .cat-sec-ttl {
    border-bottom: 0;
}
.myAccount .investor.recommendations ul li {
    padding-bottom: 10px;
    border-bottom: 0;
}
.myAccount .cat-list ul li .cat {
    font-size: 14px;
    line-height: 9px;
}
.myAccount .cat-list ul li .cat .inttime {
    float: right;
    color: #999;
}
.myAccount .cat-list ul li .cat-ttl {
    margin-top: 5px;
}
.myAccount .cat-list ul li .cat-ttl a {
    font-size: 14px;
    line-height: 14px;
    color: #1b9f84;
}
.myAccount .cat-list ul li .location {
    font-size: 14px;
}
.myAccount .cat-list ul li .location i {
    color: #1b9f84;
}
.myAccount .cat-list ul li .investment-price {
    font-size: 20px;
    line-height: 25px;
    margin-top: 10px;
}
.myAccount .cat-list ul li .investment-price div {
    font-size: 14px;
    line-height: 14px;
    color: #666;
}
.myAccount .cat-list .load-more {
    display: block;
    background-color: #f1f1f1;
    margin: 10px 20px 20px;
    color: #333;
    font-size: 16px;
    padding: 9px 0;
    text-align: center;
}
.myAccount .adds {
    margin-bottom: 30px;
}
.myAccount .cat-list.recommendations ul li {
    margin: 0 20px 20px;
}
.myAccount .cat-list.recommendations ul li .cat-ttl a {
    font-size: 16px;
    line-height: 18px;
    color: #1b9f84;
}
.myAccount .cat-list.recommendations ul li .cat-ttl {
    margin-top: -6px;
}
.myAccount .cat-list.recommendations ul li .investment-price {
    font-size: 20px;
    line-height: 20px;
}
.myAccount .recommendations.investor ul li:first-child {
    margin-top: 20px;
}
.myAccount .recommendations.investor ul li .investor-tag {
    top: -21px;
}

.myAccount .cat-list .col-lg-1,
.myAccount .cat-list .col-lg-10,
.myAccount .cat-list .col-lg-11,
.myAccount .cat-list .col-lg-12,
.myAccount .cat-list .col-lg-2,
.myAccount .cat-list .col-lg-3,
.myAccount .cat-list .col-lg-4,
.myAccount .cat-list .col-lg-5,
.myAccount .cat-list .col-lg-6,
.myAccount .cat-list .col-lg-7,
.myAccount .cat-list .col-lg-8,
.myAccount .cat-list .col-lg-9,
.myAccount .cat-list .col-md-1,
.myAccount .cat-list .col-md-10,
.myAccount .cat-list .col-md-11,
.myAccount .cat-list .col-md-12,
.myAccount .cat-list .col-md-2,
.myAccount .cat-list .col-md-3,
.myAccount .cat-list .col-md-4,
.myAccount .cat-list .col-md-5,
.myAccount .cat-list .col-md-6,
.myAccount .cat-list .col-md-7,
.myAccount .cat-list .col-md-8,
.myAccount .cat-list .col-md-9,
.myAccount .cat-list .col-sm-1,
.myAccount .cat-list .col-sm-10,
.myAccount .cat-list .col-sm-11,
.myAccount .cat-list .col-sm-12,
.myAccount .cat-list .col-sm-2,
.myAccount .cat-list .col-sm-3,
.myAccount .cat-list .col-sm-4,
.myAccount .cat-list .col-sm-5,
.myAccount .cat-list .col-sm-6,
.myAccount .cat-list .col-sm-7,
.myAccount .cat-list .col-sm-8,
.myAccount .cat-list .col-sm-9,
.myAccount .cat-list .col-xs-1,
.myAccount .cat-list .col-xs-10,
.myAccount .cat-list .col-xs-11,
.myAccount .cat-list .col-xs-12,
.myAccount .cat-list .col-xs-2,
.myAccount .cat-list .col-xs-3,
.myAccount .cat-list .col-xs-4,
.myAccount .cat-list .col-xs-5,
.myAccount .cat-list .col-xs-6,
.myAccount .cat-list .col-xs-7,
.myAccount .cat-list .col-xs-8,
.myAccount .cat-list .col-xs-9 {
    padding-right: 10px;
    padding-left: 10px;
}
.myAccount .cat-list .row {
    margin-right: -10px;
    margin-left: -10px;
}
.myAccount.ph-pnl {
    background-color: #1f1f1f;
    border-top: 1px solid #2b2b2b;
    position: fixed;
    width: 268px;
    right: 0;
    height: calc(100% - 24px);
    margin-right: -270px;
    transition: 0.3s;
    overflow: scroll;
    top: 13px;
    z-index: 9999;
    bottom: 0;
    display: none;
}
.myAccount.ph-pnl .sidebar-menu ul li a {
    color: #999;
    padding: 10px 20px 10px 30px;
}
.myAccount.ph-pnl .upgrade-prf .ph-select {
    padding: 0 20px 0 30px;
    display: block;
    background-color: #171717;
}
.myAccount.ph-pnl .upgrade-prf .ph-select select {
    width: 100%;
    padding: 7px 0;
    background-color: #171717;
    color: #999;
    border: 0;
}
.myAccount.ph-pnl .sidebar-menu ul li ul li a {
    padding: 10px 20px 10px 59px;
}
.myAccount.ph-pnl .sidebar-menu ul li a.active,
.myAccount.ph-pnl .sidebar-menu ul li a:hover {
    background-color: #2b2b2b;
    color: #fff;
}
.myAccount.ph-pnl .sidebar-menu ul li a.active img,
.myAccount.ph-pnl .sidebar-menu ul li a:hover img {
    webkit-filter: grayscale(100%);
    filter: brightness(650%);
}
.myAccount.ph-pnl .profile-name {
    padding: 10px 20px 10px 30px;
}
.myAccount.ph-pnl .profile-name .cmn-txt {
    color: #999;
    font-size: 13px;
    line-height: 13px;
}
.myAccount.ph-pnl .profile-name .name {
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
}
.myAccount.ph-pnl .circle-pic img {
    border-radius: 50%;
    width: 0.92em;
    height: 0.92em;
    max-width: 100%;
    object-fit: cover;
}
.myAccount.ph-pnl .profile-pic {
    float: left;
    padding-left: 30px;
    margin-top: 10px;
}
.myAccount.ph-pnl .completed-profile {
    float: left;
    border: 0;
    font-size: 15px;
    color: #999;
}
.myAccount.ph-pnl .completed-profile .prcent {
    color: #fff;
}
.myAccount.ph-pnl .upgrade-prf {
    clear: both;
    display: block;
    float: left;
    width: 100%;
    margin-top: 15px;
    border-bottom: 1px solid #1f1f1f;
}
.myAccount.ph-pnl .upgrade-prf.rm-pad {
    margin-top: 0;
}
.myAccount.ph-pnl .upgrade-prf a {
    background-color: #171717;
    color: #999;
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    padding: 7px 20px 7px 30px;
}
.myAccount.ph-pnl .upgrade-prf a .fa {
    color: #1b9f84;
    font-size: 20px;
    margin-top: -1px;
}
.myAccount.ph-pnl .feedback-sec {
    border-top: 1px solid #2b2b2b;
    font-size: 14px;
    margin-top: 50px;
    color: #999;
    padding: 20px 20px 30px 30px;
}
.myAccount.ph-pnl .feedback-sec.cng {
    padding-left: 20px;
    padding-bottom: 10px;
}
.myAccount.ph-pnl .feedback-sec .ttl {
    font-size: 17px;
    color: #999;
    padding-bottom: 15px;
}
.myAccount.ph-pnl .feedback-sec .support .fa {
    margin-right: 5px;
}
.myAccount.ph-pnl .feedback-sec .support a {
    color: #999;
}
.myAccount.ph-pnl .feedback-sec .followUs {
    margin-top: 40px;
    font-size: 16px;
}
.myAccount.ph-pnl .feedback-sec .followUs .follow-icn {
    margin-top: 5px;
}
.myAccount.ph-pnl .feedback-sec .followUs a {
    color: #999;
    padding-right: 15px;
    font-size: 18px;
}
.myAccount.ph-pnl .feedback-sec .foo-link {
font-weight: 400;
    font-size: 14px;
}
.myAccount.ph-pnl .feedback-sec .foo-link ul {
    list-style: none;
}
.myAccount.ph-pnl .feedback-sec .foo-link ul li {
    display: inline-block;
    border-right: 1px solid #999;
    padding: 0 5px;
    line-height: 14px;
}
.myAccount.ph-pnl .feedback-sec .foo-link ul li:last-child {
    border-right: 0;
}
.myAccount.ph-pnl .feedback-sec .foo-link ul li a {
    color: #999;
}
.myAccount.ph-pnl .feedback-sec .foo-link .tl-free {
    text-align: center;
}
.myAccount.ph-pnl .circle-pic .file-upload {
    width: 0.92em;
    height: 0.92em;
    overflow: hidden;
    position: absolute;
    opacity: 0;
    bottom: 0;
    cursor: pointer;
}
.margin-20 {
    margin: 20px;
}
.myAccount .margin30 {
    margin: 20px 20px 0;
}
.myAccount .margin40 {
    margin-top: 40px;
}
.myAccount .marginlr20 {
    margin-left: 20px;
    margin-right: 20px;
}
.myAccount .submitfrm {
    text-align: center;
}
.myAccount .form-sec .submitfrm .btn-blue {
    margin: 15px 0 30px;
    font-size: 20px;
    padding: 8px 27px;
}
.myAccount .input-area {
    position: relative;
}
.myAccount .input-area .showhidecng {
    background: #1b9f84;
    font-size: 16px;
    color: #fff;
    padding: 4px 0;
    display: block;
    width: 80px;
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 3px;
    margin: 0 auto;
    text-transform: uppercase;
}
.myAccount .showhidecng a {
    color: #fff;
}
.myAccount .business-sec .nav-tabs > li {
    float: none;
    display: inline-block;
    margin: 0;
    padding-bottom: 0;
    border-bottom: 0;
}
.myAccount .business-sec .nav > li > a:focus,
.myAccount .business-sec .nav > li > a:hover {
    background-color: transparent;
      bottom: 2px;
}
.myAccount .business-sec .nav-tabs > li > a::before,
.myAccount .contact-btn a::before {
    content: "";
    z-index: -1;
    background: #1b9f84;
    bottom: 0;
    left: 0;
}
.myAccount .business-sec .nav-tabs > li > a {
    border: none;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    color: #333;
    font-size: 20px;
    padding: 10px 15px;
}
.myAccount .business-sec .nav-tabs > li > a::before {
    position: absolute;
    right: 100%;
    height: 4px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.myAccount .business-sec .nav-tabs > li > a:focus::before,
.myAccount .business-sec .nav-tabs > li > a:hover::before {
    right: 0;
}
.myAccount .business-sec .nav-tabs > li.active > a::before {
    left: 0;
    right: 0;
        bottom: 2px;
}
.myAccount .business-sec .nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    color: #333;
}
.myAccount .contact-btn a {
    display: block;
    line-height: 16px;
    padding: 4px 0;
    font-size: 13px;
    border: 1px solid #1b9f84;
    border-left: 4px solid #1b9f84;
    text-align: center;
    color: #1b9f84;
    margin-top: 17px;
    text-decoration: none;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    width: 167px;
    text-transform: uppercase;
}
.myAccount .contact-btn a::before {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.myAccount .contact-btn a:active,
.myAccount .contact-btn a:focus,
.myAccount .contact-btn a:hover {
    color: #fff;
}
.myAccount .contact-btn .calculate-btn a:active::before,
.myAccount .contact-btn a:focus::before,
.myAccount .contact-btn a:hover::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
.myAccount .colorstrip {
    padding: 20px 0 10px;
    background-color: #f4f4f4;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}
.myAccount .colorstrip ul {
    margin: 0 auto;
    padding: 0;
    text-align: center;
}
.myAccount .colorstrip ul li {
    margin: 0 0 10px 10px;
    display: inline-block;
    list-style: none;
    border: 0;
    padding-bottom: 0;
}
.myAccount .colorstrip ul li:first-child {
    margin-left: 0;
}
.myAccount .colorstrip ul li a {
    padding: 2px 20px;
    border-radius: 20px;
    border: 2px solid #999;
    transition: 0.3s ease-in-out;
    font-size: 13px;
    color: #333;
    text-transform: capitalize;
    display: block;
    text-align: center;
}
.myAccount .colorstrip ul li a:hover,
.myAccount .colorstrip ul li.selected a {
    background-color: #1b9f84;
    border-color: #1b9f84;
    color: #fff;
}
.myAccount .colorstrip.insta {
    background-color: transparent;
    border: 0;
    padding-bottom: 0;
}
.myAccount .colorstrip.insta ul {
    text-align: left;
    margin-left: 20px;
}
.myAccount .colorstrip.insta ul li {
    margin-bottom: 0;
}
.myAccount .investorlist .iname {
    font-size: 16px;
   font-weight:700;
    line-height: 16px;
    color: #333;
}
.myAccount .investorlist .desi {
    font-size: 14px;
    line-height: 14px;
    color: #666;
}
.myAccount .investorlist .innti {
    font-size: 14px;
    line-height: 14px;
    color: #999;
    margin: 10px 0 5px;
}
.myAccount .investorlist .summary {
    font-size: 14px;
    color: #666;
    line-height: 16px;
    margin-top: 10px;
}
.myAccount .investorlist .summary span {
font-weight: 700;
    color: #333;
}
.myAccount .investorlist .locate {
    font-size: 14px;
    color: #333;
}
.myAccount .investorlist .locate .fa-map-marker {
    color: #1b9f84;
}
.myAccount .investorlist .othera {
    font-size: 14px;
    color: #666;
    line-height: 16px;
}
.myAccount .investorlist .othera span {
font-weight: 700;
    color: #333;
}
.myAccount .investorlist .profile-pic {
    width: 13.5%;
    float: left;
    margin-right: 10px;
}
.myAccount .investorlist .profile-content {
    width: 82%;
    float: right;
}
.myAccount .investorlist .contact-btn a {
    width: 145px;
}
.myAccount .investorlist .borlef {
    border-right: 1px dotted #e7e7e7;
    height: 110px;
}
.myAccount .investorlist .marlef {
    margin-left: 20px;
}
.myAccount .cat-list ul.nav-tabs {
    text-align: center;
}
.myAccount .cat-list .panel-heading {
    padding: 0;
}
.panel-group.responsive .panel-title a {
    display: block;
    text-align: left;
    font-weight:600;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
}
.contact-conf,
.myAccount .instant-list.purchasehistory td,
.myAccount .instant-list.purchasehistory th,
.myAccount .ttl-amt {
    text-align: center;
}
.myAccount .cat-list .panel-title a {
    display: block;
    padding: 10px 15px;
   font-weight: 600;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
}
.myAccount .cat-list .panel-title a:hover {
    color: #333;
}
.myAccount .cat-list.recommendations .top-sec-list {
    margin-top: 0;
}
.myAccount .ttl-viewd {
    text-align: center;
    margin: 20px;
}
.myAccount .ttl-viewd .number {
font-weight: 600;
    font-size: 35px;
}
.myAccount .ttl-viewd .desc {
    font-size: 18px;
}
.myAccount .upgrade-btn {
    margin: 20px;
}
.myAccount .upgrade-btn .submitfrm .btn {
    margin-right: 10px;
}
.myAccount .instant-list {
    margin: 20px;
    color: #666;
    font-size: 14px;
}
.myAccount .instant-list .tabulardata p {
    margin: 0;
}
.myAccount .instant-list .tabulardata .contact-btn a {
    margin: 5px 0;
    width: auto;
    width: 81px;
    padding: 4px 20px;
}
.myAccount .instant-list .tabulardata .contact-btn a.upgrade {
    width: 120px;
}
.myAccount .instant-list th {
    font-size: 16px;
}
.myAccount .instant-list th.cmnt-area {
    min-width: 200px;
}
.myAccount .instant-list.purchasehistory th.cmnt-area {
    min-width: 50%;
}
.myAccount .instant-list .response-date {
    color: #999;
}
.myAccount .instant-list .response-name {
    color: #333;
}
.myAccount .instant-list .response-company-name {
    color: #999;
}
.contact-conf,
.edit-info {
    padding: 20px;
}
.contact-conf .msg {
    font-size: 17px;
}
.contact-conf .action {
    margin-top: 20px;
}
.contact-conf .action a {
    margin: 0 3px;
    padding: 3px 20px;
}
.myAccount .dwn-response {
    color: #fff;
    margin-right: 20px;
    background-color: #1b9f84;
    float: right;
    font-size: 12px;
   font-weight: 400;
    line-height: 12px;
    padding: 5px 10px;
}
.myAccount .pagi {
    padding-right: 20px;
}
.myAccount .pagi .pagination {
    margin: 0 0 20px;
}
.myAccount .add-on-packages .addon-ttl {
    font-weight: 600;
    font-size: 16px;
    background: #f7f7f7;
    padding: 10px;
}
.myAccount .add-on-packages ul {
    list-style: none;
}
.myAccount .add-on-packages ul li:first-child {
    margin-top: 10px;
}
.myAccount .add-on-packages ul li {
    border: 1px solid #e5e5e5;
    display: block;
    margin-bottom: 20px;
    position: relative;
}
.myAccount .add-on-packages ul li .addOn-select-area {
    display: table-cell;
    background-color: #666;
    vertical-align: middle;
    padding: 10px 8px 20px 15px;
}
.myAccount .add-on-packages ul li .addOn-name {
    display: table-cell;
    background-color: #f4f4f4;
    color: #333;
    font-size: 13px;
    width: 300px;
    border-right: 1px solid #e5e5e5;
    vertical-align: middle;
    padding: 10px 35px 10px;
}
.myAccount .add-on-packages ul li .addOn-Year {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 15px 10px;
    font-size: 13px;
}
.myAccount .add-on-packages ul li .addOn-Year .selectbx {
    width: 234px;
    clear: both;
    display: table-cell;
    vertical-align: middle;
    padding-right: 20px;
}
.myAccount .add-on-packages ul li .addOn-Year .price {
    display: table-cell;
    vertical-align: middle;
    color: #333;
    font-size: 22px;
    padding-left: 20px;
    border-left: 1px dotted #ccc;
}
.myAccount .add-on-packages ul li .addOn-Year .desc {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.myAccount .ttl-amt {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px 0;
    border-top: 1px solid #e5e5e5;
    font-size: 22px;
font-weight: 400;
}
.myAccount .ttl-amt span {
font-weight: 600;
    margin-left: 10px;
}
.myAccount .form-sec .submitfrm .btn-blue.rm-pad {
    margin-bottom: 10px;
}
.myAccount .pos-rel {
    position: relative;
}
.myAccount .height-calc {
    height: calc(100vh - 120px);
    overflow: hidden;
    transition: all 0.5s ease;
}
.myAccount .height-calc.fxd {
    position: fixed;
}
.myAccount .height-calc.abs {
    position: absolute;
    bottom: -49px;
}
.myAccount .height-calc .width-calc {
    height: 100%;
}
.myAccount .bx-mail {
    height: 100%;
    width: 65%;
    float: left;
    position: relative;
}
.myAccount .bx-mail .mail-bdy {
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    position: relative;
}
.myAccount .bx-mail .mail-bdy .mail-profile .ph-arrow {
    display: none;
    margin-right: 10px;
    cursor: pointer;
}
.myAccount .bx-mail .mail-bdy .mail-profile .ph-arrow .fa {
    margin-top: 16px;
}
.myAccount .bx-mail .mail-bdy .mail-profile {
    display: flex;
    border: 1px solid #e7e7e7;
    padding: 15px;
}
.myAccount .bx-mail .mail-bdy .mail-profile img {
    border: 1px solid #e5e5e5;
    background-clip: content-box;
    width: 48px;
    height: 48px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
}
.myAccount .bx-mail .mail-bdy .mail-profile .detail-profile {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
}
.safari .myAccount .bx-mail .mail-bdy .mail-profile img {
    float: left;
}
.safari .myAccount .bx-mail .mail-bdy .mail-profile {
    clear: both;
    float: left;
    width: 100%;
    display: block;
}
.safari .myAccount .bx-mail .mail-bdy .mail-profile .detail-profile {
    width: 80%;
    display: inline-block;
    vertical-align: middle;
    float: left;
}
.myAccount .bx-mail .mail-bdy .mail-profile .detail-profile .profile {
    color: #999;
    font-size: 13px;
    line-height: 17px;
}
.myAccount .bx-mail .mail-bdy .mail-profile .detail-profile .name {
    font-size: 16px;
font-weight: 700;
    color: #333;
    line-height: 16px;
}
.myAccount .bx-mail .mail-bdy .mail-profile .detail-profile .comp-info {
    color: #666;
    font-size: 14px;
    line-height: 16px;
}
.myAccount .bx-mail .mail-bdy ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px;
    list-style: none;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #e7e7e7;
    border-top: 0;
    border-right-color: #1b9f84;
    margin: 0;
    transition: all 0.5s ease;
}
.safari .myAccount .bx-mail .mail-bdy ul {
    min-height: 100px;
}
.myAccount .bx-mail .mail-bdy ul li {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list {
    display: block;
font-weight: 400;
    font-size: 16px;
    color: #fff;
    padding-bottom: 10px;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list.right {
    color: #666;
    float: right;
    text-align: right;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg {
    display: inline-block;
    text-align: left;
    width: calc(100% - 100px);
    position: relative;
    padding: 10px 10px 10px 20px;
    background-color: #1b9f84;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.safari .myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg {
    width: 80%;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg .sender-nm {
    display: block;
    margin-bottom: 0;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg .attach-file {
    display: block;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg .attach-file ul {
    list-style: none;
    display: block;
    border: 0;
    padding: 20px 0 0;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg .attach-file ul li {
    position: relative;
    cursor: pointer;
    padding: 10px;
    display: block;
    margin-bottom: 10px;
    border: 1px solid #fff;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg .down-file {
    color: #fff;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 26px;
    padding-top: 13px;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg ul li:hover .down-file {
    display: block;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list.right .msg .attach-file ul li {
    border: 1px solid #1b9f84;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg .attach-file ul li .file-name {
font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .msg .attach-file ul li .file-size {
    font-size: 14px;
    color: #fff;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list.right .msg .attach-file ul li .file-size {
    color: #999;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list.right .msg {
    background-color: #f5f5f5;
    color: #666;
    border-radius: 20px 0 0 20px;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list.right .sec-left {
    float: left;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .time {
    color: #999;
    padding-left: 15px;
    float: left;
    margin-left: 49px;
    margin-top: 5px;
    font-size: 13px;
    display: table-cell;
    vertical-align: middle;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list.right .time {
    float: right;
    margin-left: 0;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .profile {
    display: inline-block;
    vertical-align: top;
}
.myAccount .bx-mail .mail-bdy ul .msg-bdy-list .profile img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;
}
.myAccount .bx-mail .mail-bdy .mail-compose {
    display: block;
    height: auto;
    margin-top: auto;
    padding: 15px;
    background-color: #fff;
    width: 100%;
    border: 1px solid #e7e7e7;
    border-right-color: #1b9f84;
    border-top: 0;
}
.myAccount .bx-mail .mail-bdy .mail-compose .input-area {
    clear: both;
    display: block;
}
.myAccount .bx-mail .mail-bdy .mail-compose .input-area textarea {
    font-size: 16px;
    resize: none;
    box-shadow: none;
    padding: 0;
    border: 0;
    overflow-y: scroll;
    display: block;
    min-height: 40px;
    max-height: 180px;
}
.myAccount .bx-mail .mail-bdy .mail-compose .action {
    clear: both;
    display: block;
    margin-top: 10px;
}
.myAccount .bx-mail .mail-bdy .mail-compose .action .attachment {
    float: right;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}
.myAccount .bx-mail .mail-bdy .mail-compose .action .attachment .file-upload {
    width: 30px;
    height: 27px;
    overflow: hidden;
    position: absolute;
    opacity: 0;
    bottom: 0;
    cursor: pointer;
}
.submit-msg {
    float: right;
    color: #f9f9f9;
    font-size: 16px;
}
.submit-msg .btn-blue {
    padding: 3px 16px;
}
.myAccount .bx-mail .mail-bdy .mail-compose .attachment-file {
    display: block;
    clear: both;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.myAccount .bx-mail .mail-bdy .mail-compose .attachment-file ul {
    list-style: none;
    display: block;
    border: 0;
    padding: 20px 0 0;
}
.myAccount .bx-mail .mail-bdy .mail-compose .attachment-file li {
    border: 1px solid #1b9f84;
    position: relative;
    padding: 10px;
    display: inline-block;
    margin-right: 40px;
    margin-bottom: 10px;
}
.myAccount .bx-mail .mail-bdy .mail-compose .attachment-file li .file-name {
font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    width: 211px;
    text-overflow: ellipsis;
}
.myAccount .bx-mail .mail-bdy .mail-compose .attachment-file li .file-size {
    font-size: 14px;
    color: #999;
}
.myAccount .bx-mail .mail-bdy .mail-compose .attachment-file li .rm-file {
    position: absolute;
    right: -18px;
    top: -8px;
    color: #1b9f84;
}
.myAccount .mail-list {
    float: left;
    width: 35%;
    position: relative;
    height: calc(100% - 49px);
    overflow: hidden;
    border: 1px solid #e7e7e7;
    border-left: 0;
}
.myAccount .mail-list .sec-ttl {
    display: block;
    color: #333;
font-weight: 700;
    text-transform: uppercase;
    padding: 15px;
}
.myAccount .mail-list .searchbar {
    display: block;
    padding: 10px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    background-color: #f9f9f9;
}
.myAccount .mail-list .searchbar .input-group {
    width: 100%;
}
.myAccount .mail-list .searchbar .form-control {
    border-radius: 0;
    box-shadow: none;
}
.myAccount .mail-list .searchbar .input-group-btn .btn-default {
    background-color: #fff;
    border-left: 0;
    color: #1b9f84;
}
.myAccount .mail-list .msg-list {
    display: block;
    padding: 0;
    height: calc(100vh - 278px);
    overflow-x: hidden;
}
.myAccount .mail-list .msg-list ul {
    list-style: none;
        margin: 0px;
    padding: 0px;
}
.myAccount .mail-list .msg-list ul li {
    padding: 15px 15px 0;
}
.myAccount .mail-list .msg-list ul .mail-profile {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
}
.myAccount .mail-list .msg-list ul li:last-child .mail-profile {
    border-bottom: 0;
}
.myAccount .mail-list .msg-list ul .mail-profile img {
    border: 1px solid #e5e5e5;
    background-clip: content-box;
    width: 48px;
    height: 48px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
}
.myAccount .mail-list .msg-list ul .mail-profile .detail-profile {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
}
.safari .myAccount .mail-list .msg-list ul .mail-profile .detail-profile {
    width: 75%;
    display: inline-block;
    vertical-align: top;
}
.safari .myAccount .mail-list .msg-list ul .mail-profile img {
    float: left;
}
.myAccount .mail-list .msg-list ul .mail-profile .detail-profile .profile {
    color: #999;
    font-size: 13px;
    line-height: 17px;
}
.myAccount .mail-list .msg-list ul .mail-profile .detail-profile .profile span {
    width: 87px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.myAccount .mail-list .msg-list ul .mail-profile .detail-profile .name {
    font-size: 16px;
  font-weight: 700;
    color: #333;
    line-height: 16px;
}
.myAccount .mail-list .msg-list ul .mail-profile .detail-profile .comp-info {
    color: #666;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    width: 166px;
    text-overflow: ellipsis;
}
.myAccount .mail-list .msg-list ul .mail-profile .detail-profile .shrt-mail {
    color: #333;
    font-size: 13px;
    margin-top: 10px;
    line-height: 17px;
}
.myAccount .mail-list .msg-list ul li.unread .mail-profile .detail-profile .shrt-mail {
  font-weight: 600;
}
.myAccount .mail-list .msg-list ul li.active,
.myAccount .mail-list .msg-list ul li:hover {
    background-color: #1b9f84;
    color: #fff;
    cursor: pointer;
}
.myAccount .mail-list .msg-list ul li.active .mail-profile,
.myAccount .mail-list .msg-list ul li:hover .mail-profile {
    border-color: #1b9f84;
    color: #fff;
}
.myAccount .mail-list .msg-list ul li.active .comp-info,
.myAccount .mail-list .msg-list ul li.active .name,
.myAccount .mail-list .msg-list ul li.active .profile,
.myAccount .mail-list .msg-list ul li.active .shrt-mail,
.myAccount .mail-list .msg-list ul li:hover .comp-info,
.myAccount .mail-list .msg-list ul li:hover .name,
.myAccount .mail-list .msg-list ul li:hover .profile,
.myAccount .mail-list .msg-list ul li:hover .shrt-mail {
    color: #fff !important;
}
.myAccount .profile-detail .profile-pic {
    display: inline-block;
}
.myAccount .profile-detail {
    padding: 20px 20px 15px;
}
.myAccount .profile-detail .profile-pic img {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    max-width: 100%;
    object-fit: cover;
}
.myAccount .profile-detail .profile-pic .camera-icn {
    position: absolute;
    top: 69px;
}
.myAccount .profile-detail .profile-pic .cm-icn {
    width: 26px;
    height: 26px;
}
.myAccount .profile-detail .profile-pic .file-upload {
    width: 74px;
    height: 95px;
    overflow: hidden;
    position: absolute;
    opacity: 0;
    bottom: 1px;
    cursor: pointer;
}
.myAccount .profile-detail .profile-info .location {
    font-weight: 400;
    padding-bottom: 3px;
    font-size: 13px;
    color: #333;
}
.myAccount .profile-detail .profile-info .location .fa {
    color: #639ee0;
}
.myAccount .profile-detail .profile-info .name {
    color: #333;
  font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}
.myAccount .profile-detail .profile-info .designation {
font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 12px;
    color: #666;
    line-height: 12px;
}
.myAccount .profile-detail .profile-info .designation.tpmr {
    margin-top: 2px;
}
.myAccount .profile-detail .profile-info {
    display: inline-block;
    width: calc(100% - 78px);
    vertical-align: top;
    padding-left: 10px;
}
.myAccount .profile-detail .profile-info .name {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    text-overflow: ellipsis;
}
.menu-verify {
    clear: both;
    float: left;
    width: 100%;
        font-size: 13px;
            padding-bottom: 20px;
}
.zoomout img {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.zoomout:hover img {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}
.hover13 figure:hover img {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s;
}
@-webkit-keyframes flash {
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
@keyframes flash {
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
.myAccount .cat-list.new-dash .panel-title a {
    font-weight: 600;
    font-size: 16px;
}
.myAccount .cat-list.new-dash .panel-title a span {
    color: #666;
font-weight: 400;
    font-size: 12px;
    position: absolute;
    padding-left: 5px;
    top: 13px;
}

.tab-content.cat-list{ background: #fff;}



.myAccount .cat-list.new-dash .panel-title a .fa {
    float: right;
    color: #666;
}
.myAccount .cat-list.new-dash .panel-group .panel,
.myAccount .cat-list.new-dash .panel-heading {
    border-radius: 0;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-comp {
    display: inline-block;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .profile-info {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .profile-info .profile-sec {
    position: relative;
        width: 125px;
    text-align: center;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .profile-info .plan {
    color: #1b9f84;
    font-weight:700;
    font-size: 11px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 13px;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .profile-info .complete-status {
    color: #333;
font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 53px;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .profile-info .active-status {
    color: #1b9f84;
    font-weight:700;
    font-size: 11px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 72px;
    text-align: center;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .profile-info .active-status.pending {
    color: #999;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .profile-info .bdr-spc {
    border-top: 1px solid #ccc;
    margin: 0 66px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 69px;
    right: 0;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .perc {

    font-weight: 600!important;
    line-height: 80px !important;
    font-size: 28px !important;
    color: #1b9f84 !important;
}
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .sh-desc {
 font-weight: 400;
    font-size: 14px;
    color: #666;
    min-height: 56px;
    margin: 10px 0;
    line-height: 18px;
    text-align: center;
    width: 100px;

}

.frm-sec-ttl { font-size: 18px;  color: #1B9F84; }
.myAccount .cat-list.new-dash .panel-group .panel .profile-lst .sh-desc a {
    font-size: 12px;
}
.myAccount .cat-list.new-dash .profile-desc {
    color: #333;
    font-weight:700;
    padding-bottom: 15px;
    margin-bottom: 20px;
    font-size: 18px;
    border-bottom: 1px solid #e7e7e7;
}
.myAccount .cat-list.new-dash .profile-sht-desc {
   
    padding-top: 5px;
    font-size: 16px;
    color: #666;
    line-height: 18px;
    font-weight: 400;
}
.myAccount .cat-list.new-dash .crt-profile {
    display: table;
    width: 100px;
    margin: 0 auto;
    height: 100px;
    background-color: #ebebeb;
    border-radius: 50%;
}
.myAccount .cat-list.new-dash .crt-profile .img-con {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
#shrinkMe {
    height: 89px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    -moz-transition: height 5s ease;
    -webkit-transition: height 5s ease;
    -o-transition: height 5s ease;
    transition: height 5s ease;
}
#shrinkMe a.pos {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
}
#shrinkMe ul {
    list-style-position: inside;
    margin-left: 20px;
}
#shrinkMe ul li p strong {
    font-size: 16px;
    font-weight:700;
}
#shrinkMe h2,
#shrinkMe p strong {
    font-size: 20px;
    font-weight:700;
    color: #333;
}
.w-height {
    transition: height 5s ease;
    min-height: 100vh;
}
.rmv-bdr {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
/* End here  old css for my account */


@media (min-width: 768px) {
	
	.navbar-default .dropdown .dropdown-menu {
		border-top: 4px solid #F58220;
		border-left: 0;
		display: block;
		position: absolute;
		box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(20, 19, 18, 0.1);
	}
	.headblk .navbar-default .dropdown .dropdown-menu {	border-top: none; }
}

.navbar-default .dropdown .dropdown-menu .dropdown-item {
	padding: 12px 18px;
	transition: all 500ms ease;
	font-weight: 600;
	min-width: 220px;
}

.navbar-default .dropdown .dropdown-menu .dropdown-item:hover {
	background-color: #ffffff;
	color: #F58220;
	transition: all 500ms ease;
}

.navbar-default .dropdown .dropdown-menu .dropdown-item.active {
	background-color: #ffffff;
	color: #F58220;
}

/*.navbar-default .dropdown:hover .dropdown-menu {
	transform: translate3d(0px, 0px, 0px);
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=1);
}*/

.navbar-default .dropdown .dropdown-menu.show {	transform: translate3d(0px, 0px, 0px);
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=1);}
/*------/ Hamburger Navbar /------*/

.navbar-toggler {
	position: relative;
}

.navbar-toggler:focus,
.navbar-toggler:active {
	outline: 0;
}

.navbar-toggler span {
	display: block;
	background-color: #000000;
	height: 3px;
	width: 25px;
	margin-top: 4px;
	margin-bottom: 4px;
	transform: rotate(0deg);
	left: 0;
	opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
	transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
	position: absolute;
	left: 12px;
	top: 10px;
	transform: rotate(135deg);
	opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
	height: 12px;
	visibility: hidden;
	background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
	position: absolute;
	left: 12px;
	top: 10px;
	transform: rotate(-135deg);
	opacity: 0.9;
}


/*======================================
//--//-->   BOX COLAPSE
======================================*/

.box-collapse {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	z-index: 1040;
	background-color: #efefef;
	transform: translateX(100%);
	transition: all 0.6s ease;
}

@media (min-width: 768px) {
	.box-collapse {
		width: 80%;
	}
}

.box-collapse .title-box-d {
	top: 30px;
	left: 60px;
	opacity: 0;
	transition: all 1s ease;
	transition-delay: .3s;
}

@media (max-width: 575px) {
	.box-collapse .title-box-d {
		left: 35px;
	}
}

@media (max-width: 575px) {
	.box-collapse .title-box-d .title-d {
		font-size: 1.3rem;
	}
}

.box-collapse-wrap {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 6.5rem;
	bottom: 5rem;
	padding-left: 5%;
	padding-right: 5%;
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0;
	transform: translateY(3rem);
	transition: transform 0.5s 0.5s ease, opacity 0.5s 0.5s ease;
	width: 100%;
}

.box-collapse-open .click-closed {
	visibility: visible;
}

.box-collapse-open .box-collapse {
	transform: translateX(0);
	box-shadow: 0 0 65px rgba(0, 0, 0, 0.07);
	opacity: 1;
}

.box-collapse-open .box-collapse .title-box-d {
	transform: translate(0);
	opacity: 1;
}

.box-collapse-open .box-collapse-wrap {
	transform: translate(0);
	opacity: 1;
}

.box-collapse-closed .box-collapse {
	opacity: .7;
	transition-delay: 0s;
}

.box-collapse-closed .box-collapse .title-box-d {
	opacity: 0;
	transition-delay: 0s;
}

.box-collapse-closed .box-collapse .form-a {
	opacity: 0;
	transition-delay: 0s;
}

.click-closed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
	z-index: 1035;
	background-color: #000000;
	opacity: .4;
}

.close-box-collapse {
	position: absolute;
	z-index: 1050;
	top: 2rem;
	font-size: 3.5rem;
	line-height: 1;
	color: #000000;
	cursor: pointer;
	transition: all 0.3s ease;
}

.right-boxed {
	right: 4.2857rem;
}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

.intro {
	height: 100vh;
	position: relative;
	color: #ffffff;
}

.intro .owl-theme .owl-dots {
	position: absolute;
	bottom: 70px;
	right: 50px;
}

@media (min-width: 768px) {
	.intro .owl-theme .owl-dots {
		right: 200px;
	}
}

.intro .owl-theme .owl-dots .owl-dot span {
	background: #ffffff;
	border: 0px solid #ffffff;
}

.intro .owl-theme .owl-dots .owl-dot:hover span {
	background-color: #F58220;
}

.intro .owl-theme .owl-dots .owl-dot.active span {
	background-color: #F58220;
}

.intro .intro-item {
	padding-top: 2rem;
}

.intro .carousel-item-a {
	position: relative;
	height: 100vh;
}

.intro .carousel-item-a.bg-image {
	background-attachment: scroll;
}

.intro .intro-content {
	position: absolute;
}

.intro .intro-body {
	padding-left: 1rem;
}

@media (min-width: 768px) {
	.intro .intro-body {
		padding-left: 2rem;
	}
}

.intro .intro-body .price-a {
	color: #ffffff;
	padding: 1rem 1.5rem;
	border: 2px solid #F58220;
	border-radius: 50px;
	text-transform: uppercase;
	letter-spacing: 0.030em;
}

.intro .spacial {
	-webkit-animation-delay: .2s;
	animation-delay: .2s;
	color: #ffffff;
}

.intro .intro-title-top {
	-webkit-animation-delay: .6s;
	animation-delay: .6s;
	margin-bottom: 1rem;
	font-size: .9rem;
	color: #ffffff;
	letter-spacing: .05em;
}

@media (min-width: 768px) {
	.intro .intro-title-top {
		font-size: 1rem;
		margin-bottom: 1rem;
	}
}

@media (min-width: 992px) {
	.intro .intro-title-top {
		font-size: 1rem;
		margin-bottom: 2rem;
	}
}

.intro .intro-title {
	transition-duration: 9s !important;
	color: #ffffff;
	font-weight: 700;
	font-size: 2.8rem;
	text-transform: uppercase;
}

@media (min-width: 576px) {
	.intro .intro-title {
		font-size: 3.5rem;
	}
}

@media (min-width: 768px) {
	.intro .intro-title {
		font-size: 4rem;
	}
}

@media (min-width: 992px) {
	.intro .intro-title {
		font-size: 4.5rem;
	}
}

.intro .intro-subtitle {
	font-size: 1.5rem;
	font-weight: 300;
}

@media (min-width: 768px) {
	.intro .intro-subtitle {
		font-size: 2.5rem;
	}
}

.intro .intro-subtitle.intro-price {
	padding-top: 1rem;
	font-size: 1.2rem;
}

@media (min-width: 576px) {
	.intro .intro-subtitle.intro-price {
		font-size: 1.3rem;
	}
}

@media (min-width: 768px) {
	.intro .intro-subtitle.intro-price {
		padding-top: 2rem;
		font-size: 1.5rem;
	}
}

@media (min-width: 992px) {
	.intro .intro-subtitle.intro-price {
		padding-top: 3rem;
	}
}


/*------/ Intro Single /------*/

.intro-single {
	padding: 12rem 0 3rem;
}

@media (max-width: 767px) {
	.intro-single {
		padding-top: 9rem;
	}
}

.intro-single .title-single-box {
	padding: 1rem 0 1rem 2rem;
}

.intro-single .title-single-box {
	border-left: 3px solid #F58220;
}

.intro-single .title-single-box .title-single {
	font-weight: 600;
	font-size: 2.2rem;
}

@media (min-width: 768px) {
	.intro-single .title-single-box .title-single {
		font-size: 2.1rem;
	}
}

@media (min-width: 992px) {
	.intro-single .title-single-box .title-single {
		font-size: 2.5rem;
	}
}

.intro-single .breadcrumb-box {
	padding: 1rem 0 0 .5rem;
}

.intro-single .breadcrumb {
	background-color: transparent;
	padding-right: 0;
	padding-left: 0;
}


/*--------------------------------------------------------------
# Card General
--------------------------------------------------------------*/

@media (max-width: 767px) {
	.card-box-a,
	.card-box-b,
	.card-box-c,
	.card-box-d {
		margin-bottom: 2.5rem;
	}
}

@media (min-width: 768px) {
	.grid .card-box-a,
	.grid .card-box-b,
	.grid .card-box-c,
	.grid .card-box-d {
		margin-bottom: 2.5rem;
	}
}

.card-box-a,
.card-box-b,
.card-box-d {
	position: relative;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.card-box-a .img-a,
.card-box-a .img-b,
.card-box-b .img-a,
.card-box-b .img-b {
	transition: .8s all ease-in-out;
}

@media (min-width: 768px) {
	.card-box-a:hover .img-a,
	.card-box-a:hover .img-b,
	.card-box-b:hover .img-a,
	.card-box-b:hover .img-b {
		transform: scale(1.2);
	}
}

@media (min-width: 768px) {
	.card-box-a .price-a,
	.card-box-b .price-a {
		font-size: .9rem;
	}
}

@media (min-width: 992px) {
	.card-box-a .price-a,
	.card-box-b .price-a {
		font-size: 1rem;
	}
}

.card-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	    background: rgba(0,0,0,0.4);
}

.card-shadow {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.card-shadow:before {
	content: ' ';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
}


.bex-all-popular-bussiness-main.shob .card-overlay {
	 background: rgba(0,0,0,0.4);
}
.bex-all-popular-bussiness-main.shob .card-overlay ul.loclist {   opacity: 0.0;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;}
.bex-all-popular-bussiness-main.shob .card-shadow:hover .card-overlay {  background: rgba(0,0,0,0.6); 


}
.bex-all-popular-bussiness-main.shob .card-shadow:hover .card-overlay ul.loclist { opacity: 1.0;


 }



/*======================================
//--//-->   PROPERTY - CARD-A
======================================*/

@media (min-width: 768px) {
	.card-box-a:hover .card-overlay-a-content {
		bottom: 60px;
	}
}

@media (min-width: 768px) and (min-width: 768px) {
	.card-box-a:hover .card-overlay-a-content {
		bottom: 60px;
	}
}

@media (min-width: 768px) and (min-width: 992px) {
	.card-box-a:hover .card-overlay-a-content {
		bottom: 64px;
	}
}

@media (min-width: 768px) {
	.card-box-a:hover .card-body-a {
		padding-bottom: 1rem;
	}
}

@media (max-width: 767px) {
	.card-box-a .card-overlay-a-content {
		bottom: 65px;
	}
	.card-box-a .card-body-a {
		padding-bottom: 1rem;
	}
}

.card-overlay-a-content {
	transition: all .5s ease-in;
	position: absolute;
	width: 100%;
	bottom: 0px;
	z-index: 2;
}

@media (min-width: 768px) {
	.card-overlay-a-content {
		bottom: -20px;
	}
}

@media (min-width: 992px) {
	.card-overlay-a-content {
		bottom: 0px;
	}
}

.card-header-a {
	padding: 0 1rem;
}

.card-header-a .card-title-a {
	color: #ffffff;
	margin-bottom: 0;
	padding-bottom: .7rem;
}

@media (min-width: 768px) {
	.card-header-a .card-title-a {
		font-size: 1.3rem;
	}
}

@media (min-width: 992px) {
	.card-header-a .card-title-a {
		font-size: 2rem;
	}
}

.card-header-a .card-title-a a {
	color: #ffffff;
	text-decoration: none;
}

.card-body-a {
	z-index: 2;
	transition: all .5s ease-in;
	padding: 0rem 1rem 2rem 1rem;
}

.card-body-a .price-box {
	padding-bottom: .5rem;
}

.price-a {
	color: #ffffff;
	padding: .6rem .8rem;
	border: 2px solid #F58220;
	border-radius: 50px;
	text-transform: uppercase;
	letter-spacing: 0.030em;
}

.card-footer-a {
	width: 100%;
	position: absolute;
	z-index: 2;
	background-color: #F58220;
}

.card-info {
	list-style: none;
	margin-bottom: 0;
	padding: .5rem 0;
}

.card-info .card-info-title {
	font-size: 1rem;
	color: #313131;
}

@media (min-width: 768px) {
	.card-info .card-info-title {
		font-size: .9rem;
	}
}

@media (min-width: 992px) {
	.card-info .card-info-title {
		font-size: 1rem;
	}
}

.card-info li span {
	color: #ffffff;
	font-size: .9rem;
}

@media (min-width: 768px) {
	.card-info li span {
		font-size: .8rem;
	}
}

@media (min-width: 992px) {
	.card-info li span {
		font-size: 1rem;
	}
}


/*------/ Property Grid /-----*/

.property-grid .grid-option {
	text-align: right;
	margin-bottom: 2rem;
}

.property-grid .grid-option .custom-select {
	border-radius: 0;
	width: 200px;
	border-color: #313131;
}

.property-grid .grid-option .custom-select:hover,
.property-grid .grid-option .custom-select:active,
.property-grid .grid-option .custom-select:focus {
	border-color: #313131;
}

.property-grid .grid-option .custom-select option {
	border-color: #313131;
}


/*------/ Property Single /-----*/

.gallery-property {
	margin-bottom: 3rem;
}

.property-price {
	margin: 0 auto;
}

.property-price .card-header-c {
	padding: 0;
}

.summary-list {
	padding-right: 1rem;
	color: #000000;
}

.summary-list .list {
	padding: 0;
	line-height: 2;
}

.summary-list .list span {
	color: #555555;
}

@media (max-width: 767px) {
	.property-agent {
		margin-top: 2.5rem;
	}
}

.property-agent .title-agent {
	font-weight: 600;
}

.property-agent ul {
	line-height: 2;
	color: #000000;
}

.property-agent .socials-a {
	text-align: center;
}

@media (max-width: 991px) {
	.property-contact {
		margin-top: 2.5rem;
	}
}


/*======================================
//--//-->   NEWS - CARD-B
======================================*/

.card-header-b {
	padding: 1rem;
	color: #ffffff;
	position: absolute;
	bottom: 20px;
	z-index: 2;
}

@media (min-width: 768px) {
	.card-header-b {
		bottom: 0px;
	}
}

@media (min-width: 992px) {
	.card-header-b {
		/* bottom: 20px; */
	}
}

.card-header-b .category-b {
	font-size: .9rem;
	background-color: #F58220;
	padding: .3rem .7rem;
	color: #313131;
	letter-spacing: 0.030em;
	border-radius: 50px;
	text-decoration: none;
}

.card-header-b .title-2 {
	margin-bottom: 0;
	padding: .6rem 0;
	font-size: 1.5rem;
}

@media (min-width: 768px) {
	.card-header-b .title-2 {
		font-size: 1rem;
	}
}

@media (min-width: 992px) {
	.card-header-b .title-2 {
		font-size: 1.6rem;
	}
}

.card-header-b .title-2 a {
	color: #ffffff;
	text-decoration: none;
}

.card-header-b .date-b {
	color: #d8d8d8;
	font-size: 1rem;
}

@media (min-width: 768px) {
	.card-header-b .date-b {
		font-size: .9rem;
	}
}

@media (min-width: 992px) {
	.card-header-b .date-b {
		font-size: 1rem;
	}
}


/*------/ News Single /------*/

.post-information {
	padding: 3rem 0;
}

.post-content {
	font-size: 1.1rem;
	font-weight: 300;
	line-height: 1.6;
}

.post-content .post-intro {
	font-size: 1.7rem;
	font-weight: 300;
	margin-bottom: 4rem;
	line-height: 1.6;
	color: #000000;
	font-weight: 400;
}

.post-content p {
	margin-bottom: 1.7rem;
}

.post-content .blockquote {
	padding: 1rem 3rem 1.7rem 3rem;
	border-left: 3px solid #F58220;
	color: #000000;
}

.post-content .blockquote footer {
	text-align: left;
	padding: 0;
}

.post-footer {
	padding: 3rem 0 4rem;
}

.post-share .socials {
	display: inline-block;
}

.post-share .socials li {
	margin-left: .6rem;
}


/*------/ Comments /------*/

.box-comments .list-comments li {
	padding-bottom: 1.5rem;
}

.box-comments .list-comments .comment-avatar {
	display: table-cell;
	vertical-align: top;
}

.box-comments .list-comments .comment-avatar img {
	width: 80px;
	height: 80px;
}

.box-comments .list-comments .comment-author {
	font-size: 1.3rem;
}

@media (min-width: 768px) {
	.box-comments .list-comments .comment-author {
		font-size: 1.5rem;
	}
}

.box-comments .list-comments .comment-details {
	display: table-cell;
	vertical-align: top;
	padding-left: 25px;
}

.box-comments .list-comments .comment-description {
	padding: .8rem 0 .5rem 0;
}

.box-comments .list-comments a {
	color: #000000;
}

.box-comments .list-comments span {
	display: block;
	color: #2B2A2A;
	font-style: italic;
}

.box-comments .comment-children {
	margin-left: 40px;
}


/*======================================
//--//-->   SERVICES - CARD-C
======================================*/

.card-box-c {
	position: relative;
}

@media (max-width: 767px) {
	.card-box-c {
		margin-bottom: 2rem;
	}
}

.card-header-c {
	padding: 0 1rem 1rem 1rem;
}

.card-body-c {
	padding: .5rem .5rem 0 .5rem;
}

@media (max-width: 767px) {
	.card-body-c {
		padding: 0;
	}
}

.card-footer-c {
	padding-left: .5rem;
}

.card-box-ico {
	padding: 1rem 3rem 1rem 2.5rem;
	border: 5px solid #F58220;
}

.card-box-ico span {
	font-size: 4rem;
	color: #000000;
}

.title-c {
	font-size: 2.5rem;
	font-weight: 600;
	margin-left: -40px;
}

@media (min-width: 768px) {
	.title-c {
		font-size: 1.8rem;
	}
}

@media (min-width: 992px) {
	.title-c {
		font-size: 2.5rem;
	}
}


/*======================================
//--//-->   AGENTS - CARD-D
======================================*/

.card-box-d .card-overlay-hover {
	transition: all .2s ease-in-out;
	padding: 15px 40px 15px 35px;
}

@media (min-width: 768px) {
	.card-box-d .card-overlay-hover {
		padding: 5px 15px 5px 10px;
	}
}

@media (min-width: 992px) {
	.card-box-d .card-overlay-hover {
		padding: 5px 40px 5px 35px;
	}
}

@media (min-width: 1200px) {
	.card-box-d .card-overlay-hover {
		padding: 15px 40px 5px 35px;
	}
}

.card-box-d .title-d {
	transition: .3s ease-in-out;
	font-size: 2rem;
	font-weight: 600;
	margin: 1rem 0;
	transform: translateY(-20px);
	opacity: 0;
}

@media (min-width: 768px) {
	.card-box-d .title-d {
		font-size: 1.1rem;
		margin: .5rem 0;
	}
}

@media (min-width: 992px) {
	.card-box-d .title-d {
		font-size: 1.5rem;
		margin: 1rem 0;
	}
}

@media (min-width: 1200px) {
	.card-box-d .title-d {
		font-size: 2rem;
		margin: 1rem 0;
	}
}

.card-box-d .content-d {
	opacity: 0;
	transition: .5s ease-in-out;
	transform: translateY(-40px);
}

@media (min-width: 768px) {
	.card-box-d .content-d {
		font-size: .8rem;
		margin: .5rem 0;
	}
}

@media (min-width: 768px) {
	.card-box-d .card-body-d p {
		margin-bottom: 8px;
		font-size: .8rem;
	}
}

@media (min-width: 992px) {
	.card-box-d .card-body-d p {
		margin-bottom: 12px;
		font-size: 1rem;
	}
}

.card-box-d .info-agents {
	opacity: 0;
	transition: .5s ease-in-out;
}

.card-box-d .card-footer-d {
	transition: .5s ease-in-out;
	transform: translateY(40px);
	opacity: 0;
	position: absolute;
	width: 100%;
	bottom: 10px;
	left: 0;
}

.card-box-d .list-inline-item:not(:last-child) {
	margin-right: 25px;
}

.card-box-d:hover .card-overlay-hover {
	background-color: #F58220;
	opacity: .9;
}

.card-box-d:hover .title-d,
.card-box-d:hover .content-d,
.card-box-d:hover .info-agents,
.card-box-d:hover .card-footer-d {
	opacity: 1;
	transform: translateY(0);
}


/*------/ Agent Single /------*/

.agent-info-box .socials-footer {
	margin-top: 2rem;
}

.agent-info-box .socials-footer li {
	margin-right: 1.5rem;
}


/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/

.section-about .title-vertical {
	position: absolute;
	letter-spacing: 0;
	transform: rotate(-90deg);
	top: 50px;
	text-align: left;
	font-size: 1.8rem;
	width: 300px;
	font-weight: 700;
	text-align: right;
}

.about-img-box {
	padding-left: 1rem;
	position: relative;
}

@media (min-width: 576px) {
	.about-img-box {
		padding-left: 4rem;
	}
}

@media (min-width: 768px) {
	.about-img-box {
		padding-left: 5rem;
	}
}

.sinse-box {
	background-color: #F58220;
	padding: 1rem 2rem;
	position: absolute;
	left: 15px;
	bottom: 10px;
}

@media (min-width: 576px) {
	.sinse-box {
		padding: 1.5rem 2.5rem;
		bottom: 20px;
	}
}

@media (min-width: 768px) {
	.sinse-box {
		padding: 1.5rem 3rem;
	}
}

@media (min-width: 992px) {
	.sinse-box {
		padding: 3rem 4.5rem;
	}
}

.sinse-box .sinse-title {
	font-weight: 700;
	letter-spacing: 0.043em;
	font-size: 1rem;
}

@media (min-width: 576px) {
	.sinse-box .sinse-title {
		font-size: 1.3rem;
	}
}

@media (min-width: 768px) {
	.sinse-box .sinse-title {
		font-size: 1.8rem;
	}
}

@media (min-width: 992px) {
	.sinse-box .sinse-title {
		font-size: 2.2rem;
	}
}

.sinse-box .sinse-title span {
	position: absolute;
}

.sinse-box .sinse-title span:after {
	content: ' ';
	width: 35px;
	height: 2px;
	background-color: #000000;
	position: absolute;
	bottom: -15px;
}

.sinse-box p {
	margin-bottom: 0;
	font-size: .7rem;
}

@media (min-width: 576px) {
	.sinse-box p {
		font-size: .8rem;
	}
}

@media (min-width: 576px) {
	.sinse-box p {
		font-size: 1rem;
	}
}


/*--------------------------------------------------------------
# business-ex Section
--------------------------------------------------------------*/

.business-ex-box {
	padding: 0;
	/* text-align: center; */
	margin: 0 10px;
}

.business-ex-box .testimonial-avatar {
	width: 80px !important;
	display: inline-flex !important;
	border-radius: 50%;
}


/* 
.testimonial-ico {
  text-align: center;
}

.testimonial-ico span {
  font-size: 1.8rem;
  background-color: #F58220;
  color: #ffffff;
  padding: .4rem 1rem;
  border-radius: 50px;
}

.testimonial-text {
  font-style: italic;
  margin-top: 25px;
  padding: 1.5rem 1.5rem;
  background-color: #f3f3f3;
  position: relative;
}

.testimonial-text:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 25px;
  width: 0px;
  height: 0px;
  border-top: 15px solid #f3f3f3;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.testimonial-author-box {
  margin-top: 2rem;
}

.testimonial-author {
  margin-left: 1rem;
  display: inline-flex;
  font-size: 1.2rem;
  color: #000000;
} */


/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/

.form-as #sendmessage {
	color: #F58220;
	border: 1px solid #26a356;
	display: none;
	text-align: center;
	padding: 15px;
	font-weight: 600;
	margin-bottom: 15px;
}

.form-as #errormessage {
	color: red;
	display: none;
	border: 1px solid red;
	text-align: center;
	padding: 15px;
	font-weight: 600;
	margin-bottom: 15px;
}

.form-as #sendmessage.show,
.form-as #errormessage.show,
.form-as .show {
	display: block;
}

.form-as .validation {
	color: red;
	display: none;
	margin: 4px 0 20px 0;
	font-weight: 400;
	font-size: 13px;
}

.form-as label {
	color: #000000;
	position: relative;
}

.form-as select.form-control-lg:not([size]):not([multiple]) {
	height: 3.5rem;
}

.form-as .form-control {
	border-radius: 0;
	font-size: 1.1rem;
	font-weight: 300;
}

.form-as .form-control.form-control-a {
	height: 3.5rem;
}

.form-as .form-control:focus {
	box-shadow: none;
	border-color: #F58220;
}

.php-email-form .validate {
	display: none;
	color: red;
	margin: 0 0 15px 0;
	font-weight: 400;
	font-size: 13px;
}

.php-email-form .error-message {
	display: none;
	color: #fff;
	background: #ed3c0d;
	text-align: center;
	padding: 15px;
	font-weight: 600;
}

.php-email-form .sent-message {
	display: none;
	color: #fff;
	background: #18d26e;
	text-align: center;
	padding: 15px;
	font-weight: 600;
}

.php-email-form .loading {
	display: none;
	background: #fff;
	text-align: center;
	padding: 15px;
}

.php-email-form .loading:before {
	content: "";
	display: inline-block;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 10px -6px 0;
	border: 3px solid #18d26e;
	border-top-color: #eee;
	-webkit-animation: animate-loading 1s linear infinite;
	animation: animate-loading 1s linear infinite;
}

.php-email-form label {
	color: #000000;
	position: relative;
}

.php-email-form select.form-control-lg:not([size]):not([multiple]) {
	height: 3.5rem;
}

.php-email-form .form-control {
	border-radius: 0;
	font-size: 1.1rem;
	font-weight: 300;
}

.php-email-form .form-control.form-control-a {
	height: 3.5rem;
}

.php-email-form .form-control:focus {
	box-shadow: none;
	border-color: #F58220;
}

@-webkit-keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.section-footer {
	padding-top: 60px;
	margin-top: 60px;
	background: #f3f3f3;
}

.section-footer ul {
	line-height: 2;
}

.section-footer .item-list-a i {
	font-size: 18px;
	padding-right: 4px;
	color: #F58220;
}

.section-footer .widget-a .w-title-a {
	margin-bottom: 1rem;
}

footer {
	background: #f7f7f7;
	text-align: center;
	padding: 30px 0;
	font-size: 14px;
}


#hero .bex-hero-section-btn {
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 6px 30px;
	border-radius: 4px;
	transition: 0.5s;
	/* margin-top: 30px; */
	color: #fff;
	background: #e03a3c;
	border: 2px solid #e03a3c;
}

#hero h3 {
	color: #fff;
	margin: 23px 0 0 0;
	font-size: 23px;
}

.bex-form-control form {
	margin-top: 30px;
}

.bex-btn-primary {
	color: #fff;
	background-color: #1b9f84;
	border-color: #1b9f84;
}

.bex-btn-primary:hover {
	color: #fff;
	background-color: #1b9f84;
	border-color: #1b9f84;
}

.bex-form-section-main h5 {
	font-weight: 700;
	text-align: center;
}

.bex-form-section-main {
	border-bottom: 1px solid #c1c1c1;
	padding: 25px 10px 10px 10px;
}

.bex-form-section {
	padding: 30px 15px 30px 15px;
}

.btn-outline-secondary-main {
	color: #1b9f84;
	border-color: #1b9f84;
}

.btn-outline-secondary-main:focus,
.btn-check:checked+.btn-outline-secondary-main:focus,
.btn-outline-secondary-main.active:focus,
.btn-outline-secondary-main.dropdown-toggle.show:focus,
.btn-outline-secondary-main:active:focus {
	box-shadow: 0 0 0 0.2rem #1b9f84;
}


/* .btn-outline-secondary-main, .btn-check:checked+.btn-outline-secondary-main, .btn-outline-secondary-main.active, .btn-outline-secondary-main.dropdown-toggle.show, .btn-outline-secondary-main:active {
  color: #fff;
  background-color: ##1b9f84;
  border-color: ##1b9f84;
} */

.bex-form-top-btn {
	text-align: center;
}

.bex-section-business-ex {}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#hero {
	width: 100%;
	/* height: 100vh; */
	background: url("../img/hero-bg.jpg") top center no-repeat;
	background-size: cover;
	position: relative;
	/* padding-top: 118px !important; */
}

#hero:before {
	content: "";
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

#hero h1 {
	margin: 0;
	font-size: 34px;
	font-weight: 700;
	color: #fff;
}

#hero h2 {
	color: #fff;
	margin: 10px 0 0 0;
	font-size: 14px;
	font-weight: 400;
}
#hero h2 strong { font-weight: 600;}
#hero .btn-get-started {
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 10px 30px;
	border-radius: 4px;
	transition: 0.5s;
	margin-top: 30px;
	color: #fff;
	background: #e03a3c;
	border: 2px solid #e03a3c;
}

#hero .btn-get-started:hover {
	background: transparent;
	border-color: #fff;
}



.bex-form-section .input-group-text {background: #F8F8F8;}

.imgblk { width: 190px; margin: 0 auto;}

.title-box { margin-top: 5px;}
.title-box h2 { font-size: 32px; line-height: 32px; color: #231F20; }
.title-box h5 { color: #231F20; opacity: 0.75; font-size:14px; line-height: 20px; font-weight: 400;}


@media (max-width: 768px) {
	#hero {
		text-align: center;
		padding-top: 86px;
	}
	#hero h1 {
		font-size: 28px;
	}
	#hero h2 {
		font-size: 18px;
		line-height: 24px;
	}
}

@media (max-height: 500px) {
	#hero {
		/* height: 120vh; */
	}
}

#hero {
	text-align: center;
	/* padding-top: 82px; */
	padding-bottom: 35px;
}

.bex-hero-section-hed {
	color: #fe7806;
}

.title-box h5 {
	color: #797474;
}

/*.bex-card-margin {
	padding: 10px;
}*/
.bex-all-popular-bussiness-main {padding: 15px;}
#myTab{margin:0 10px;}
#myTabContent { padding-left: 10px;}
.bex-main-text-aling {
	text-align: center;
}

.owl-dot {
	margin: 0 5px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #a5a5a5;
}

#bex-bussiness-carousel .owl-nav,
#bex-bussiness-carousel .owl-dots {
	margin-top: 25px;
	text-align: center;
}

#bex-bussiness-carousel .owl-dot {
	display: inline-block;
	margin: 0 5px;
	/* width: 7px; */
	/* height: 7px; */
	border-radius: 50%;
	background-color: #ddd;
	width: 10px;
	height: 10px;
}

#bex-bussiness-carousel .owl-dot.active {
	background-color: #F58220;
}

#bex-featured-business-for-sale-carousel .owl-nav,
#bex-featured-business-for-sale-carousel .owl-dots {
	margin-top: 25px;
	text-align: center;
}

#bex-featured-business-for-sale-carousel .owl-dot {
	display: inline-block;
	margin: 0 5px;
	/* width: 7px; */
	/* height: 7px; */
	border-radius: 50%;
	background-color: #ddd;
	width: 10px;
	height: 10px;
}

#bex-featured-business-for-sale-carousel .owl-dot.active {
	background-color: #F58220;
}

.ribbon {
	width: 150px;
	position: absolute;
	top: 10px;
	background: #F58220;
	color: #fff;
	font-size: 13px;
	padding: 5px 0px 5px 15px;
}
#ribbon-container {
    position: absolute;
    top: 15px;
    /* left: -20px; */
    overflow: visible;
    /* so we can see the pseudo-elements we're going to add to the anchor */
    font-size: 12px;
    /* font-size and line-height must be equal so we can account for the height of the banner */
    line-height: 18px;
}
#ribbon-container:before {
    content: "";
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
    /* border-top: 29px solid rgba(0, 0, 0, .3); */
    /* These 4 border properties create the first part of our drop-shadow */
    /* border-bottom: 29px solid rgba(0, 0, 0, .3); */
    /* border-left: 29px solid rgba(0, 0, 0, .3); */
    /* border-right: 29px solid transparent; */
}
#ribbon-container:after {
    /* This adds the second part of our dropshadow */
    /* content: ""; */
    /* height: 3px; */
    /* background: rgba(0, 0, 0, .3); */
    display: block;
    /* position: absolute; */
    /* bottom: -3px; */
    /* right: 58px; */
    /* left: 3px; */
}
#ribbon-container a {
    display: block;
    padding: 8px 30px 8px 30px;
    position: relative;
    /* allows us to position our pseudo-elements properly */
    background: #F58220;
    overflow: visible;
    height: 30px;
    margin-right: 28px;
    color: #fff;
    text-decoration: none;
}
#ribbon-container a:after {
    /* this creates the "folded" part of our ribbon */
    /* content: ""; */
    /* height: 0; */
    /* width: 0; */
    /* display: block; */
    /* position: absolute; */
    /* bottom: -15px; */
    left: 0;
    /* border-top: 15px solid #004a70; */
    /* border-left: 15px solid transparent; */
}
#ribbon-container a:before {
    /* this creates the "forked" part of our ribbon */
    content: "";
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    top: 0;
    right: -16px;
    border-top: 15px solid #F58220;
    border-bottom: 15px solid #F58220;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
}
#ribbon-container a:hover {
    background: #F58220;
}
#ribbon-container a:hover:before {
    /* this makes sure that the "forked" part of the ribbon changes color with the anchor on :hover */
    border-top: 15px solid #F58220;
    border-bottom: 15px solid #F58220;
}

.bex-bussiness-section-info {
	padding: 10px;
}

.bex-bussiness-section-info p {
	margin-block-end: 0.2em;
	font-size: 13px;
}

.bex-bussiness-section-info h6 {
	margin-block-end: 0.2em;
	font-size: 14px;
	text-align: center;

}

.bex-bussiness-section-info-top {
	border-bottom: 1px solid #e03a3c;
}

.bex-bussiness-section-info-main {
	padding: 10px 10px 10px 10px;
	border-top: 1px solid #e6e6e6;
}

.bex-contact-info {
	margin-left: 5px;
}

.bex-contact-info i {
	font-size: 17px;
	margin-right: 10px;
}

.bex-location-info {
	list-style: none;
	padding-inline-start: 0px;
}

.bex-bussiness-section-info-main p {
	margin-block-end: 0em;
	padding: 9px 0px 0px 0px;
	font-size: 13px;
	margin-left: 5px;
}

.bex-service-tags {
	list-style: none;
	margin-bottom: 0rem;
	padding-inline-start: 0px;
	font-size: 14px;
	margin-top: 15px;
}

ul.bex-service-tags li {
	display: inline;
	margin: 2px;
	background:rgba(245,130,32,0.2);
	border-radius: 50px;
	padding: 8px;
	font-size: 12px;
	color: #231F20;
}

.bex-service-tags-btn a {
	color: #ffffff;
	padding: .3rem .4rem;
	border: 2px solid #1b9f84;
	color: #1b9f84;
	border-radius: 5px;
	text-transform: uppercase;
	letter-spacing: 0.030em;
}

.bex-service-tags-btn {
	text-align: center;
	padding-top: 35px;
	padding-bottom: 35px;
}

.top-main-arrow-section {
	display: inherit;
}

.bex-mentor-section-main {
	padding: 10px;
}

.bex-mentor-section-info {
	padding-top: 10px;
}

.bex-mentor-section-info P {
	font-size: 11px;
}

.bex-summary {
	font-size: 12px;
	padding-top: 10px;
}

.bex-summary-info {
	font-size: 12px;
	color: #000;
	font-weight: 600;
	height: 30px;
}

#bex-Featured-mentors-carousel .owl-nav,
#bex-Featured-mentors-carousel .owl-dots {
	margin-top: 25px;
	text-align: center;
}

#bex-Featured-mentors-carousel .owl-dot {
	display: inline-block;
	margin: 0 5px;
	/* width: 7px; */
	/* height: 7px; */
	border-radius: 50%;
	background-color: #ddd;
	width: 10px;
	height: 10px;
}

#bex-Featured-mentors-carousel .owl-dot.active {
	background-color: #F58220;
}


/* .{
  background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0.2) 30%, #000 70%);
}
.bex-card-margin div{
    position: absolute;
    bottom: 10px;
    padding: 10px;
}
.bex-card-margin a{
  
} */

.bex-overlay-btn {
	position: absolute;
	bottom: 10px;
	padding: 10px;
}

.title-main a {
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-bse {
	border: 2px solid #1b9f84;
	color: #1b9f84 !important;
	background-color: #fff;
	font-weight: 700;
	border-radius: 5px;
}

.bse-btn-overlay-w {
	/*background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) -10%, #F58220b8 100%);*/
	
  background-image: linear-gradient(to bottom, rgba(223,166,117,0), rgba(223,166,117,1));

	width: 100%;
	text-align: center;
	bottom: 0;
}

.bx-insight #bx-insight-flters li:hover,
.bx-insight #bx-insight-flters li.filter-active {
	color: #000 !important;
	/* background: #f08225; */
	border-bottom: 4px solid #f08225;
}

.bx-insight #bx-insight-flters li:last-child {
	margin-right: 0;
}

.bx-insight .bx-insight-wrap {
	transition: 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background: rgba(17, 17, 17, 0.6);
}

.bx-insight .bx-insight-wrap::before {
	content: "";
	background: rgba(17, 17, 17, 0.6);
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transition: all ease-in-out 0.3s;
	z-index: 2;
	opacity: 0;
}

.bx-insight .bx-insight-wrap img {
	transition: all ease-in-out 0.3s;
}

.bx-insight .bx-insight-wrap .bx-insight-info {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	transition: all ease-in-out 0.3s;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	padding: 20px;
}

.bx-insight .bx-insight-wrap .bx-insight-info h4 {
	font-size: 20px;
	color: #fff;
	font-weight: 600;
}

.bx-insight .bx-insight-wrap .bx-insight-info p {
	color: rgba(255, 255, 255, 0.7);
	font-size: 14px;
	text-transform: uppercase;
	padding: 0;
	margin: 0;
	font-style: italic;
}

.bx-insight .bx-insight-wrap .bx-insight-links {
	text-align: center;
	z-index: 4;
}

.bx-insight .bx-insight-wrap .bx-insight-links a {
	color: rgba(255, 255, 255, 0.4);
	margin: 0 5px 0 0;
	font-size: 28px;
	display: inline-block;
	transition: 0.3s;
}

.bx-insight .bx-insight-wrap .bx-insight-links a:hover {
	color: #fff;
}

.bx-insight .bx-insight-wrap:hover::before {
	opacity: 1;
}

.bx-insight .bx-insight-wrap:hover img {
	transform: scale(1.2);
}

.bx-insight .bx-insight-wrap:hover .bx-insight-info {
	opacity: 1;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #F58220;
	background-color: #f5f5f5 !important;
	border-color: #fff #fff #fff !important;
	border-bottom: 5px solid #F58220 !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	/* border-color: #fff0 #fff0 #fff0 !important; */
	outline: 0;
}

.nav-tabs .nav-link {
	border: 0px solid transparent !important;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem;
}

.bex-card-info-main p {
	margin-bottom: 0.4rem !important;
}

.bex-dates {
	font-size: 12px;
	padding-top: 10px;
}

.bex-summury {
	font-size: 12px;
	color: #000000;
}

.bex-writer {
	font-size: 12px;
}
.bex-card-padding-tblr {
  padding: 10px;
}
.bex-card-info-main{
  padding-top: 10px;
}
.bex-card-info-main p {
  font-size: 12px;
  color: #000;
}
.bex-plans-card-bottom {
  border-top: 0px solid #dadada;
  padding-top: 25px;
}
.bex-plans-btn{
  padding-top: 10px;
  padding-bottom: 20px;

}
.btn-outline-secondary {
  color: #000 !important;
  border-color: #000 !important;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 0%) !important;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.bex-plans-main h4 {
  padding-top: 20px;
}
.bex-most-popular span {
  /* position: inherit; */
  background-color: #000;
  padding: 10px 30px 10px 30px;
  border-radius: 50px;
  /* margin-top: -9px; */
  color: #fff;
}
.bex-most-popular{
  margin-top: -10px;
}
.bex-most-popular-text {
    color: #554580;
}
.btn-light {
    color: #ffffff !important;
    background-color: #F58220 !important;
    border-color: #F58220 !important;
}
.bex-premium-price {
	color: #F58220;
}
.card {margin-bottom: 10px; min-height: 320px;}


#bex-upcoming-past-events-carousel .owl-nav,
#bex-upcoming-past-events-carousel .owl-dots {
	margin-top: 25px;
	text-align: center;
}

#bex-upcoming-past-events-carousel .owl-dot {
	display: inline-block;
	margin: 0 5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ddd;
}

#bex-upcoming-past-events-carousel .owl-dot.active {
	background-color: #F58220;
}

#bex-Featured-Start-Ups-carousel .owl-nav,
#bex-Featured-Start-Ups-carousel .owl-dots {
	margin-top: 25px;
	text-align: center;
}

#bex-Featured-Start-Ups-carousel .owl-dot {
	display: inline-block;
	margin: 0 5px;
	/* width: 12px; */
	/* height: 12px; */
	border-radius: 50%;
	background-color: #ddd;
	width: 10px;
	height: 10px;
}

#bex-Featured-Start-Ups-carousel .owl-dot.active {
	background-color: #F58220;
}
/* bex-featured-investors-carousel */

#bex-featured-investors-carousel .owl-nav,
#bex-featured-investors-carousel .owl-dots {
	/* margin-top: -14px; */
	text-align: center;
}

#bex-featured-investors-carousel .owl-dot {
	display: inline-block;
	margin: 0 5px;
	/* width: 12px; */
	/* height: 12px; */
	border-radius: 50%;
	background-color: #ddd;
	width: 10px;
	height: 10px;
}

#bex-featured-investors-carousel .owl-dot.active {
	background-color: #F58220;
}
#main{
	background-color: #f5f5f5;
}
.b-white{
	background-color: #fff;
}

.bex-main-info-card p {
    font-size: 15px;
	font-weight: 700;
	margin-block-end: 0em;
}
.bex-main-info-summury{
	font-size: 13px;
}

.bex-author-name-info {
	font-size: 13px;
	font-weight: 700;
	margin-block-end: 0em;
	padding-bottom: 5px;
}
.bex-author-conpany-info{
	font-size: 13px;
	/* font-weight: 700; */
	margin-block-end: 0em;
}
.bex-main-info-summury{
	margin-block-end: 0em;
	padding-top: 10px;
	padding-bottom: 10px;
}
.bex-business-main-btn{
	padding: 25px 0px 25px 0px;
}
.bex-business-main-btn a{
	color: #ffffff;
	padding: .3rem .4rem;
	border: 2px solid #1b9f84;
	color: #1b9f84;
	border-radius: 5px;
	text-transform: uppercase;
	letter-spacing: 0.030em;
}
.bex-main-info-card-timing-img img{
	width: 15px !important;
	margin-right: 10px;
}
.bex-main-info-card-timing{
	display: flex;
	padding-top: 5px;
}
/* .bex-bussiness-section{
	padding: 10px;
} */
.bex-main-info-card-timing-img{
	margin-right: 25px;
	display: flex;
	font-size: 12px;
}

.bex-main-info-card-timing-img:last-child {
		margin-right: 0px;
}
.bex-main-info-summury-main { min-height:155px; }

.btn-success-main{
	color: #fff !important;
    background-color: #1b9f84 !important;
    border-color: #1b9f84 !important;
}
.bex-bt{
	border-top: 1px solid #000;
}
.footer-newsletter form {
    margin-top: 16px;
    background: #ececec;
    padding: 5px 10px;
    position: relative;
    border-radius: 4px;
    text-align: left;
}

.footer-newsletter form input[type="submit"]:hover {
    background: #000000;
}
 .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #000000;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
    background-color: #ececec;
    outline: 0;
}
.social-links a {
    font-size: 18px;
    display: inline-block;
    background: #2b2b2b;
    color: #fff;
    /* line-height: 1; */
    /* padding: 8px 0; */
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}
.bex-listing-section{
	padding-inline-start: 0px;
	list-style: none;
}
.bex-popular-search-section {
	padding-inline-start: 0px;
	list-style: none;
	/* margin-top: 10px; */
	font-size: 13px;
}
ul.bex-popular-search-section li {
    float: left;
    padding-right: 6px;
}
ul.bex-popular-search-section li::after {
    content: "|";
}
ul.bex-popular-search-section li:last-child:after {
    content: "";
}
ul.bex-popular-search-section li a {
    padding: 0px 7px 0px 0px;
}
.bex-footer-bottom-links{
	padding-inline-start: 0px;
	list-style: none;
	/* margin-top: 10px; */
	/* font-size: 13px; */
}
ul.bex-footer-bottom-links li{
	float: left;
	padding-right: 7px;
}

#bx-insight {
	padding: 2rem 0;
	}
  
  #bx-insight #bx-insight-flters {
	padding: 0;
	margin: 5px 0 35px 0;
	list-style: none;
	/* text-align: center; */
	}
  
  #bx-insight #bx-insight-flters li {
	cursor: pointer;
	margin: 15px 15px 15px 0;
	display: inline-block;
	padding: 6px 10px;
	font-size: 16px;
	line-height: 20px;
	color: #413e66;
	margin-bottom: 5px;
	transition: all 0.3s ease-in-out;
  }
  
  #bx-insight #bx-insight-flters li:hover, #bx-insight #bx-insight-flters li.filter-active {
	color: #ffffff;
	}
  
  #bx-insight #bx-insight-flters li:last-child {
	margin-right: 0;
  }
  
  #bx-insight .bx-insight-item {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap {
	overflow: hidden;
	position: relative;
	margin: 0;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap:hover img {
	opacity: 0.4;
	transition: 0.3s;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	opacity: 0;
	transition: 0.2s linear;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info h4 {
	font-size: 22px;
	line-height: 1px;
	font-weight: 700;
	margin-bottom: 14px;
	padding-bottom: 0;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info h4 a {
	color: #fff;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info h4 a:hover {
	color: #1bb1dc;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info p {
	padding: 0;
	margin: 0;
	color: #f8fcff;
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info .link-preview, #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info .link-details {
	display: inline-block;
	line-height: 1;
	text-align: center;
	width: 36px;
	height: 36px;
	background: #1bb1dc;
	border-radius: 50%;
	margin: 10px 4px 0 4px;
  }
  /* .ribbon:before{
	  font-style: normal;
  } */
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info .link-preview i, #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info .link-details i {
	padding-top: 6px;
	font-size: 22px;
	color: #fff;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info .link-preview:hover, #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info .link-details:hover {
	background: #42c3e8;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info .link-preview:hover i, #bx-insight .bx-insight-item .bx-insight-wrap .bx-insight-info .link-details:hover i {
	color: #fff;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap:hover {
	background: #282646;
  }
  
  #bx-insight .bx-insight-item .bx-insight-wrap:hover .bx-insight-info {
	opacity: 1;
  }
  .navbar-brand {
    margin-right: 0rem !important;
}
.navbar-toggler {
    padding: 0rem 0rem !important;
}
@media screen and (max-width: 350px) { 
	.btn {
		padding: 0rem 0.1rem !important;
	}
}
.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 0rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #f5f5f500 !important;
    border-radius: 0rem;
}
.bex-filter{
	padding-left: 10px;
}
.btn-outline-secondary-filter{
	border: 1px solid #F58220;
	color: #F58220;
}
.card-mt15{
	margin-top: 30px;
}
.filter-main{
	width: 100%;
}
.bex-range-here{
	font-size: 13px;
    font-weight: 700;
}
.fr {
	float:right;
}
.fl{
	float: left;
}
.bex-input-range-output{
	display: inline-flex;
    padding-top: 20px;
}

.ui-slider-popup {
    position: absolute !important;
    width: 64px;
    height: 64px;
    text-align: center;
    font-size: 36px;
    padding-top: 14px;
    z-index: 100;
    opacity: 0.8;
}
.bex-popular-categories{
	list-style: none;
	padding-inline-start: 0px;
}
.bex-trending-search-tab{
	list-style: none;
	padding-inline-start: 0px;
}
ul.bex-trending-search-tab li {
    float: left;
    padding: 5px 15px;

    font-size: 12px;
    background-color: #ffffff;
    margin-left: 13px;
    border-radius: 10px;
    /* padding: 1px; */
}

ul.bex-trending-search-tab li a { color: #231F20;}



.bex-search-section{
	display: flex;
	font-size: 15px;
}
.bex-heading-main{
	font-weight: 600;
}
.mt20px{
	margin-top: 20px;
}
.fnt-16px{
	font-size: 15px;
}
.bex-view-all-section{
	position: absolute;
	/* float: right; */
	width: 98%;
	margin-top: -64px;
	text-align: right;
	padding-right: 22px !important;
	/* padding: 20px; */
}
.slider-tick.in-selection {
    background-image: -webkit-linear-gradient(top, #d1620000 0%, #F5822000 100%);
    background-image: -o-linear-gradient(top, #d16200 0%, #F58220 100%);
    background-image: linear-gradient(to bottom, #d1620000 0%, #F5822000 100%);
    /* background-repeat: repeat-x; */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff89cdef', endColorstr='#ff81bfde', GradientType=0);
    opacity: 1;
}
.slider-selection.tick-slider-selection {
    background-image: -webkit-linear-gradient(top, #F58220 0%, #F58220 100%);
    background-image: -o-linear-gradient(top, #F58220 0%, #F58220 100%);
    background-image: linear-gradient(to bottom, #F58220 0%, #F58220 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe08433', endColorstr='#ff81bfde', GradientType=0);
}
.slider-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #da7720;
    background-image: -webkit-linear-gradient(top, #da7720 0%, #da7720 100%);
    background-image: -o-linear-gradient(top, #da7720 0%, #da7720 100%);
    background-image: linear-gradient(to bottom, #d87116 0%, #d9741b 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
    filter: none;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    border: 0px solid transparent;
}


.emaishow { color: #231F20; font-size: 12px; font-weight: 400; text-align: center;}


.soc { text-align: center; margin: 10px 0;}
.soc .innsoc{ display: inline-block; margin: 0 10px;}

.frmblk { width: 80%; margin: 0 auto; margin-top: 20px;}
.frmblk .mb-4, .frmblk  .my-4 {    margin-bottom: 0.7rem!important;}
.close { position: absolute; top:-20px; right:-20px;     opacity: 1;
  } 
.close span { width:40px; height: 40px; border-radius: 50%; background: #fff; 
	color: #333;    padding: 8px; display: block;}


#login .modal-content  { padding:0px; border-radius: 0;}

.frmblk .input-group-text { background:transparent; width: 50px; border-right:none;}
.frmblk  .form-control { height: 46px;}
.ffull { width: 100%; overflow: hidden;}
.pleft { float: left; font-size: 12px;}
.pright { float: right; font-size: 12px;}
.pright a { color: #16A085;}

.popbtn  { width: 140px; height: 41px;  margin:20px auto 0; background:#16A085;
color: #fff; text-align: center; font-size: 16px; font-weight: bold; display: block;
border: none;
 }
#login .modal-dialog { max-width: 805px;}
#login .modal-body { padding: 0px;}
.popimgleft { float: left; width: 324px; height:556px; 
 background: url('../img/login-register-pop-img.jpg') no-repeat;
}
.poptxtright { float: left; width: 479px; text-align: center;
padding-left: 30px; padding-top: 30px;
}
.textblk { display: table; height: 100%; width: 100%;}
.innertextblk { display: table-cell; vertical-align: middle;  text-align: center;}


.h2bg { font-size:24px; color: #fff; font-weight: 400;  }

p.txt {margin-top:20px; opacity: 0.75; color: #fff; font-size: 16px; font-weight: 400; padding: 0 20px; }

ul.frmlist li .form-control.invalid-border { border-color: #fff; }

.nav-tabs .nav-link { font-size: 16px;  color: #231F20; padding: 10px 0; margin-right:45px; }
.poptxtright .nav-tabs .nav-item.show .nav-link, .poptxtright .nav-tabs .nav-link.active
{ border-bottom: 2px solid #F58220 !important; color: #F58220;    
 background-color: transparent!important;  }
.profileimg { width: 25px; height: 25px; border-radius: 50%; margin-right: 5px; }


.navbar-default.navbar-trans .nav-item.myacc { } 

.myacc .navbar-default.navbar-reduce .nav-link:before { display: none;}

.myacc .dropdown-toggle::after{ display: none; }
.myacc .navbar-default.navbar-reduce .nav-link:before { display: none;}

 .navbar-default  .dropdown.myacc .dropdown-menu  { 
    width: 210px;     left: -70px;
	top:65px;}

.navbar-default.navbar-trans .dropdown.myacc .nav-link:before { background: transparent;}
 .navbar-default.navbar-trans .dropdown.myacc  .nav-link:hover { background: transparent;}
.navbar-default.navbar-trans  .dropdown.myacc .nav-link:before,  .navbar-default.navbar-reduce .dropdown.myacc .nav-link:before { display: none;}
.navbar-default .dropdown.myacc .dropdown-menu { border-top:0px;}

.navbar-default .dropdown.myacc .dropdown-menu .dropdown-item { 
	padding:5px;  font-size: 16px; color: #231F20;     font-weight: 400;
    min-width: auto; }


.navbar-default .dropdown.myacc .dropdown-menu .dropdown-item:hover {    min-width: auto;  padding:5px; font-size: 16px; color: #231F20;}


ul.mylist { padding: 0; margin: 0; } 
ul.mylist li { padding-bottom:10px; margin-bottom: 10px; border-bottom: 1px solid #dfdfdf; list-style: none;  
font-size: 16px; opacity: 0.75; color: #231F20;
 } 
ul.mylist li:last-child{ border-bottom: 0;}

ul.mylist a {color: #231F20;}

.plist { margin-left: 40px;}
.pinnlist { padding-bottom: 0px; font-size: 14px; opacity: 0.75; color: #231F20;}
.pinnlist a{color: #231F20;}

.navbar-default.navbar-trans .dropdown.myacc .nav-link, .navbar-default.navbar-reduce .dropdown.myacc .nav-link {     color: #fe7806;}


.getindustryfirstinsights{
  padding-top: 10px;
  padding-bottom: 0px;
}
.getindustryfirstinsights .getindustry-inner{
  background-image: url('../img/footer-orange.jpg');
  background-repeat: no-repeat;
  background-position: center center;

  color: #fff;
  padding: 30px;
  width: 1216px;
  height: 308px;
  margin: 0 auto;
}


.emailbg-inner{
  background-image: url('../img/footer-social-blk.jpg');
  background-repeat: no-repeat;
  background-position: center center;

  color: #fff;
  padding: 12px 30px;

  width: 1216px;
  height: 60px;
  margin: 0 auto;
}


ul.frmlist { padding: 0px; margin: 0px; text-align: left; }
ul.frmlist li { width: 46%; list-style: none;  display: inline-block; margin: 0 10px 25px; }



ul.frmlist li .form-control  {border:1px solid #fff; background: transparent; height: 40px; font-size: 15px; color: #fff; }

.btn-sub { margin-left: 10px; border:none; border-radius: 25px; background: #fff; font-size: 15px; 
  color: #F58220; padding: 15px 25px; }

ul.socmedia { text-align: left; padding: 0px; }
ul.socmedia li{ display: inline-block; margin-left: 15px; }

.trst { color: #fff; font-size: 16px; text-align: right; margin-top: 5px;}
ul.socmedia li.linkedin {
    background: #0077B7;
    border-radius: 4px; }
ul.socmedia li.linkedin a img {
    width: 36px;
    background: #fff;
    border-radius: 10px;
    /* padding: 5px; */
    border: 5px solid #0077B7;
}



li.folltxt { font-size: 16px;}
li.folltxt span { color: #F58525;}
ul.frmlist li input[type="email"]::placeholder {  
                  
          
                color: #fff;
            } 
ul.frmlist li input[type="text"]::placeholder {  
                  
                color: #fff;
            } 
       
   ul.frmlist li input[type="email"]:-ms-input-placeholder { 
                  
              color: #fff;
            } 
ul.frmlist li input[type="email"]::-ms-input-placeholder {  
                  
                color: #fff;
            }
.shosection { background: #fff; padding:20px 0; }
.shosection .container { padding:0; }
  ul.mainul { padding:0px; margin:0;   }
ul.mainul li { display: inline-block; width: 24.5%; padding: 0px; margin: 0px;}
ul.mainul li  a { font-size:16px; color:#000; line-height:22px;  }

ul.mainul li > ul.submainul {      margin-left: 15px;  padding-left: 5px;}
ul.mainul li ul.submainul li{ display: block; width: 100%; list-style: disc; font-size:13px; color: #666; }
ul.mainul li ul.submainul li a  {color: #666; font-size: 14px; }
ul.mainul li  .viewmore { margin-bottom: 20px;}
ul.mainul li  .viewmore a.ss { font-size:13px;  color: #006699; 
    text-transform:capitalize;}
ul.mainul li  .viewmore i { position: relative; top: 3px;}  
  ul.mainul { padding:0px; margin:30px 0 0 0;   }
ul.mainul li {}
ul.mainul li  a { font-size:16px; color:#000; line-height:22px;  }

ul.mainul li > ul.submainul {      margin-left: 15px;  padding-left: 5px;}
ul.mainul li ul.submainul li{ list-style: disc; font-size:13px; color: #666; }
ul.mainul li ul.submainul li a  {color: #666; font-size: 14px; }
ul.mainul li  .viewmore { margin-bottom: 7px;}
ul.mainul li  .viewmore a.ss { font-size:13px;  color: #006699; 
    text-transform:capitalize;}
ul.mainul li  .viewmore i { position: relative; top: 0px;}  

.shosection .nav-tabs .nav-item.show .nav-link, .shosection  .nav-tabs .nav-link.active 
{    background-color: transparent!important;}

.copyright { text-align: left;}


.backcol { margin-top: 30px;}
/*.mainsubheading { text-align: center; font-size:40px; line-height:60px; font-weight: 600; color: #404040;       padding-top: 150px; }*/


p.whattxt { text-align: center; font-size:16px;  font-weight: 400; color: #404040; margin-bottom:40px;    }

.slideblk {position: relative; width:350px; background:#fff;  font-size:13px; 
	line-height:17px;   border-radius:10px; height:175px; padding:15px; 
	color: #333; font-weight: 700;     }



.slideblk:after {
  top: 80%;
  left: 25%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.slideblk:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #fff;
 border-left: 5px solid transparent;
  border-right: 5px solid #fff;
    border-width: 36px;
  margin-left: -45px;
}





.comblk { width:250px; clear: both; margin:40px auto 0;  }
.cirimg { float: left; width: 50px;  }
.cirimg  img { width: 100%; border-radius: 50%; }
.circontent { padding:10px 0 0 10px; float: left; font-size:18px; line-height:18px;  font-weight: 600; color: #404040;  }
.circontent span {   display: block;  font-size:16px; color:#808080; font-weight: 400; }






.bex-main{
	padding-top: 100px;
}
.bex-like-nd-share{
	position: absolute;
	color: #fff;
	width: 100%;
	/* float: right; */
	text-align: right;
	top: 10px;
	padding: 10px;
}
.bex-like-nd-share a {
	color: #fff;
	background-color: #0000006b;
	font-size: 13px;
	padding: 8px;
	border-radius: 100%;
	/* line-height: 1; */
}
#heart.red {
	color: red;
  }
  
  .section-b2{
	  padding-bottom: 2rem;
  }
  .bex-author-timeline{
	  color: #888 !important;
	 }
	 .bex-features-section-main img{
		 width: 100%;
	 }
	 .bex-view-all-section2 {
		position: absolute;
		/* float: right; */
		width: 98%;
		margin-top: 15px;
		text-align: right;
		padding-right: 22px !important;
		padding: 0px;
	}
	.tab-pane{
		padding-top: 15px;
		/* padding-bottom: 15px; */
	}
	.bex-author-timeline2{
		padding-top: 10px;
		padding-bottom: 10px;
		border-top: 1px solid #d8d8d8;
		color: #978897;
	}
	.pt30{
		padding-top: 30px;
	}
	.bex-primary-btn{
		padding: 5px;
		border: 2px solid #1b9f84;
		color: #1b9f84;
		margin: 15px;
		text-align: center;
		border-radius: 5px;
		font-size: 12px;
	}
	.bex-primary-btn a {
		color: #1b9f84;
		font-weight: 600;
		text-transform: capitalize;
	}
	.bex-filter-heading h4{
		padding: 20px;
	}
	.btn-link {
		font-weight: 400;
		color: #828282;
		text-decoration: none;
	}
	.page-link{
		color: #1b9f84;
	}
	.page-item.active .page-link {
		z-index: 3;
		color: #ed882e !important;
		background-color: #ffffff !important;
		border-color: #e9e9e9 !important;
		font-weight: 700 !important;
	}
	.page-link:hover {
		z-index: 2;
		color: #1b9f84;
		text-decoration: none;
		background-color: #e9ecef;
		border-color: #dee2e6;
	}
	.btn-link:hover{
		color: #828282;
		text-decoration: none;
	}
	.pt-b15{
		/* padding-top: 15px; */
		padding-bottom: 25px;
	}
	.bex-view-icon-main {
		margin-bottom: 10px;
		background-color: #0006;
		color: #fff;
		padding: 5px;
		border-radius: 100%;
		width: 30px;
		height: 30px;
		float: right;
		text-align: center;
		margin-left: 10px;
	}
	.bex-sahring-icon-main{
		margin-bottom: 10px;
		background-color: #0006;
		color: #fff;
		padding: 5px;
		border-radius: 100%;
		width: 30px;
		height: 30px;
		float: right;
		margin-left: 10px;
	}
	.pb-30{
		padding-bottom: 30px;
	}
	.bex-social-sahre-icon{
		position: absolute;
		width: 100%;
		/* float: right; */
		/* text-align: right; */
		padding: 10px;
	}
	.card-header {
		padding: 0.25rem 1.25rem;
		margin-bottom: 0;
		background-color: rgb(255 255 255 / 0%);
		border-bottom: 0px solid rgb(255 255 255 / 13%);
	}
	.card-magin{
		margin-bottom: 0px;
	}
	.bex-author-infomain-user p{
		font-size: 13px;
    padding-top: 13px;
	}
	.bex-alingmen-main{
		width: 100%;
		text-align: left;
		text-transform: capitalize;
		font-size: 14px;
		font-weight: 600;
		padding: 15px 0px 15px 0px;
		color: #848484;
	}
	.bex-arrow{
		float: right;
	}

	
.bex-mentors-tags {
	list-style: none;
	margin-bottom: 0rem;
	padding-inline-start: 0px;
	font-size: 14px;
	margin-top: 15px;
}

ul.bex-mentors-tags li {
	display: inline;
	margin: 2px;
	background-color: #F5822063;
	border-radius: 50px;
	padding: 5px;
	color: #000000;
}

.bex-top-info-main{
	padding-top: 20px;
	font-size: 13px;
}
.mr-top30px{
	margin-top: 30px;
}
.mr-topbot30px{
	padding-top: 30px;
	padding-bottom: 30px;
}
.bex-primary-btn-inner {
    padding: 5px;
    border: 2px solid rgb(27, 159, 132);
    color: rgb(27, 159, 132);
    margin-top: 20px;
    text-align: center;
    border-radius: 5px;
}
.bex-primary-btn-inner a{
    color: rgb(27, 159, 132);
}
.card-p15{
	padding: 15px;
}
.bex-main-info-card-timing-img-main img {
    width: 17px !important;
    margin-right: 10px;
}
.bex-main-info-card-timing-img-main  {
    margin-right: 10px;
    display: flex;
    font-size: 13px;
}
.card-header-main{
	padding: 0.25rem 3.25rem;
}
.cardbody-main{
	padding: 0rem 3.25rem 0.25rem 3.25rem;
}
.user-avtar img{
	width: 80px;
	height: 80px;
	/* border: 4px solid #e9e9e9; */
	/* -webkit-box-shadow: 0px -3px 4px 12px rgba(0,0,0,0.28); */
	box-shadow: 0px -1px 4px 4px rgb(0 0 0 / 20%);
}
.box-user-profile ul{
	list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.box-user-profile{
	padding-top: 20px;
	padding-bottom: 20px;
}
.box-author-details{
	display: table-cell;
	vertical-align: top;
	padding-left: 25px;
	padding-top: 15px;
	width: 100%;
}
.user-avtar{
	
    display: table-cell;
    vertical-align: top;

}
.text-right-float{
	text-align: right !important;
    float: right !important;
}
.pt20{
	padding-top: 20px;
}
.social-links-share{
	padding-bottom: 15px;
	padding-top: 15px;
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
	/* display: inline-flex; */
}
.social-links-share div span{
	padding:10px;
	font-size: 14px;
}
.social-links-share div img{
	width: 16px;
}
.post-intro{
	padding-top: 20px;
}
.box-comments h5 {
	font-weight: 600;
}
.bex-like-section span img{
width: 16px;
}
.bex-like-section div span {
    padding: 10px;
    font-size: 14px;
}
.bex-like-section{
	border-top: 1px solid #e4e4e4;
}
.bex-report-this-comment{
	font-weight: 600;
}
.bex-section-sidebar{
	padding: 10px;
    border-bottom: 2px solid #f5f5f5;
}
.bex-post-date {
    padding-bottom: 10px;
    font-size: 14px;
}
.bex-card-post{
	padding: 10px;
	border-bottom: 2px solid #f5f5f5;
}
.bex-summury-text-main{
	padding-top: 10px;
    font-size: 14px;
}

.img-side-round{
	border-radius: 20px;
	border: 1px solid #f5f5f5;
}
.minipost-main p {
    color: #a9a9a9;
    font-size: 14px;
    margin-bottom: 0rem;
}
.bex-summury-main{
	font-size: 15px;
}
.sectiont2{
	padding-bottom: 0px;
	padding-top: 10px;
}
.sectiont2.topsec{
	padding-bottom: 20px;
	
}
 
.sectiont2.sec1  { margin-top: -10px; }


.pb15{
	padding-bottom: 15px;
}
.pt-b10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.social-links-share-post div span img{
	width: 15px;
}
.social-links-share-post div span {
    padding: 10px;
    font-size: 14px;
}
.social-links-share-post {
    /* padding-bottom: 5px; */
    padding-top: 8px;
    border-top: 1px solid #e4e4e4;
    /* border-bottom: 1px solid #e4e4e4; */
    /* display: inline-flex; */
}
.slider-tick {
	width: 0px !important;
    height: 0px !important;
}
.bex-amount-left{
	font-size: 12px;
	padding-right: 14px;
}
.bex-like-nd-share img{
width: 30px;
height: 30px;
}
.bex-selection-main img{
padding: 5px;
}
.bex-selection-main{
	padding-right: 10px;
}
.bex-selection{
	position: absolute;
	/* width: 100%; */
	/* float: right; */
	/* text-align: right; */
	top: 19px;
	right: 0;
}
.bex-amount-right {
    font-size: 12px;
    padding-left: 15px;
}
.bex-mentor-img{
	width: 100px;
	height: 100px;
}
.mr-10{
	margin-right: 10px
}
.slider.slider-horizontal {
    width: 145px;
    height: 20px;
}
.bex-bussiness-section {
	/* text-align: center; */
	/* display: initial; */
}
.bex-bussiness-section{
	/* width: 300px !important; */
	/* display: initial !important; */
}
.bex-card-m-main{
	box-shadow: 0px 0px 9px #00000029;
border: 1px solid #707070;
border-radius: 3px;
opacity: 1;
}
.share-icon-main{
	width: 30px !important;
	padding: 3px;
	margin-right: 17px;
}
.box .bottom {
	padding: 12px;
	background-color: red;
	color: white;
	display: none;
  }
  .active-main {
	transform: translateX(0px);
	background-color: red;
	opacity: 1;

	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.sectiont2.sec1 .owl-carousel { margin: 0px;  }
.sectiont2.sec3 .owl-carousel { margin: 0px; }

/*.owl-carousel .owl-stage { margin: 0 -10px;}*/

.owl-arrow .owl-nav {    right: 10px;}
.owl-carousel.owl-drag .owl-item { margin: 0 0px}
.bex-view-all-section {}
.sectiont2.sec1  .business-ex-box {
	padding: 0;	margin: 0px;
}
.business-ex-box { 	padding: 0;	margin: 0px;}

/*.sectiont2.sec1 .bex-bussiness-section {    margin: 0 15px;}*/
.title-box { margin-left: 10px;}
.owl-carousel.owl-drag .owl-item { padding:0px 10px; }
.sectiont2.sec4 .bex-features-section-main {padding: 0px!important;}
.setnew { padding: 0 10px; margin: 0 0px 0 0px;}
.sectiont2.topsec .owl-arrow .owl-nav {    right: 0px;}
.btn-outline-bse.mody { padding:10px 25px; border-radius: 0px; border:1px solid;
    font-size: 17px;
 }

.img-box-b img {    padding: 10px;
    background: #fff;
    border: 1px solid #dfdfdf;}
.setv { padding: 0 25px;}



.sectiont2.popul .card-shadow:before { display:none;}
.bse-btn-overlay-w {    bottom: 10px;
    left: 10px;
   width: 94.4%;}

   .bx-insight .card-shadow:before { display:none;}
  .bx-insight .img-box-b img { padding: inherit; border: none;}

  .bx-insight .bex-card-padding-tblr { height: 280px;}

.bxServices  .card-shadow:before { display:none;}
.bxServices  .img-box-b img  {padding: inherit; border: none; 
    padding: 0px; width: 100%;
}

.bxServices .bse-btn-overlay-w { bottom: 0px; left:0; width: 100%;     }


.bxServices .btn-outline-bse {padding:10px 25px; border-radius: 0px; border:1px solid;
    font-size: 17px;}

.bxServices  .img-box-b  { width:362px; }
.bxServices .bex-card-padding-tblr { height: 480px;}
.bxServices .bex-card-info-main { height: 135px; }

.section-b2.prics .title-box h5 { margin-bottom: 30px;}
.setmarg { margin-top: 17px;}
.bex-plans-main {    height: 55px;}
.setct { margin: 0 -15px;}
.btn.btn-b-n:hover { background: transparent; color: transparent;}


#bex-bussiness-carousel .owl-dots { margin-bottom:40px; }
.catleftblk { background: #fff;}

.lheading { font-size:18px; line-height: 20px; font-weight: 600;
 background: #dfdfdf; padding:10px; border-bottom: 1px solid #dfdfdf;  }

.catleft { background: #fff; float: left; width: 100%; min-height: 400px;   box-shadow: 0 0 10px 0 rgba(0,0,0,.15); }


.catleft h2 { font-size: 25px; line-height: 27px; color: #000; }

.head { font-size:16px; line-height: 22px; color: #000; font-weight: 700; 
	margin-bottom: 10px; margin-top: 10px; height:40px;}
.head a { color: #000;}
ul.catlist {   padding:0; text-align: left;   }
ul.catlist li{ display: inline-block; text-align: center;  background: #fff; 
border-radius: 4px; -moz-border-radius: 4px; padding:0; min-height: 430px; width: 280px;  
margin:0 12px 25px; vertical-align: top; text-align: left;      box-shadow: 0 0 10px 0 rgba(0,0,0,.15); position: relative;}
.txtsimple { text-align: left; padding:5px;  color: #616161; background: #f2f2f2;  margin-bottom: 1px; font-size: 12px;  }
.txtsimple span {  float: right; color: #000; font-weight: 700;  }

ul.loclist { padding: 0; margin: 10px 0px 0 10px; text-align: left;}
ul.loclist.settr {    margin-top: 12px;}
ul.loclist li {     display: inline-block;
    margin:0 3px 2px;
    text-align: left; width: 47.8%;

 }
 ul.loclist li a {        
color: #fff;
    padding:2px 0px;     font-size: 13px;}
/*
.imgblk {width: 260px; height: 146px; border:1px solid #dfdfdf; 
	text-align: center; box-sizing: border-box; display: table; } 
	.imgblk .innerimg { display: table-cell; vertical-align: middle; }
.imgblk img  { max-width: 100%; }*/
ul.loclist li a:hover {color: #1B9F84;      }
.maind { text-align: left; margin:10px 0 0 0; padding: 0px; }
.subcatnew { display: inline-block; padding: 7px 10px; background:#e9eff4; font-size: 12px; margin-left: 5px; 
border-radius: 16px; margin:0 0 10px 0;
}
.icontxt { margin-bottom: 10px; }
.icontxt span { margin-right: 15px; font-size: 12px; }
.icontxt span img { position:relative; top:-3px;     left: -5px;} 
.btmfix { position: absolute; bottom: 0px; background: #f7f7f7; padding: 10px; width: 100%;}
div.showlist { text-align: left; padding: 0px; margin: 0px; width: 100%;  }
div.showlist .showlistval{ width: 32%; display: inline-block;  text-align: center;}
div.showlist .showlistval img { width:18px; }
.pad10 { padding: 10px; }
div.showlist .showlistval:first-child { text-align: left;}
div.showlist .showlistval:last-child { text-align: right;}
ul.catlist.mdfy { margin: 0 -15px;}
ul.catlist.mdfy li{ width: 260px;     margin: 0 15px 25px;     min-height: 400px;}
ul.catlist.mdfy li .imgblk { width:240px; }

.plusminus { position: relative;
top: 0px;
width:20px;
height:20px;
display:block;
float: right;
}


.plusminus.add { background: url(../img/toparrow.svg)  no-repeat; top:-3px; }
.plusminus.minus { background: url(../img/downarrow.svg) no-repeat; }
.flefts {	display:inline-block; padding-left:5px;  clear:both; }


.rightdown { position: relative;
top: 0px;
width:20px;
height:20px;
display:block;
float: right;
}


.rightdown.rightval { background: url(../img/arrowright.svg)  no-repeat; top:-3px; }
.rightdown.downval { background: url(../img/arrowrightdown.svg) no-repeat; }




a.morelin { font-size: 12px; color:#006BB9; font-weight: 700; line-height: 15px;}

.accordion_bodymain { padding-left: 15px;}

.subhead { font-size: 18px; line-height: 20px; border-bottom: 1px solid #dfdfdf; 
	padding-bottom:10px; 
 font-weight:700; color: #231F20; margin-top:0px;  background: #f7f7f7;    
  padding: 10px;}

.accordion_head { text-transform: uppercase; font-size: 13px;  line-height: 13px;
 color: #000; font-weight: 700; margin:0px 0 0px 0; cursor: pointer;
}
h4.fcathead { font-size: 12px; line-height: 18px; color: #AAAAAA; font-weight: 700; 
	margin:10px 0 0 0;     text-transform: capitalize; }
.accordion_head a { color: #000; opacity:0.50;     text-transform: uppercase;} 

.accordion_container { border-bottom: 1px solid #dfdfdf; padding-bottom: 10px;

padding:16px 10px;
    width: 100%;
    clear: both;
}

.accordion_container:last-child { border-bottom: none;}
.menu-item-has-children { font-size: 12px; line-height: 18px; color: #000; font-weight: 700; margin:10px 0 0 0; }

.menu-item-has-children a { color: #000; }
.accordion_headmain {font-size: 13px;  line-height: 18px; 
	color: #000;  font-weight: 700;
	cursor: pointer; text-transform:uppercase; padding-left: 15px;   width: 100%;
    clear: both; }
.accordion_headmain a {    color: #262626;       opacity: 1;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;}

.accordion_body { margin-top: 10px; font-size: 12px;  color: #000; opacity: 0.8;}

.accordion_bodymain { margin-top: 0px;}
.accordion_bodymain .accordion_head a { text-transform: capitalize; opacity: 0.8;}

.accordion_bodymain .accordion_head >  input[type="checkbox"]  { position: relative; top: 2px; right: 5px;}

.accordion_headmain >  input[type="checkbox"]  { position: relative; top: 2px; right: 5px;}

ul.sub-menu { padding: 0px; margin:5px 0 10px 7px;}

ul.sub-menu li{ list-style: none; font-size: 12px; line-height: 16px; font-weight: 600;
 padding: 0; margin: 0 0 5px 20px; color:#262626;  text-transform: capitalize;


}
ul.sub-menu li input[type="radio"], ul.sub-menu li input[type="checkbox"]  { position: relative; top: 2px; right: 10px;}
ul.sub-menu li a {color:#000;opacity:0.8;  text-transform: capitalize; }
.more {	overflow: hidden;}
a.showxt { color:#006BB9; font-size: 12px; line-height: 22px; padding-left:7px; }

.filter { display: none;}
.closebtn { display: none;}

.innersearhblk { margin-top: 40px; margin-bottom: 40px; overflow:hidden; }

ul.searchfilter { text-align: left; margin:30px 0 0 0; padding: 0;}
ul.searchfilter li{ width: 32.2%; display: inline-block; margin: 0 15px 0 0; position:relative;  }
ul.searchfilter li:last-child { margin: 0px; }
ul.searchfilter li span { display: block; font-size: 12px; line-height: 18px; color: #101010;
padding-bottom:10px; }
/* #checkboxes{   
    position: absolute;
    z-index: 53;
    background: #fff;
    width: 100%; } */
ul.searchfilter li.disable {}

ul.searchfilter li.disable span {opacity: 0.7;}

.fullblk { width:100%; }
.fblks { float: none; width: 100%; }
.mainleftdiv { border-bottom:1px solid #dfdfdf; padding-bottom:30px;  
   margin-bottom: 10px;    }
.mainleftdiv:last-child { border-bottom:none;}
ul.searchfiltersmall { text-align: left; margin-top: 30px;}
ul.searchfiltersmall li{ width: 49%; display: inline-block; margin: 0 15px 0 0;  }
ul.searchfiltersmall li:last-child { margin: 0px;}
ul.searchfiltersmall li span { display: block; font-size: 12px; line-height: 18px; color: #101010;
padding-bottom:10px; }


.morcity { font-size: 12px; font-weight: 700; padding-left: 10px; padding-top: 5px;}
.morcity a { color: #16A085;}

.leftfrmblk { padding: 0 10px;}
.leftfrmblk .bex-input-range-output .bex-minmax-imput  { margin-right: 10px;}
.leftfrmblk .bex-input-range-output .bex-minmax-imput .form-control{ width:80px; }


.pribtn { background:#fff; border:1px solid #16A085; color: #16A085;
font-size: 12px;    font-size: 12px;
    padding: 7px 15px;
    outline: none;
    font-weight: 700;
 }


.form-control.modysel { border:1px solid #dfdfdf; border-radius: 0;}




ul.listop { text-align:left; margin: 0px; padding: 0px;}
ul.listop li{ padding:10px;  border:1px solid #dfdfdf; width:280px; 
	display: inline-block; height: 540px; vertical-align: top;     background: #fff; position: relative; margin: 0 10px 20px;}
ul.listop.investlist li{ height: 440px; }
ul.listop.otherlist li{ height: 422px; }
ul.listop.startuplist li{ height: 460px; }
ul.listop.buxexlist li{ height: 460px; }
.inblk span a.bookmar { font-size: 22px;}
.inblk span a.bookmar i.fa-bookmark { color: #ccc;}
.inblk span a.bookmar.active i.fa-bookmark, .inblk span a.bookmar:hover i.fa-bookmark { color: #1b9f84;}
.fullb { width: 100%; overflow: hidden; clear: both; padding: 10px 0;}
.fbleft { float: left; width: 64%;}
.fbright { float: right; width: 36%;}
.complogo { float: right; width: 90px; float: right; }
.complogo img { max-width: 100%; }
.compper { float: right; width:85px; height: 85px; border-radius:50%;   }
.compper img { width: 100%; width:85px; height: 85px; border-radius:50%;  }
.cname { display: table; height: 85px; }
.cnameinner { display: table-cell; font-size: 15px;  line-height: 18px; color: #231F20;  font-weight: 600;
 vertical-align: middle;
}
.cnameinner a {  color: #231F20;  }
.contxt { padding:10px 0; font-weight: 400; color: #231F20; opacity: 0.60; font-size: 12px; line-height: 15px;height: 55px; }

.sdd { text-align: left; padding: 10px 0;}
.sddinner { width:18%;  font-weight: 400; display: inline-block; 
	padding-right:0px; color: #666; font-size: 12px; line-height: 15px;  }
.sddinner i {font-size: 19px; color: #666;}
.sddinner a.bookmar {}
.sddinner a.bookmar:hover i,.sddinner .bookmar.active i { color:#1B9F84; 	}
.sddinner.setwva { width: 40%;} 
.sddinner.setwva span { font-size: 12px;}

.sddinner span { padding-left: 5px; }
.summtxt { color: #231F20; font-size: 11px; line-height: 15px; font-weight: 400; height: 60px; }
.summtxt span{ display: block; color: #000; font-weight: 600;   }
.citytxt { color: #231F20; font-size: 11px; line-height: 15px; font-weight: 400; opacity: 0.60;  }

.backv { background: #FBFBFB;  padding: 0px; }

.tagv { text-align: left; padding: 10px 0;}
.tagvinner{ border-radius: 10px; background: #F2F2F2; padding:4px 7px;  font-weight: 400; display: inline-block; padding-right:10px; color: #231F20;  font-size: 12px; line-height: 15px;  }

.inblk { padding:5px 0; clear: both;   color: #231F20; font-size: 12px; line-height: 15px; font-weight: 400; }
.inblk span { text-align: right; color: #231F20; opacity: 1; float: right;font-size: 12px;
font-weight: 600; color: #000;
}

.inbtn { width: 100%; margin-top: 10px;  }
.inbtn a { color:#16A085;  border:2px solid #16A085; font-size: 12px; font-weight: 700; text-transform: uppercase;
	line-height: 15px; text-align: center; display: block; padding:8px 10px; border-radius: 4px;  }
.fullban { width: 260px; }
.fullban img { max-width: 100%; }
.cattxt { text-align: center; font-size: 16px; color: #231F20; line-height: 18px; font-weight: 600; }
.cattxt span{ font-weight: 400; font-size: 12px; line-height: 14px; color: #231F20; opacity:0.60; display: block; 
margin-bottom: 3px; height: 26px; text-align: left;}

.sebe { font-size: 14px;     color: #fff;
    background: rgba(27,159,132,1);
    padding: 5px; }
.sebe span { color: #fff; }
.ribbonblk {
    position: absolute;
    top: 15px;
    /* left: -20px; */
    overflow: visible;
    /* so we can see the pseudo-elements we're going to add to the anchor */
    font-size: 12px;
    /* font-size and line-height must be equal so we can account for the height of the banner */
    line-height: 14px;
}
.ribbonblk:before {
    content: "";
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
    /* border-top: 29px solid rgba(0, 0, 0, .3); */
    /* These 4 border properties create the first part of our drop-shadow */
    /* border-bottom: 29px solid rgba(0, 0, 0, .3); */
    /* border-left: 29px solid rgba(0, 0, 0, .3); */
    /* border-right: 29px solid transparent; */
}
.ribbonblk:after {
    /* This adds the second part of our dropshadow */
    /* content: ""; */
    /* height: 3px; */
    /* background: rgba(0, 0, 0, .3); */
    display: block;
    /* position: absolute; */
    /* bottom: -3px; */
    /* right: 58px; */
    /* left: 3px; */
}
.settmar {margin-top: 10px;}
.ribbonblk .ribbonblkinner {
    display: block;
    padding: 4px 10px 3px 10px;
    position: relative;
    /* allows us to position our pseudo-elements properly */
    background: #F58220;
    overflow: visible;
    height: 22px;
    margin-right: 28px;
    color: #fff;
    text-decoration: none;
}
.ribbonblk .ribbonblkinner:after {
    /* this creates the "folded" part of our ribbon */
    /* content: ""; */
    /* height: 0; */
    /* width: 0; */
    /* display: block; */
    /* position: absolute; */
    /* bottom: -15px; */
    left: 0;
    /* border-top: 15px solid #004a70; */
    /* border-left: 15px solid transparent; */
}
.ribbonblk .ribbonblkinner:before {
    /* this creates the "forked" part of our ribbon */
    content: "";
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    top: 0;
    right: -10px;
    border-top: 11px solid #F58220;
    border-bottom: 11px solid #F58220;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
/*.ribbonblk .ribbonblkinner:hover {
    background: #F58220;
}
.ribbonblk .ribbonblkinner:hover:before {
    
    border-top: 15px solid #F58220;
    border-bottom: 15px solid #F58220;
}

*/

.headblk { font-size: 24px; line-height: 24px; font-weight: 600; color: #231F20; }
.pagination-a .pagination .page-item.disabled .page-link { color: #16A085; font-weight: 600; }
.pagination-a .pagination .page-item:last-child .page-link { color: #16A085; font-weight: 600; }
.priblk #input-select,
.priblk #input-number {
    padding: 7px;
    margin: 15px 5px 5px;
    width: 70px;
}

.priblk .noUi-horizontal {
    height: 5px;
    margin-bottom: 10px;
    margin-top: 30px;
}
.priblk .noUi-horizontal .noUi-handle {
    width: 15px;
    height: 15px;
    left: 20px;
    top: -6px;
    border-radius: 0;
    outline: 0;
    background: #F58220;
    border: none;
}
.priblk .center {
  margin: 0 auto;
  float: none;
  text-align: center;
/*   background: #423e3e; */
  overflow: hidden;
  padding: 10px 10px;
  margin-top: 50px;
}
.priblk input {
  box-shadow: none;
}
 .priblk #input-number {
    padding: 7px;
    margin:0px;
    width: 102px;
    padding: 10px 6px;
    text-align: center;
        float: right;
}

.priblk #input-select {
    padding: 7px;
    margin: 0 20px 0 0;
    width: 120px;
    padding: 10px 6px;
    text-align: center;
}
.priblk .noUi-horizontal .noUi-handle {
  background: #F58220;
      border-radius: 50%;
}
.priblk .noUi-target { background: #B7B7B7; border-radius: 0px; box-shadow: none;     border: none;}
.priblk .noUi-connect {background: #F58220;}
.priblk .noUi-target, .noUi-target * {  }
.priblk .noUi-handle:before, .priblk .noUi-handle:after { display: none; }
.priblk .noUi-horizontal .noUi-handle { box-shadow: none; }
.bex-input-range-output {        padding-top: 15px;}
.fbl { width: 100%; clear: both; padding-bottom: 10px;     overflow: hidden;}
.prlic1 { font-size: 12px; line-height: 13px; color: #262626; float: left; width: 50%; }
.prlic2 { font-size: 12px; line-height: 13px; color: #262626; float: right; width: 50%; text-align: right; }

.googleads {
  padding-top: 30px;
  padding-bottom: 15px;
}

.ads-section-main {
  text-align: center;
  margin: 0 auto;
}

.ads-main {
  margin: 0 auto;
  text-align: center;
}

.ads-main .dfp320 {
  display: none;
  width: 320px;
  margin: 0 auto;
  text-align: center;
}

.ads-main .dfp468 {
  display: none;
  width: 468px;
  margin: 0 auto;
  text-align: center;
}

.ads-main .dfp728 {
  display: none;
  width: 728px;
  margin: 0 auto;
  text-align: center;
}

.ads-main .dfp970 {
  display: Block;
  width: 970px;
  margin: 0 auto;
  text-align: center;
}

#heroinner {
  width: 100%;
  height: 100vh;
  background-color: rgba(4, 12, 21, 0.8);
  overflow: hidden;
  position: relative;
}

#heroinner .carousel, #heroinner .carousel-inner, #heroinner .carousel-item, #heroinner .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#heroinner .carousel-item {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

#heroinner .carousel-item::before {
  content: '';
  background-color: rgba(4, 12, 21, 0.5);
}

#heroinner .carousel-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 82px;
  left: 50px;
  right: 50px;
}

#heroinner h2 {
  color: #F58220;
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  font-family: Bodoni-Bold;
}

#heroinner span {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  color: #fff;
  margin-top: 10px;
}

#heroinner p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  color: #fff;
  margin-top: 10px;
  font-size: 25px;
  font-weight: 300;
}

@media (min-width: 1200px) {
  #heroinner p {
    width: 50%;
  }
}

.fea #heroinner .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#heroinner .carousel-inner .carousel-item, #heroinner .carousel-inner .active.carousel-item-left, #heroinner .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

.item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 66%) 100%);
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 66%) 100%);
  background-image: -o-linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 66%) 100%);
  background-image: -ms-linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 66%) 100%);
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 66%) 100%);
}

#heroinner .carousel-inner .active, #heroinner .carousel-inner .carousel-item-next.carousel-item-left, #heroinner .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
  transition: 0.5s;
}

#heroinner .carousel-inner .carousel-item-next, #heroinner .carousel-inner .carousel-item-prev, #heroinner .carousel-inner .active.carousel-item-left, #heroinner .carousel-inner .active.carousel-item-right {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#heroinner .carousel-control-prev, #heroinner .carousel-control-next {
  width: 10%;
  top: 112px;
}

@media (max-width: 992px) {
  #heroinner .carousel-control-prev, #heroinner .carousel-control-next {
    top: 66px;
  }
}

#heroinner .carousel-control-next-icon, #heroinner .carousel-control-prev-icon {
  background: none;
  font-size: 36px;
  line-height: 1;
  width: auto;
  height: auto;
  background: rgb(255 255 255 / 75%);
  border-radius: 50px;
  padding: 10px;
  transition: 0.3s;
  color: rgb(0 0 0 / 52%);
}

#heroinner .carousel-control-next-icon:hover, #heroinner .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

#heroinner .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: .6;
  transition: 0.3s;
}

#heroinner .carousel-indicators li.active {
  opacity: 1;
  background: #fe7806;
}

#heroinner .btn-get-started {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  color: #fe7806;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  /* background: #fe7806; */
  margin-top: 15px;
  border: 2px solid #fe7806;
}

#heroinner .btn-get-started:hover {
  background: #ef6445;
  color: #fff;
}

@media (max-width: 992px) {
  #heroinner {
    height: 85vh;
  }
  #heroinner .carousel-container {
    text-align: center;
    top: 74px;
  }
}

@media (max-width: 768px) {
  #heroinner h2 {
    font-size: 32px;
  }
}

@media (min-width: 1024px) {
  #heroinner .carousel-control-prev, #heroinner .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #heroinner {
    height: 120vh;
  }
}



ul.frmlist { padding: 0px; margin: 0px; text-align: left; }
ul.frmlist li { width: 46%; list-style: none;  display: inline-block; margin: 0 10px 25px; }



ul.frmlist li .form-control  {border:1px solid #fff; background: transparent; height: 40px; font-size: 15px; color: #fff; }

.btn-sub { margin-left: 10px; border:none; border-radius: 25px; background: #fff; font-size: 15px; 
  color: #F58220; padding: 15px 25px; }



ul.frmlist li input[type="email"]::placeholder {  
                  
          
                color: #fff;
            } 
ul.frmlist li input[type="text"]::placeholder {  
                  
                color: #fff;
            } 
       
   ul.frmlist li input[type="email"]:-ms-input-placeholder { 
                  
              color: #fff;
            } 
ul.frmlist li input[type="email"]::-ms-input-placeholder {  
                  
                color: #fff;
            }

ul.bxartlist {
    text-align: left;
    margin: 0 -15px;
    padding: 0px;
}
ul.bxartlist li {
    display: inline-block;
    list-style: none;
    width: 284px;
    margin: 0  10px 10px;
}
.inimg {
    width: 280px;
    height: 190px;
    border: 1px solid #dfdfdf;
    text-align: center;
    padding: 10px;
}
.inimg img { width: 100%; }

ul.bxartlist li .post-card {
  padding: 10px;
}

ul.bxartlist li .summry-section p {
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #303030;
  text-align: left;
}

ul.bxartlist li .summry-section p a {
  color: #303030;
}

ul.bxartlist li .summry-section h2 {
  font-family: Bodoni-Bold;
  font-size: 20px;
  display: -webkit-box;
  height: 55px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0;
}

ul.bxartlist li .summry-section h2 a {
  color: #303030;
}

ul.bxartlist li  .duration-section {
  width: 50%;
}

ul.bxartlist li  .duration-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

ul.bxartlist li  .share-section {
  width: 50%;
}

ul.bxartlist li  .share-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

ul.bxartlist li .post-inner-section .time {
  color: #303030;
  font-size: 13px;
}


span.time { text-align: left; display:block;     font-size: 12px;}

.rightsec {}

.bex-form-section .form-control { border-left: 1px solid #dfdfdf;}

ul.rlist { width: 100%; padding: 0; margin: 0px;}
ul.rlist li { padding: 0; list-style: none; margin-bottom: 10px; 
border-bottom: 1px solid #dfdfdf; padding-bottom: 10px;  overflow: hidden;
}
div.imgleft { width: 115px; float: left;}
div.imgleft img { padding: 5px; border: 1px solid #dfdfdf; border-radius: 5px; width: 100%;}
div.imgtxt { width: 58%;float: right; padding-left: 10px;}

.datshow { font-size: 12px; color: #303030; opacity: 0.50; display: block;
margin-top:5px;  }
.rhead { font-size: 15px; line-height: 16px;    
/* white-space: nowrap;     text-overflow: ellipsis; */
  font-family: 'Roboto', sans-serif; height: 40px;
  font-weight: 600;
   }
ul.catlistleft { clear: both; padding: 0; margin: 0px;}
ul.catlistleft li  { font-size: 15px;  font-weight: 600;  font-family: 'Roboto', sans-serif; line-height: 25px; list-style: none; border-bottom: 1px solid #dfdfdf;
padding-bottom: 15px; margin-bottom: 15px;
}
ul.catlistleft li a { color: #303030;}
ul.catlistleft li  i { float: right;}
.riads { display: block; text-align:center;  width:300px;}
.riads img {width: 100%;}

.topimg { margin: 0 auto 30px;}
.topimg img{ width: 100%; display: block; margin: 0 auto;}
.catlink { font-size:16px; font-weight: 20px; margin:80px 0 10px;  text-align: center;  }
.catlink  a { color: #F58220;}
h1.heading { font-size: 36px;  font-weight: 600;  font-family: 'Roboto', sans-serif; line-height: 36px;
 color: #303030;  text-align: center; margin-bottom: 30px;}

.contentblk {}
.contentblk p{ font-size:16px; line-height: 22px; color: #303030; opacity: 0.75;}
.contentblk h2 { margin-top: 30px; font-size: 18px; font-weight: 600; color: #231F20;}

.toplbk { border-top:1px solid #dfdfdf; border-bottom:1px solid #dfdfdf;     
    padding: 10px 0 0; margin-top:0px;   margin-bottom: 50px; overflow: hidden; color: #303030; opacity: 0.75;
  }

.toplbk.newsb { padding: 10px 0 10px;}


.lbk { border-top:1px solid #dfdfdf; border-bottom:1px solid #dfdfdf;     
    padding: 10px 0; margin-top:30px;   margin-bottom: 50px; overflow: hidden; color: #303030; opacity: 0.75;
  }
.fleft { float: left; width: 50%;}
.fright { float: right; width: 50%;     text-align: right;}
.shead  { display: block; float: left; padding-top: 10px;
    padding-left: 10px;}

    .sheadhome { font-size: 14px;  }

    .bex-features-section-main img {    height: auto;}
    .bex-features-section-main img.rounded-circle {    border-radius: 20%!important;}

ul.fle { padding: 0; float: left; width: 70%; text-align: left; margin: 0;}
ul.fle li { display: inline-block;     vertical-align: top;     margin-right: 20px;}
ul.fle li .primg  { width: 44px; float: left; display: none;}
ul.fle li .primg img { width: 100%; border-radius:50%; }
ul.cmtright {padding: 0; float: right; width: 30%; text-align: right; margin: 0;     padding-top: 10px;}
ul.cmtright li { display: inline-block;     padding-right: 10px;}
.stxt { padding-top: 10px;}

 .tag-block {
    width: auto;
    margin: 20px 0 0;
}
.post-comment {background: #fff;
    padding: 10px;
    border-radius: 10px;}
.tag-block ul.tag-list {
    color: #666;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 0 -4px;
    padding: 0;
    margin-bottom: 20px;
}

.tag-block ul.tag-list li {
    background: #fff;
    margin: 4px;
    padding: 6px 11px;
    display: inline-block;
    border-radius: 4px;
    -moz-border-radius: 4px;
}


.tag-block ul.tag-list li a {
    color: #666;
    font-size: 14px;
}

.contentblk img{ max-width:100%; text-align:center;display:block;margin:0 auto}
.contentblk  p > img{max-width:100%}


.contentblk ul li { font-size: 18px; line-height: 20px;}
.contentblk ol li { font-size: 18px; line-height: 20px;}
.contentblk p a{color:#069;}
  .contentblk iframe { width:100%!important; }
  .section-bg {
  background-color: #f6f9fd;
}

.section-title {

  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;
  margin-bottom: 20px;
}

.section-title h2 {
  font-weight: 500;
  padding: 0;

  margin: 0 0 0px 0;
  /* letter-spacing: 2px; */
  /* color: #aaaaaa; */
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  width: 80%;
  float: left;

}
.section-title h2 span { margin-top: 15px; display: block; height: 1px; background: #fe7806;; width: 100px;  }

.section-title p {
  position: absolute;
  top: 0px;
  right: 20px;
  margin-top: -11px;
  margin-bottom: 0rem;
  font-weight: 400;
  font-size: 14px;
}

div.viewall { float: right; }
/*--------------------------------------------------------------
# Trending Articles
--------------------------------------------------------------*/

.trending-articles {
  padding-top: 30px;
  padding-bottom: 15px;
}

.trending-articles .post-inner-section {
  margin: 10px 0px 10px 0px;
  height: 392px;
}

.trending-articles .post-inner-section .post-card .post-visual {
  border: 1px solid #e5e5e5;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  height: 135px;
}

.trending-articles .post-inner-section .post-card .post-visual .post-inner-visual {
  display: table-cell;
  vertical-align: middle;
}
.trending-articles .post-inner-section .post-card .post-visual .post-inner-visual img { max-width: 100%; }



.ttl { font-size: 30px; line-height: 30px;  margin-bottom: 20px;}


.trending-articles .post-card {
  padding: 10px;
}

.trending-articles .summry-section p {
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #303030;
}

.trending-articles .summry-section p a {
  color: #303030;
}

.trending-articles .summry-section h2 {
  font-family: Bodoni-Bold;
  font-size: 20px;
  display: -webkit-box;
  height: 55px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.trending-articles .summry-section h2 a {
  color: #303030;
}

.trending-articles .post-inner-section .duration-section {
  width: 50%;
}

.trending-articles .post-inner-section .duration-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.trending-articles .post-inner-section .share-section {
  width: 50%;
}

.trending-articles .post-inner-section .share-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}
.inneblk { margin:100px 0; }
.trending-articles .post-inner-section .time {
  color: #303030;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Buying a Business Articles
--------------------------------------------------------------*/

.buyingabusiness-articles {
  padding-top: 30px;
  padding-bottom: 15px;
}

.buyingabusiness-articles .post-inner-section {
  margin: 10px 0px 10px 0px;
  height: 365px;
}

.buyingabusiness-articles .post-inner-section .post-card .post-visual {
  border: 1px solid #e5e5e5;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  height: 135px;
}

.buyingabusiness-articles .post-inner-section .post-card .post-visual .post-inner-visual {
  display: table-cell;
  vertical-align: middle;
}
.buyingabusiness-articles .post-inner-section .post-card .post-visual .post-inner-visual img { max-width: 100%; }
.buyingabusiness-articles .post-card {
  padding: 10px;
}

.buyingabusiness-articles .summry-section p {
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #303030;
}

.buyingabusiness-articles .summry-section p a {
  color: #303030;
}

.buyingabusiness-articles .summry-section h2 {
  font-family: Bodoni-Bold;
  font-size: 20px;
  display: -webkit-box;
  height: 55px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .5rem;
  margin-top: .5rem;
  font-weight: 600;
}

.buyingabusiness-articles .summry-section h2 a {
  color: #303030;
}

.buyingabusiness-articles .post-inner-section .duration-section {
  width: 50%;
}

.buyingabusiness-articles .post-inner-section .duration-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.buyingabusiness-articles .post-inner-section .share-section {
  width: 50%;
}

.buyingabusiness-articles .post-inner-section .share-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.buyingabusiness-articles .post-inner-section .time {
  color: #303030;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Business Ideas Articles
--------------------------------------------------------------*/

.businessideas-articles {
  padding-top: 30px;
  padding-bottom: 15px;
}

.businessideas-articles .post-inner-section {
  margin: 10px 0px 10px 0px;
  height: 365px;
}

.businessideas-articles .post-inner-section .post-card .post-visual {
  border: 1px solid #e5e5e5;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  height: 135px;
}

.businessideas-articles .post-inner-section .post-card .post-visual .post-inner-visual {
  display: table-cell;
  vertical-align: middle;
}
.businessideas-articles .post-inner-section .post-card .post-visual .post-inner-visual img { max-width: 100%; }
.businessideas-articles .post-card {
  padding: 10px;
}

.businessideas-articles .summry-section p {
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #303030;
}

.businessideas-articles .summry-section p a {
  color: #303030;
}

.businessideas-articles .summry-section h2 {
  font-family: Bodoni-Bold;
  font-size: 20px;
  display: -webkit-box;
  height: 55px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .5rem;
  margin-top: .5rem;
  font-weight: 600;
}

.businessideas-articles .summry-section h2 a {
  color: #303030;
}

.businessideas-articles .post-inner-section .duration-section {
  width: 50%;
}

.businessideas-articles .post-inner-section .duration-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.businessideas-articles .post-inner-section .share-section {
  width: 50%;
}

.businessideas-articles .post-inner-section .share-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.businessideas-articles .post-inner-section .time {
  color: #303030;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Selling A Business Articles
--------------------------------------------------------------*/

.sellingabusiness-articles {
  padding-top: 30px;
  padding-bottom: 15px;
}

.sellingabusiness-articles .post-inner-section {
  margin: 10px 0px 10px 0px;
  height: 365px;
}

.sellingabusiness-articles .post-inner-section .post-card .post-visual {
  border: 1px solid #e5e5e5;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  height: 135px;
}

.sellingabusiness-articles .post-inner-section .post-card .post-visual .post-inner-visual {
  display: table-cell;
  vertical-align: middle;
}
.sellingabusiness-articles .post-inner-section .post-card .post-visual .post-inner-visual img { max-width: 100%; }
.sellingabusiness-articles .post-card {
  padding: 10px;
}

.sellingabusiness-articles .summry-section p {
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #303030;
}

.sellingabusiness-articles .summry-section p a {
  color: #303030;
}

.sellingabusiness-articles .summry-section h2 {
  font-family: Bodoni-Bold;
  font-size: 20px;
  display: -webkit-box;
  height: 55px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .5rem;
  margin-top: .5rem;
  font-weight: 600;
}

.sellingabusiness-articles .summry-section h2 a {
  color: #303030;
}

.sellingabusiness-articles .post-inner-section .duration-section {
  width: 50%;
}

.sellingabusiness-articles .post-inner-section .duration-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.sellingabusiness-articles .post-inner-section .share-section {
  width: 50%;
}

.sellingabusiness-articles .post-inner-section .share-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.sellingabusiness-articles .post-inner-section .time {
  color: #303030;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Business Financing Articles
--------------------------------------------------------------*/

.businessfinancing-articles {
  padding-top: 30px;
  padding-bottom: 15px;
}

.businessfinancing-articles .post-inner-section {
  margin: 10px 0px 10px 0px;
  height: 365px;
}

.businessfinancing-articles .post-inner-section .post-card .post-visual {
  border: 1px solid #e5e5e5;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  height: 135px;
}

.businessfinancing-articles .post-inner-section .post-card .post-visual .post-inner-visual {
  display: table-cell;
  vertical-align: middle;
}
.businessfinancing-articles .post-inner-section .post-card .post-visual .post-inner-visual img { max-width: 100%; }
.businessfinancing-articles .post-card {
  padding: 10px;
}

.businessfinancing-articles .summry-section p {
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #303030;
}

.businessfinancing-articles .summry-section p a {
  color: #303030;
}

.businessfinancing-articles .summry-section h2 {
  font-family: Bodoni-Bold;
  font-size: 20px;
  display: -webkit-box;
  height: 55px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .5rem;
  margin-top: .5rem;
  font-weight: 600;
}

.businessfinancing-articles .summry-section h2 a {
  color: #303030;
}

.businessfinancing-articles .post-inner-section .duration-section {
  width: 50%;
}

.businessfinancing-articles .post-inner-section .duration-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.businessfinancing-articles .post-inner-section .share-section {
  width: 50%;
}

.businessfinancing-articles .post-inner-section .share-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.businessfinancing-articles .post-inner-section .time {
  color: #303030;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Business Brokerage Articles
--------------------------------------------------------------*/

.businessbrokerage-articles {
  padding-top: 30px;
  padding-bottom: 15px;
}

.businessbrokerage-articles .post-inner-section {
  margin: 10px 0px 10px 0px;
  height: 365px;
}

.businessbrokerage-articles .post-inner-section .post-card .post-visual {
  border: 1px solid #e5e5e5;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  height: 135px;
}

.businessbrokerage-articles .post-inner-section .post-card .post-visual .post-inner-visual {
  display: table-cell;
  vertical-align: middle;
}
.businessbrokerage-articles .post-inner-section .post-card .post-visual .post-inner-visual img { max-width: 100%; }
.businessbrokerage-articles .post-card {
  padding: 10px;
}

.businessbrokerage-articles .summry-section p {
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #303030;
}

.businessbrokerage-articles .summry-section p a {
  color: #303030;
}

.businessbrokerage-articles .summry-section h2 {
  font-family: Bodoni-Bold;
  font-size: 20px;
  display: -webkit-box;
  height: 55px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .5rem;
  margin-top: .5rem;
  font-weight: 600;
}

.businessbrokerage-articles .summry-section h2 a {
  color: #303030;
}

.businessbrokerage-articles .post-inner-section .duration-section {
  width: 50%;
}

.businessbrokerage-articles .post-inner-section .duration-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.businessbrokerage-articles .post-inner-section .share-section {
  width: 50%;
}

.businessbrokerage-articles .post-inner-section .share-section span {
  margin-left: 7px;
  font-size: 13px;
  /* width: 50%; */
}

.businessbrokerage-articles .post-inner-section .time {
  color: #303030;
  font-size: 13px;
}






/* media code start here */
@media only screen and (max-width: 991px) and (min-width: 300px)  {
	.social-links-share div span {
		padding: 1px !important;
	}
	.bex-view-all-section {
		position: absolute;
		margin-top: 0px;
		text-align: left;
		padding-right: 0px !important;
		width: 50%;
	}
	.bex-view-all-section {
		position: inherit !IMPORTANT;
		/* float: right; */
		/* width: 98%; */
		margin-top: -64px;
		text-align: right;
		padding-right: 22px !important;
		/* padding: 20px; */
	}
	.bex-view-all-section2 {
		position: absolute;
		/* float: right; */
		width: 91%  !important;
		margin-top: -39px !important;
		text-align: right;
		padding-right: 0px !important;
		padding: 4px;
	}
	.nav-arrow-a .owl-arrow .owl-nav {
		font-size: 1.8rem;
		margin-top: 0px !important;
		position: unset;
	}
	.navbar-brand {
		display: inline-block;
		padding-top: .3125rem;
		padding-bottom: .3125rem;
		margin-right: 0.2rem;
		font-size: 1.25rem;
		line-height: inherit;
		white-space: nowrap;
	}
	  
	h2 {
		font-size: 25px;
	  }
	  h5 {
		font-size: 15px;
	  }
.emailbg-inner { width: 100%;}
}


@media only screen and (min-width: 991px) {
	a.bex-view-all-section {
		position: absolute;
		margin-top: -62px;
		text-align: left;
		padding-right: 0px;
		width: 98% !important;
		float: right;
		text-align: right!important;
		font-size: 14px;  
		color: #16A085;
		    right: 9px;
		    opacity: 0.7;

	}

	a.bex-view-all-section:hover { opacity: 1;color: #16A085;}

}

.getindustry-inner h2 { color: #fff;}
.minheigh { min-height: 600px; margin-top: 0px; }
.filter { display: none;}
.closebtn { display: none;}

ul.brunnar { text-align: left; padding: 0; margin: 0 0 20px 0;}
ul.brunnar li{ display: inline-block; margin-right:15px; color: #000; font-size: 14px;
font-weight: 400;  }
ul.brunnar li a { color: #7B7979; font-weight: 700; }
.statictxt { font-size: 15px; line-height: 18px; font-weight: 400; color:#333333;}
.pestion {border-bottom: 1px solid #dfdfdf; overflow: hidden; margin: 20px 0;  }
.prtxt { width:10%;  font-size: 16px; line-height: 18px; font-weight: 700; 
 color: #333333; float: left;}
.lineval { float: left; width:90%; }
.marsettop { margin-bottom: 20px;}
label.frmtxt { font-size: 14px;}
.frmtxt { font-size: 14px;}
.frmall label.mandatory:after,.frmall .form-group label.mandatory:after{content:"\f005";top:-5px;margin-right:2px;padding-left:5px;position:relative}
.frmall label.mandatory:after {
    color: #f22406;
    font: normal normal normal 6px/1 FontAwesome;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 0px 0 0;
  margin-left: 20px;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #666;
  font-weight: normal;
  border-bottom:1px solid transparent;
        padding-bottom:10px;
      margin-bottom: 0px;

}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid #ccc;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
    border: 1px solid #F58220;
  background: #F58220;
}


.radio-item input[type=radio]:checked + label:before {

    border: 1px solid #F58220;

}

.radio-item input[type=radio]:checked + label {border-bottom: 1px solid #F58220; }

.priclist { padding: 0; margin:40px -9px 0; position: relative; 
 text-align: center;
display: inline-block;
}
.priclist .priclistinner{ display: inline-block; border-radius: 4px; 
	border:1px solid #dfdfdf;
background:#f7f7f7; width: 268px; height: 770px; position: relative;
padding:30px 0 0 0; 
margin: 0 15px 15px;
vertical-align: top;
}
.homeshead { font-size: 14px; text-align: left; height: 30px; }
.homestxt { height: 30px;     margin-bottom: 5px;    line-height: 15px;}
.mhomeshead { font-size: 14px; text-align: left; height: 20px; }
.mhomestxt { height: 25px;     margin-bottom: 5px;    line-height: 15px;}
.priclist .priclistinner.active { background: #fff; box-shadow: 0 0 10px 0 rgba(0,0,0,.15);}

.inhieght { height: 200px;}
.mtxt { text-align: center;  color: #333333; font-size: 18px;  line-height: 18px;}
.shead { margin:10px 0;  font-weight: 600; font-size: 24px; text-align: center; color: #616161; line-height: 24px;}
.dll{color: #616161; opacity: 0.50;  font-size: 18px; font-weight: 400;   margin:10px 0;  display: block; text-align: center;}

.priclist .priclistinner .shead { text-align: center;     float: none;}

.bntls { position: absolute; bottom:  40px; text-align: center; width: 100%;}

.bex-main-info-card-timing-img i { position: relative; top: 2px; left: -2px;}

.btnpric { font-size:14px; color: #333; border:1px solid #dfdfdf; 
width: 130px; text-align: center; padding:5px 10px; display: inline-block; }

ul.pritxt { padding:20px 10px 10px 0px; margin:20px 0 0 0; border-top: 1px solid #dfdfdf;}
ul.pritxt li{  
font-size: 13px; font-weight: 400;  line-height: 18px; color: #43425D; opacity: 0.75;
 background: url(../img/righta.svg) no-repeat 0 5px; padding:0 0 0 20px; 
 margin:0 0 10px 10px;
 text-align: left;  
}

.priclist .priclistinner.active .btnpric  { background: #16A085; color: #fff; border:1px solid #16A085; }
.topset { position: absolute; top:-20px; text-align: center; width: 100%;}

.shbbtxt { font-size:14px; color: #fff; border:1px solid #dfdfdf; background: #F58220;
 text-align: center; border-radius:25px;  padding:7px 15px; display: inline-block; text-transform: uppercase; }

.landpage .carousel-indicators .active { background-color:#F58525; }

.mainpri { margin:10px 0; text-align:center; color:#F58220; font-size: 30px; line-height: 30px; font-weight: 600; }
.mainpri  span  { font-size: 14px; color: #000; opacity: 0.75; line-height: 18px;}
.savcol { text-align:center;color: #16A085; font-weight: 600; margin:10px 0;}


ul.priceplan { border:0px solid #dfdfdf; margin:15px 0 0; padding:0px; text-align: left;  }

ul.priceplan li{ float: left; width: 17%; vertical-align: top;    margin-left: -1px;
  }
ul.priceplan li:last-child {   }
ul.priceplan li:first-child { width: 32%;   }

.reghead {  padding:10px 7px 10px; font-size: 18px; line-height: 22px; 
text-align: center; color: #000;  font-weight: 600; height: 36px;
 border-top:1px solid #dfdfdf; border-bottom:1px solid #dfdfdf;}

.iconblk { text-align: center;   }
.iconblk i { color: #F58220;    vertical-align: middle;
    display: table-cell; }

.pricingtxt { text-align: center;  border-top:1px solid #dfdfdf; border-bottom:1px solid #dfdfdf; }

a.bookbtn { background: #000; color: #fff; border-radius: 4px; font-family:'HelveticaNeueLTStd-Ex'; font-size: 15px; 
padding:10px 15px;   }

.htset {  height: 36px; display: table; width: 100%; font-size: 14px; 
	line-height: 16px;
color: #333333; font-weight: 400; text-align: center; }

ul.priceplan li .htset:nth-child(2n) { background: #fff;}

.reghead.tleft { text-align: left;     padding-top: 8px;
    padding-left: 8px;}
.htset.tleft { text-align: left; padding-top: 8px;
    padding-left: 8px;}

.pvalue {vertical-align: middle;
    display: table-cell; }

.subheading { font-size: 28px; line-height: 32px; font-family:'HelveticaNeueLTStd-Ex'; margin-bottom: 40px; }
.padset {}



.blkbt { text-align:left;  border-top:1px solid #dfdfdf; padding:10px; clear: both; text-align: center;   }

.btnknew {  text-align:left;  border:1px solid #ef7f1a;  display: inline-block; font-size: 17px; line-height: 20px; text-transform: uppercase; background: #ef7f1a; color: #fff; padding: 10px 25px; border-radius: 10px;  } 
.btnknew:hover, .btnknew:focus {background: #fff; color: #ef7f1a; border:1px solid #ef7f1a;}
.linebd { width:180px; margin:0 auto 40px;  }
.linefi { width: 140px;   height: 1px; background:#ef7f1a; margin:0 auto 1px;}
.linese { width: 140px;  height: 1px; background:#333; margin:0 auto 0 40px;  }


ul.priceplan li .fa-check {  color: #16A085;}



.feblk { }

  .cirplusminus { position: relative;
top: 0px;
width:23px;
height:23px;
display:block;
float: right;
}


.cirplusminus.add { background: url(../img/cirplus.svg)  no-repeat; top:-3px; }
.cirplusminus.minus { background: url(../img/cirminus.svg) no-repeat; }
.feblkcontainer { margin-top: 50px;} 

.feblk_head { text-transform: uppercase; font-size: 22px;  line-height: 22px;
 color: #000; font-weight: 700; margin:0px 0 0px 0; cursor: pointer;
}
.feblk_body {}

ul.bexdlist { padding: 0; margin: 0 0 20px 0; text-align: left;}
ul.bexdlist li { margin-right: 20px; 
	display: inline-block; color:#707070; font-size: 16px; font-weight: 400;
}


ul.mainsecdet { background: #fff; padding: 25px; margin:15px 0;     box-shadow: 0 0 10px 0 rgba(0,0,0,.15); }
ul.mainsecdet li { display: inline-block; color: #303030; font-weight: 600; font-size:18px;
line-height: 20px; width: 19%;     vertical-align: top; }
ul.mainsecdet li span { display: block; opacity: 0.75; font-weight: 400; font-size: 14px; line-height: 16px;
 }
ul.mainsecdet li.linesh { padding: 5px; color: #dfdfdf; font-weight: 400; text-align: center;  }

.frmsubhead {    margin-top: 15px; font-size: 18px; font-weight: 600; line-height: 21px; color: #303030;}

ul.catfrmlist { margin: 0; padding: 0; }
ul.catfrmlist li{ display: block; margin-bottom: 20px;}
ul.catfrmlist li label.frmtxt {  color: #231F20; font-size: 12px; line-height: 15px; font-weight: 400;}

.btnland { font-size:14px; color: #fff; border:1px solid #16A085; background: #16A085;
width: 130px; text-align: center; padding:5px 10px; display: inline-block; }

.frmblkland { background: #fff;     box-shadow: 0 0 10px 0 rgba(0,0,0,.15); padding: 10px;}

.fulldetailpage { margin-top: 50px;}
.fulldetailpage .tab-content { background: #fff; padding: 15px; font-size: 14px;
line-height: 20px; color: #303030; font-weight: 400;}
.bexdetailsh{  font-size: 16px; font-weight: 600; line-height: 18px; color: #303030; margin-bottom: 30px;}
.bexdetailsh span { display: block; font-size: 14px; font-weight: 400;  }
ul.bexshowdt { padding: 0; margin: 0;}
ul.bexshowdt li { margin-bottom: 10px; display: block; text-align: left; font-size: 14px;
line-height: 18px; font-weight: 400; color: #303030;}
.b1 { width: 35%; display: inline-block;}
.b2 { width: 5%; display: inline-block;}
.b3 { width: 55%; display: inline-block;}
.back { background: #f7f7f7; padding: 15px; margin-bottom: 40px;}
.borbtm { border-bottom: 1px solid #666; padding-bottom: 10px; margin-bottom: 20px;}
.borbtm:last-child { border-bottom: none;}

.row.backlist {    background: #f7f7f7;
    padding: 15px 15px 15px 15px;
    margin-bottom: 40px;}
.tab-content.iber {}
.fulldetailpage.iber { margin-top: 10px;}
.frmback { background:#fff; border-radius: 4px; padding:20px;}
.ivright { float: right;}
.comppro { width: 150px; float: right;}
.comppro img{ max-width: 100%; padding: 0px;}
.complog { width: 190px; float: right;}
.complog  img{ max-width: 100%; padding: 0px;}
.frmback label.frmtxt { text-align: right;}
.frmcheading { border-bottom: 1px solid #dfdfdf; font-size: 18px; line-height: 20px; 
	font-weight: 500;
color: #231F20; padding-bottom: 10px; margin-bottom: 20px; }
.marftop { margin-top: 80px;}
.showme { font-size: 14px; color: #16A085; line-height: 18px; font-weight: 600;}
.showme:hover { color: #16A085; }

.showmedocuments { font-size: 14px; color: #16A085; line-height: 18px; font-weight: 600;}
.showmedocuments:hover { color: #16A085; }

.showmephoto { font-size: 14px; color: #16A085; line-height: 18px; font-weight: 600;}
.showmephoto:hover { color: #16A085; }


.tooltipfrm {
  position: relative;
  display: inline-block;
  float: left;
    margin-top: 10px;
        margin-left: 10px;
}

.tooltipfrm .tooltiptextfrm {
  visibility: hidden;
  min-width: 200px;
  background-color: black;
  color: #333;
  background-color: #dfdfdf;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 53px;
  left: 50%;
  margin-left: -100px;
}

.tooltipfrm .tooltiptextfrm::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #dfdfdf transparent transparent transparent;
}

.tooltipfrm:hover .tooltiptextfrm {
  visibility: visible;
}


.pricetooltipfrm {
  position: relative;
    line-height: 16px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;
    margin-bottom: 10px;
       
}
.pricetooltipfrm strong {color: #1B9F84;
    font-weight: 600;     font-size: 15px;}
.pricetooltipfrm .tooltiptextfrm {
  visibility: hidden;
 width: 225px;

  color: #333;
  background-color: #fff;
  font-size: 13px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 35px;
  left: 20px;
  
  box-shadow: 1px 2px 15px rgb(100 100 100 / 30%);
}

.pricetooltipfrm .tooltiptextfrm::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  
}

.pricetooltipfrm:hover .tooltiptextfrm {
  visibility: visible;
}














.photobox { margin-bottom: 15px;}
.stey{ margin-top:10px;}
.frmnote { font-size: 12px; line-height: 16px; color: #333; display: block;}
.setborder { border-top: 1px solid #dfdfdf; text-align: center; padding:15px 0 0 0; margin:15px 0 0 0;  }
.frmbtn {font-size:14px; color: #fff; border:1px solid #16A085; background: #16A085;
width: 130px; text-align: center; padding:5px 10px; display: block; margin: 0 auto;}

.termstxt { font-size: 12px; text-align: center; padding:10px 0 0 0; }
.termstxt a { color: #16A085; font-weight: 600; }

.fgts { font-size: 18px; color: #303030; font-weight: 600; }
.fabqback {background: #fff; padding: 15px; border:1px solid #dfdfdf;}



  .faqcirplusminus { position: absolute;
top: 0px;
right: 0;
width:23px;
height:23px;
display:block;
float: right;
}


.faqcirplusminus.add { background: url(../img/faqscirplus.svg)  no-repeat; top:-3px; }
.faqs_head .faqcirplusminus.minus {background: url(../img/faqscirplus.svg) no-repeat;  }
.faqs_head.active .faqcirplusminus.minus {background: url(../img/faqscirminus.svg) no-repeat; }

.faqs_head { font-size: 14px;  line-height: 16px;
 color: #000; font-weight: 400; margin:0px 0 0px 0; cursor: pointer;
 border-bottom: 1px solid #dfdfdf; padding-bottom: 20px; margin-bottom: 10px;
 position:relative ;
 padding-right:25px; 
}
.faqs_head  a { color: #000;}
.faqs_body { font-size: 13px; margin-bottom: 20px;}

.pos { position: relative; }
.pos i { padding: 10px; position:absolute; left: 0px; top:0px; background: #3b5998; color: #fff; }

.pos .form-control { padding-left: 30px; }

.pos i.fa-twitter {  background: #3cf; }
.pos i.fa-linkedin-in {  background: #007bb6; }


.checkbox { font-size: 13px; margin: 0 -10px; }
.checkbox input[type=checkbox], .checkbox input[type=radio] {    position: relative;
    left: -5px; top:1px;}
.backbg { background: #fff; padding: 30px; margin:0px; }

.poss { position: relative;}
.alybtn { position: absolute; right: 17px; top:0px; background:#F58220;
 padding:11px 15px; color: #fff;
font-size: 15px; font-weight: 400; outline: none; border:none;   }
.page-ttl { }
.page-ttl h1 {margin-bottom: 30px; font-size: 24px; color:#231F20;  line-height: 24px; font-weight: 400;}
.page-ttl.sub{ font-size: 16px;  color:#5A5758;  
	line-height: 16px; font-weight: 600; margin-bottom: 10px;	}
.shrt-desc.privacy {font-size: 15px;  color:#5A5758;  }
.shrt-desc.privacy .bld { font-size: 14px; font-weight: 600;}
.shrt-desc ul {
    margin-left: 0px;

}

.shrt-desc ul li{
    margin-left: 15px;
    list-style:decimal;
}
.shrt-desc ul li > div { margin-top: 20px; font-weight: 600;}
ul.sub-list { margin-top: 20px;}
ul.sub-list li { list-style: circle;}

ul.planlist { padding: 0; margin: 0; background: #fff; padding:40px; }
ul.planlist li { vertical-align: top; text-align: left;
width: 100%; padding:20px 10px; border-bottom: 1px solid #dfdfdf;
  }
ul.planlist li:nth-child(2n) { background:#FAFAFA; }
.df { padding-top:10px;  vertical-align: top; display: inline-block; width:3%; }
.protxt {vertical-align: top; padding-right:50px;  width:30%;  display: inline-block; font-size: 16px; line-height: 18px; color: #333333; }
.protxt span {vertical-align: top; display: block; font-size: 12px; line-height: 14px;}
.mbox { vertical-align: top; display: inline-block; width:25%;}
.pribox { vertical-align: top; display: inline-block; width:30%; text-align: right;}
ul.planlist li:last-child { border-bottom: none;}
.landpage { margin-top: 40px;}










  .fullshblk { width: 100%; }
  .halkblk { width: 49.5%; float: left; border-radius: 4px; border:1px solid #dfdfdf; }
  .halkblk:nth-child(2n) { float: right; }
  .sminhead { border-top-left-radius: 4px; border-top-right-radius: 4px; background: #f7f7f7; font-size: 16px;
  line-height: 16px; font-weight: 600; padding: 10px 10px;   }
  .indetail { text-align: left; font-size: 14px; font-weight: 600;  padding:10px;   }
  .indetail span { float: right; text-align: right; font-weight: 400;}

  .fullshblk { width: 100%;    overflow: hidden; margin-top:20px; padding:10px;   }
  .halkblk { width: 49.5%; float: left; border-radius: 4px; border:1px solid #dfdfdf; }
  .halkblk:nth-child(2n) { float: right; }
  .sminhead { border-top-left-radius: 4px; border-top-right-radius: 4px; background: #f7f7f7; font-size: 16px;
  line-height: 16px; font-weight: 600; padding: 10px 10px;   }
  .indetail { text-align: left; font-size: 14px; font-weight: 600;  padding:10px;   }
  .indetail span { float: right; text-align: right; font-weight: 400;}
  .fullshblk { width: 100%; }
  .halkblk { width: 49.5%; float: right; border-radius: 4px; border:1px solid #dfdfdf;
  min-height: 120px; }
  .halkblk.setheightm { min-height: 320px;}
  .halkblk:nth-child(2n) { float: left; }

  .halkblknew { width: 100%; float: none; border-radius: 4px; border:1px solid #dfdfdf;
margin-bottom: 20px; }




  .sminhead { border-top-left-radius: 4px; border-top-right-radius: 4px; background: #dfdfdf; font-size: 16px;
  line-height: 16px; font-weight: 600; padding: 10px 10px; margin-bottom: 10px;   }
  .indetail { text-align: left; font-size: 15px; font-weight: 600;  padding:10px;   }
  .indetail span { float: right; text-align: right; font-weight: 400;}
.subchead { font-size: 19px; border-bottom: 1px solid #dfdfdf; padding:0 0 5px;
margin-bottom:20px; clear: both; overflow: hidden; width: 99%;   }

.subchead .sds1 {  float: left;   width: 230px;}
.subchead .sds2 {    width: 70%;
    float: left;
    font-size: 18px; margin-left: 13px;}




    .indetail.setfull span { float: none; display: block;
     text-align: left;}
.perset { border:1px solid #dfdfdf; padding:10px; border-radius:4px; overflow: hidden;  }
.or { text-align: center; padding:20px;  color: red; font-size: 40px; line-height: 40px; }

ul.prefetxt  { text-align: left;padding: 0px; margin: 0;}
ul.prefetxt li { width: 47%; display: inline-block; margin-bottom: 10px;}

ul.prefetxt.mdfy li{ width: 33%; }

ul.prefetxt li svg { color:#F8A765; }
.boxsect { border-radius: 8px; border:1px solid #dfdfdf;  margin-bottom: 30px;
}
.boxsecthead { border-top-left-radius:8px; border-top-right-radius:8px;
background: #f7f7f7; padding:10px; 
font-size:22px; color: #000; font-weight: 600;
  }

.fullblks { width: 100%; overflow: hidden; padding:10px;
	 }
.f1blk { float: left; width: 70%;}
.f2blk { float: right; width: 30%;}
.showtxtc { font-size: 15px; 
line-height: 16px; margin-bottom: 7px; padding:10px 0px; overflow: hidden;}
.showtxtc:nth-child(2n) { }
.showtxtc span.sh1 {  display: block;    width: 230px;
    float: left;
    font-weight: 600; }

.showtxtc span.sh2 { display: block;
    width: 58%; 
    float: left;
    font-size: 14px;
}


.imgabt { text-align: right; float: right;}
.viright { float: right;}
.setbttop { margin-top: 50px; background: #fff; padding: 20px 10px;
border:1px solid #dfdfdf; }
.mhvt {margin-top:40px; margin-bottom:40px;  font-size: 20px; line-height: 20px; }
p.tybys { font-size: 18px; line-height: 22px; font-weight: 600;
}
p.tybys span { display: block; margin-top:50px; font-weight: 400; }

.shotxt { margin-top:20px; font-size:14px;text-align:left;  }
.shotxt span{ font-size:20px; line-height:25px; display:block;  color:#000; margin-bottom:10px; }
ul.ncn  { text-align:center; padding:0px; margin:0 -15px;  }
ul.ncn li { background:#fff; vertical-align:top; display:inline-block; width:22%; height:350px;border:1px solid #dfdfdf; margin:0 15px 30px; padding:10px; }




.divncnbtm  { text-align:center; padding:0px; margin:0 -15px;  }
.divncnbtminner {  vertical-align:top; display:inline-block; width:47%; height:350px;margin:0 15px 30px; }

ul.listnav {background:#fff; border:1px solid #dfdfdf; padding:20px 20px 20px 40px;      }
ul.listnav li{ list-style-type: disc; margin-bottom:15px; text-align:left;}

.mhvt.mdfy {    text-align: left;
    margin-bottom: 10px;}

.indetailmodfy { width:100%; font-size: 15px;
 overflow: hidden;
    padding: 10px;}
.indetailmodfy:nth-child(2n) { background:#f7f7f7; }
.ds1 { width:230px; float:left;    font-weight: 600;}
.ds2 { width:70%; float:left; font-size:14px;;}

.indetailmodfy.boxs .ds2{width:39%;}
.tobnavblk { background: #F5F5F5; }
.tobnavblk .container { }
.tobnavblk ul.tabScroll { border-radius: 10px; background: #fff;  padding: 20px 10px;
 border-bottom: none; text-align: left; margin: 0; }
.tobnavblk ul.tabScroll li { display: inline-block; margin-right:20px; }

.tobnavblk ul.tabScroll li  a { padding:8px 17px; font-size: 16px; line-height: 15px;
 border:1px solid #000; color: #000; border-radius: 4px;}

.tobnavblk ul.tabScroll li  a.active, .tobnavblk ul.tabScroll li  a:hover { border:1px solid #000;
 background: #000; color: #fff;
}
.tobnavblk.stickylandp {position: fixed; top: 85px;   background: #F5F5F5;  width: 100%;  
  z-index: 2;     box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);}
.tobnavblk.stickylandp ul.tabScroll {border-radius: 0px; background: #F5F5F5;  }

.navbar-default.topshadow.navbar-reduce {box-shadow:none;}
ul.innerlab { padding:10px 7px; margin: 0; text-align: left; }
ul.innerlab:nth-child(2n) { background: #fff;}
ul.innerlab li{ font-size: 13px; color: #999; width: 32%; display: inline-block;
vertical-align: top; line-height: 13px;
    overflow-wrap: break-word; }
ul.innerlab li label{ font-weight: 600;   color: #666; font-size: 14px; display: block;}
ul.innerlab li a {color: #999;}

ul.innerlab.mexp li {width: 45%;}


.btnconatblk { text-align: center; margin:10px 0; }
a.btnconat { background: #000; display:inline-block; padding:10px 25px; width: 170px; color:#fff;
 text-align: center; border-radius: 4px; font-size: 16px; display: inline-block;   }
.inafter{border:1px solid #dfdfdf; border-radius: 4px; margin-bottom: 10px;}

.subfrmcheading { font-size: 16px;  line-height: 16px; border-bottom: 1px solid #dfdfdf; font-weight:600; 
padding-bottom: 5px; overflow: hidden; }
.frmbackbg { background:#f7f7f7;  padding: 14px;
border-radius: 10px; margin-top: 10px; margin-bottom: 20px;  }

	ul.myaccountind { text-align: right; padding: 0; margin: 10px 0 0 0; }
ul.myaccountind li{ display: inline-block; position: relative; margin:0 0 0 15px; }
.nofy { width: 16px; height: 16px; border-radius: 50%; background:#1b9f84;
color: #fff; font-size: 10px; position: absolute;top:-8px;
}
.nofyin {font-size: 9px;position: relative;    top: -4px; text-align: center;}
.notification-width {
    width: 550px;
    padding: 0;
    border-radius: 0px; 
    left: -517px;
    padding: 0px;
    top: 34px;
    min-height: 270px;
        overflow: auto;

}
.notification-heading {  padding: 10px; font-size: 16px; border-bottom: 1px solid #dfdfdf;}
.notification-heading a {float: right; color: #1b9f84;}

.notification-width:before {
    position: absolute;
    top: -7px;
    left: 96%;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #1b9f84;
    border-left: 7px solid transparent;
    content: '';
}
.btn-blue {    margin: 15px 0 30px;
    font-size: 20px;
    padding: 8px 27px; background: #1b9f84; color: #fff; }

.btn-blue.image-preview-input { background:#999; font-size: 16px; }

.myAccount .form-sec .submitfrm .btn-blue {    margin: 15px 0 30px;
    font-size: 20px;
    padding: 8px 27px; background: #1b9f84; }
.msg-pnl, .msg-pnl_sales {
    position: relative;
    padding: 10px 15px;
    margin: 0 auto;
    background-color: #f58220;
    color: #fff;
    display: none;
    text-align: center;
}
.control-label { font-size: 14px;}
.entry { position: relative;     margin-bottom: 20px;}
.boxt { position: absolute; right: -17px; top:10px;}
.setper { position: relative;}
.perval { position: absolute; right: 20px; top:10px;}
.posico { position: relative;}
.posico i {     padding: 14px 0;
    background: #dfdfdf;
    color: #fff;
    position: absolute;
    top: 0px;
    height: 44px;
    width: 25px;
    text-align: center; }
i.fa-facebook-f{ background:#3B5998; }
i.fa-twitter{ background:#33CCFF; }
i.fa-linkedin{ background:#007BB6; }
.posico .form-control.modysel  { padding-left: 35px;}
.checkbox .settt {    text-indent: -17px;  padding-left: 30px;     vertical-align: top;
}
input.ui-widget-content {     background: #fff;
    color: #333;
    position: absolute;
    top: 0px;
    width: 93%;
    padding: 0 0 0 10px;
    height: 41px;
    border: 1px solid #dfdfdf;
}

input.ui-widget-content:focus {border: 1px solid #dfdfdf;}
.ui-widget-content { padding: 0;}
.userpro { width: 27px;}
ul.mylist li .showe { font-size: 14px; padding-left: 5px; }

.shrt-desc { clear: both;}
.news-sort-list { margin-top: 20px; }
.news-sort-list ul { padding: 0px; margin: 0; text-align:left;}
.news-sort-list ul li { display: inline-block; margin-right: 10px;}
.news-sort-list ul li a.active { color: #1b9f84; border-bottom: 1px solid #1b9f84;     padding-bottom: 2px;
    font-weight: 600;}
ul.myaccountind {     padding: 10px 0 0 0; margin:0; }
ul.myaccountind li {}
ul.myaccountind li .img-sec { width: 100px; float: left;     padding-left: 10px;}
ul.myaccountind li .img-sec img { width: 100%;}

ul.scrollarea { margin-top:10px;  padding:0px;     margin: 10px 0 0 0; }
ul.scrollarea li  {padding: 0; margin: 0px; width: 100%; }
ul.myaccountind li .img-sec { }
.bdy-sec { width: 80%;
    float: left;
    font-size: 12px;
    padding-left: 10px;}
    .pipe { padding:0 5px; }
    .business-req { margin-bottom: 10px;}
ul.scrollarea li { }
ul.scrollarea li:nth-child(2n) { background: #f7f7f7; padding:10px;  }
ul.scrollarea li .name  { line-height: 18px; clear: both;  float: none; margin-bottom: 0px;}
ul.scrollarea li  .name a { color:#1b9f84;  }
.slide-blue-btn  { background:#1b9f84; padding: 7px 10px; margin-right: 15px; color: #fff; }
.tooltipLP {
    display:inline-block;
    position:relative;

    text-align:left;
}

.tooltipLP .top {
    min-width:130px; 
    top:-7px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:10px 10px;
    color:#444444;
    background-color:#EEEEEE;
    font-weight:normal;
    font-size:12px;
    border-radius:8px;
    position:absolute;
    z-index:2;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    display:none;
} 
.tooltipLP .top.active { background: #1B9F84; color: #fff; }
.tooltipLP:hover .top {
    display:block;
}

.tooltipLP .top:after {    background-color:#EEEEEE;}

.tooltipLP .top.active::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:40%;
    top:34px;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#1B9F84;
    
}


.inblk.tooltipLPshow {
    display:inline-block;
    position:relative;
    width: 100%;
    text-align:left;
}
.inblk.tooltipLPshow span{ display: block; float: none; text-align: left;}
.inblk.tooltipLPshow .top {
    width:200px; 
    top:12px;
   right: -110px;
    transform:translate(-50%, -100%);
    padding:10px 20px;
    color:#fff;
    background-color:#1B9F84;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,1);
    display:none;
} 

.inblk.tooltipLPshow:hover .top {
    display:block;
}



.tooltipLPshow .top::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    right:10px;
    bottom:-10px;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#1B9F84;
    
}




.artyshead { font-size: 14px; 
    display: inline-block;    padding-left: 10px;}
.artyshead p{ font-size: 13px; color: #666;}

.toplbk.newsb .artyshead { padding-top: 7px;}

.inet { margin-top: 4px; padding-top: 4px; border-top:1px solid #dfdfdf; line-height: 14px;  font-size: 14px; font-weight: 400; color: #333;} 
.inet span { display: block; font-weight: 400;color: #666; font-size: 13px;	 }

.homeinblk {  font-size: 13px; font-weight: 600;     padding: 5px 10px;
    background: #f7f7f7;}
.homeinblk span { float: right; font-weight: 400;}
.contact-seller { margin-top: 13px;}
.contact-ttl {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: #303030;}
    .btn-center { text-align: center;}
    .bookmark-landing{background: #f7f7f7;
    text-align: center; padding:7px 5px; }
     .bookmark-landing:hover {background: #16A085; color: #fff}
         .bookmark-landing:hover a{color: #fff;}
.contact-seler-form { background: #fff;     padding: 10px;
    margin-top: 10px;}
.contact-seler-form .contact-btn { font-size: 14px;
    color: #fff;
    border: 1px solid #16A085;
    background: #16A085;
    width: 130px;
    text-align: center;
    padding: 5px 10px;
    display: inline-block; margin:0 auto; }    

.added-info.btnpad {font-size: 14px;
    color: #fff;
    border: 1px solid #16A085;
    background: #16A085;

    text-align: center;
    padding: 5px 10px;
    display: inline-block; margin:0 auto;}


.finalpage.ribbonblk{ font-size: 16px; line-height: 15px;}
.finalpage.ribbonblk .ribbonblkinner {    height: 32px;
    margin-right: 29px;     padding: 8px 10px 3px 10px;}
.finalpage.ribbonblk .ribbonblkinner:before {    right: -14px;
    border-top: 16px solid #F58220;
    border-bottom: 16px solid #F58220;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;}

.planblst { margin-top: 50px;}
ul.bp-list {}
ul.bp-list li{ padding: 10px 0;     overflow: hidden;}
.img-sec { float: left;}
ul.bp-list li:nth-child(2n){ }
.formob {
    text-align: left;
}

.navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl
{ justify-content: inherit;}

.flefthome {
    float: left;
    width:76%;
    padding-left: 15px;
}
.frighthome { float: right; width: 34%; text-align: right;}
li.myhidep { display: none;}
.ppricecont { padding:10px; border-top:1px solid #dfdfdf; text-align: center; font-size: 14px; }
.ppricecont span{ display: block; color:#1B9F84; font-weight: 600;  }


.myAccount .cat-list.recommendations .top-sec-list {
    margin-top: 0;
}

.cat-listing .top-sec-list.Premium {
    border-top-color: #c65b19;
}
.cat-listing.investor .other-info div {
    color: #333;
    padding-top: 2px;

}
.cat-listing.investor .profile-sec .profile-ttl {
    float: left;
    margin-left: 9px;
    width: calc(100% - 62px);
    color: #333;

    font-size: 16px;
    font-weight: 600;
}
.cat-listing.investor .profile-sec .profile-ttl div {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}
.cat-listing.investor .profile-sec .profile-ttl .location {
    font-size: 14px;
    color: #666;
font-weight: 400;
    margin-top: 0;
}
ul.investor {    border-top: 2px solid #000;}
.myAccount .cat-list.recommendations ul.investor li {    border-bottom: 1px solid #dfdfdf;}

.contact-btn{}
.cat-listing.investor .profile-sec {
    padding-top: 15px;
    line-height: 16px;
}
.cat-listing.investor .profile-sec .profile-ttl {
    float: left;
    margin-left: 9px;
    width: calc(100% - 62px);
    color: #333;
font-weight: 600;
    font-size: 16px;
}
.cat-listing.investor .other-info {
    clear: both;
    float: left;
    margin-top: 15px;
    padding: 5px 10px;
    width: 100%;
    background-color: #f5f5f5;
    line-height: 20px;
    font-size: 12px;
}
.cat-listing .top-sec-list .contact-btn {
    display: block;
    padding: 1px 0 3px;
    background-color: #1f1f1f;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 15px;
}
.investor.recommendations .top-sec-list {
    height: 175px;
}

.myAccount .recommendations.investor ul li .investor-tag {
    top: -21px;
    position: absolute;
}
.myAccount .cat-list.recommendations .top-sec-list {
    margin-top: 0;
}

.cat-listing.investor .top-sec-list {
    height: 349px;
}
.investor.recommendations .top-sec-list {
    height: 175px;
}
.cat-listing .top-sec-list.Premium {
    border-top-color: #c65b19;
}
.cat-listing .top-sec-list {
    margin-top: 30px;
    position: relative;
    clear: both;
    float: left;
    width: 100%;
    height: 433px;
    overflow: hidden;
}
.top-sec-list {
    border: 1px solid #e5e5e5;
font-weight: 400;
    padding: 0 15px 20px;
}
.cat-listing .top-sec-list.Premium {
    border-top-color: #c65b19;
}
.cat-listing.investor .other-info span {
    float: right;
    color: #333;
    margin-right: 0;
    overflow: hidden;
    white-space: nowrap;
    max-width: 130px;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.primiumtag {
    position: absolute;
    top: 5px;
    font-size: 12px;
    left: 8px;
    color: #fff;
}
.primiumtag span {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 12px;
    text-transform: capitalize;
}
.tab-content .primiumtag span { 
    top: 4px;
    left: 9px;
    font-size: 14px;
    }
.mainPayment {
    position: relative;
    height: 100%;
}
.form-sec .paymentsec .paymentplan {
    background-color: #fafafa;
    border: 1px solid #e5e5e5;
    color: #666;
    font-size: 16px;
    line-height: 16px;
    padding: 7px 15px 12px;
}
.form-sec .paymentsec .price span {
    color: #666;
    display: inline-block;
}


.form-sec .paymentsec .price {
    font-size: 23px;
    color: #333;
    padding: 10px 0 15px;
    text-align: center;
}

.btn-blue.btn-file {  line-height: 16px; margin: 0 0 10px 0; }
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 9999999; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #fff; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 10px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  box-shadow: 1px 2px 15px rgb(100 100 100 / 30%);
}

/* The navigation menu links */
.sidenav a {

  text-decoration: none;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #000;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: -9px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  display:block;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

.myfull { border-bottom:1px solid #dfdfdf; padding:5px 10px 10px; margin-bottom:0px; width:100%; clear:both;    overflow: hidden; } 
.sht { font-family:15px; line-height:15px;  }
.sht span { font-weight:600; }
.myimgh { width:100px; float:left  }
.myimgh img { width:100%; border-radius:10px;}
 .ed { padding:5px; float:left; margin-left:10px; font-size:13px; line-height:15px;    margin-top: 25px;}
.shwedit { font-size:13px; line-height:15px; border-bottom:1px solid #dfdfdf;
 padding-bottom:7px; margin-bottom:7px;     float: right;
    margin-bottom: 10px; }
.myfull.backset { background:#1B9F84; color:#fff;}
.backset a { color:#fff;}
.shwedit:last-child { border-bottom:none}
.imgicon { padding-right:5px;}
.pview { background:#1B9F84; float:right; padding:3px 10px; color:#fff; border-radius:5px; } 
.selectpicker { border-radius:4px; border:1px solid #dfdfdf; width:150px; outline:none;
 padding:5px;    float: right;
    margin-bottom: 10px; }
.dropset i { text-align:right; float:right;}
ul.intraction-list { padding:0px; margin:10px 0  0 0;}
ul.intraction-list li{ font-size:13px; line-height:15px; color:#333;
 padding-bottom:10px; padding-left:25px;}
ul.intraction-list li a { color:#333; font-size:14px; line-height:15px; }

.tlss { margin: 10px auto 10px;}
.myAccount .add-on-packages .addon-ttl {

    font-size: 16px;
    background: #f7f7f7;
    padding: 10px;
    font-weight: 600;
}
.prombtn {
    background: #f58220;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 15px;
    position: absolute;
    right: 16px;
    top: 0;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #f58220;
    color: #f7f7f7;
}

.addondisablecustom {
    padding: 0;
    margin: 20px 0 40px;
}
.addondisable {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6)!important;
    z-index: 2;
}

.addondisable {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6)!important;
    z-index: 2;
}

.overlayblk {
    position: relative;
    text-align: center;
    height: 100%;
    display: table;
    margin: 0 auto;
}


.overlayblkinner {
    vertical-align: middle;
    display: table-cell;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 30px;
}

.sidenav .profile-pic img { width: 120px; border-radius: 10px;     margin-left: 10px;
    margin-bottom: 10px;}
.sidenav .profile-pic { float: left; padding-right: 10px;}
.sidenav .camera-icn { display: none;}
.sidenav .col-md-3.mdy-width-23.profile-lft-pnl {flex: 0 0 100%;
    max-width: 100%;     padding: 0px; clear: both;}

.sidenav .menu-verify {    background: #f7f7f7; padding: 10px; }
.sidenav .location { font-size: 15px; padding:0 10px; color: #000;}
.sidenav .designation { color: #333; padding:0 10px;}
.sidenav .social { text-align: left;}
.sidenav .social span { display: inline-block; padding-right: 10px; }
.sidenav .social span  a { color:#1B9F84; }
.sidenav .contact.padrm { float: left; width: 100%; clear: both;     padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;}
.sidenav .contact.padrm .btm-pad {     display: inline-block; width: 120px; }
.verified-sec i.fa-linkedin { background: transparent;}
.sidenav i.fa-linkedin { background: transparent;}

.sidenav .sidebar-menu.my-account-dropdown ul{ padding: 10px; margin: 0px;}
.sidenav .sidebar-menu.my-account-dropdown ul li{ padding: 10px 0;}
.sidenav a.upgrade {    background: #1B9F84;
    color: #fff;    display: block;
    clear: both;
    text-align: center;
    padding: 5px 10px;
  width: 180px;
    border-radius: 10px;
    margin-left: 10px;}


.sidenav .sidebar-menu.my-account-dropdown ul li a > img { padding-right: 5px;}

.sidenav .sidebar-menu.my-account-dropdown ul li ul li {     font-size: 13px;
    list-style: disc;
    margin-left: 32px;
    padding: 5px 0;}
.sidenav .sidebar-menu.my-account-dropdown ul li ul li a { font-size: 14px;}

.sidenav .edit-pfl { display: none;}

.sidenav .completed-profile.investor-detail { display: none;}
.sidenav .sidebar-menu.my-account-dropdown { display: none;}


.sidenav ul.intraction-list li a { font-size: 13px; color: #333;}
.ft { overflow: hidden; clear: both;    padding-bottom: 15px;     position: relative;    width: 100%; }
.ft .mf1{ float: left; }
.ft .mf1 a {     width: 96px;
    background: #1B9F84;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    padding: 5px 7px; margin-left: 10px;}
.mf2 { float: right;}
span.badge { float: right;  background: #1B9F84;
    color: #fff; padding: 5px 7px; border-radius: 10px; }

.profile-view-chart { width: 100%; padding-left: 0px; margin-bottom: 20px;}
.faqs_body  ul { padding: 0; margin: 0;}
.faqs_body  ul li { list-style: circle;     margin-left: 15px;	}
ul.ui-corner-all { text-align: left; margin-top: 50px; clear: both; width: 100%;}
ul.ui-corner-all li { display: block; background: transparent!important; cursor: pointer;
padding:0px; margin-bottom: 10px; color: #000; font-size: 15px; }
.form-control.image-preview-filename { background: transparent; border: none;width: 100%; clear: both; }


.clonebox.box_container { position: relative;}
.myicona { position: absolute; top: 0px;     right: -15px;}
.myAccount .frm-sec-ttl.mytc {    margin-bottom: 15px;
    margin-left: 15px;}

.myAccount  .ui-widget-content .ui-state-default > span.tagit-label { background: #dfdfdf; padding: 5px; margin-bottom: 5px;}
.myAccount  .ui-widget-content .ui-state-default a > span.text-icon{ background: #dfdfdf; padding: 5px; margin-bottom: 5px;}
.pofa { position: relative;}
.posrt { position: absolute; right: 0px; top:10px;}
.setmmy { margin-top: 15px; margin-left: 10px;}
.steychk { padding-left: 15px;}
.nav-tabs{}
.contbg { background: #f7f7f7; padding:0 20px 10px 20px; border-radius: 10px;  }
.inncblk { margin-top: 30px; }
.stati2chead { margin-top: 20px; }
.fst { width: 100%; overflow: hidden; margin-bottom: 20px; }
.t1 { float: left; width: 18%; font-weight: 600; }
.t2 { float: left; width:82%; font-weight: 400;}
.marsetb { margin-bottom: 25px; }
.tabcontact {clear: both;    width: 100%; }
.tabcontact .nav-tabs .nav-link { padding: 7px 15px; margin-right: 20px; }

ul.contblk { text-align: left; margin: 0 -10px; padding: 0px; }
ul.contblk li { border:1px solid #dfdfdf; padding:10px; 
	border-radius:4px; background: #f7f7f7; width: 31%; font-size: 14px;

font-weight: 400; display: inline-block; min-height: 285px; margin: 0 10px 20px; vertical-align: top;  }

ul.contblk li h3 { font-size: 20px; line-height: 22px; font-weight: 600; margin-bottom: 15px;}
.hname { font-size: 17px; line-height: 17px; font-weight: 600;  margin-bottom: 10px;}

.ftc { text-align: left; width: 100%; overflow: hidden; margin-top: 10px; }
.ftc span.f1 { float:left;  font-weight: 600; width: 20%; font-size: 15px; display: block; }
.ftc span.f2 {width: 80%; float: left; display: block;}

ul.contblk.incon li{ width: 22.5%; min-height: 100px; }

.ftc span.f1 { width: 25%; font-size: 14px; }
.ftc span.f2 { width: 75%; }


.abackbg  { background: #F2F2F2; padding:20px 0 30px; margin-top: 20px; }
h2.viewtst { font-size: 22px; font-weight: 700; line-height: 25px; margin-bottom: 20px; }

.boxb {padding: 30px 15px 15px 30px; background: #fff; overflow: hidden;
margin-top: 30px; }
ul.listlinks  { padding: 0; margin: 0; text-align: left; }
ul.listlinks  li{ display: inline-block; margin-right: 15px; margin-bottom: 15px;  }
ul.listlinks  li a { background: #FDE6D2; font-size: 14px; padding:5px 10px; border-radius:8px;  }
ul.listlinks  li a:hover {background:#FAC292;  color: #000;}
.abackbgwhite { background: #fff;     padding: 30px 0 50px;     margin-bottom: 20px; }

ul.serviceslist { padding: 0; margin:20px 0 0 0; text-align: center; }
ul.serviceslist li { display: inline-block; height:410px; width: 29%; margin:0 20px;     vertical-align: top;  }
img.imgbl {}
.headcl { font-size: 20px; line-height: 20px; font-weight: 700;     margin-top: 20px;
    margin-bottom: 15px; text-transform: uppercase; }
.vtxt { font-size: 14px; font-weight:400; height:160px; color: #5C5D60; line-height: 21px; }
 .headcl a { color: #000; }
 .vtxt a { color: #000; }
.brngre {
    background-color: #1b9f84;
    border-color: #1b9f84; text-transform: uppercase; color: #fff; 
    padding: 17px; font-weight: 500;
}
.brngre:hover { color: #f7f7f7; border-radius: 4px;}
.feebackbg .item { box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);   height:280px; background: #fff;  }
.clinefee {   position: relative;}
.feebackbg  { padding:20px 0 0;  }
.feebackbg .owl-carousel .owl-stage-outer { height: 320px; padding-top: 20px; }
.feebackbg  .owl-nav { display: none; }
.section-business-ex.sectiont2.section-b2.nav-arrow-a {     padding-top: 10px; } 

.feebackbg .owl-theme .owl-dots .owl-dot span { width:8px; height: 8px;
border-radius: 50%; background: #B8BCC2; outline: none; border:none;  }

.feebackbg .owl-theme .owl-dots .owl-dot.active span {width:10px; height: 10px; border:none; background:#F58220;  outline: none;}



.pad10 { padding:20px;}
.clinefee .overlry {/* position: absolute; width: 100%; height: 100%; z-index: 9;
background: rgba(255,255,255,0.7);*/}

.clkblk { margin-bottom: 10px;     overflow: hidden;}
.clkblk .in1{ font-size: 10px;
 color: #676F7C; text-align: left;    width: 50%;
    float: left; }
.clkblk .in2{ color: #FFB800; text-align: right;     width: 50%;
    float: right;}
.chead {height: 17px; margin-bottom: 10px;  font-size: 12px; color: #676F7C; font-weight:500; }
.ctxt {  font-size: 14px; color: #676F7C; font-weight: 400;
 font-style: italic; margin-bottom: 10px;}
.clkblkimg {margin-bottom: 10px;}
.cimg { float: left; width: 25px;}
.cimg img { width: 100%; border-radius: 50%;}
.cname {padding-left:0px; float: left;  font-size: 12px; font-weight: 500;}
.cname span.dane{ display: block;  font-size: 10px; font-weight: 400;}
.cname span.clogo{ width: 48px; display: block;  }
.cname span.clogo img { width: 100%;}

.myAccount .business-detail-sec { margin-bottom: 10px;  }
.myAccount .investor-name {  }
.myAccount .overview { font-weight: 600; }
.myAccount span.location { float: right; }
.myAccount .shrt-desc {    font-size: 15px;  margin-bottom: 20px; }

 .ftrbg { background: #f7f7f7; padding:20px 0;  }
  ul.weblist { padding: 0; margin: 0 -15px 0px; }
ul.weblist li{ display: inline-block; margin:0 10px 10px; list-style: none; width:8.8%;  }
ul.weblist li img { width:100%; border-radius: 4px;  }

.ftrbg h2.bex-title-a {     margin-bottom: 20px;
    margin-left: -15px; }


.owl-dots { display: none; }
.feebackbg  .owl-dots { display: block !important; }

@media only screen and (min-device-width: 1px) and (max-device-width: 767px) {
	.owl-dots { display: block; }
h2.bex-title-a { margin-left: 0; }
ul.serviceslist li { width: 90%;     padding-bottom: 20px; height: auto; }

ul.fle { float: none; text-align: center; width: 100%;}
.section-title { margin-top: 20px;}
h1.heading {font-size: 27px; line-height: 27px; padding: 0 10px;}
.artyshead {    font-size: 12px; padding: 0px;}
.artyshead p { font-size:11px;     margin-bottom: 0px;}
ul.fle li { margin-right: 5px;}
ul.cmtright { width: 100%; float: none; text-align: center;}
ul.cmtright li { font-size: 13px;}
#main { padding: 0px;}
.btn-blue.image-preview-input {     font-size: 14px; padding: 0px;}
.sec-hide { display: none;}
.showtxtc span.sh1 { float: none; width: 100%;}
.showtxtc span.sh2 { float: none; width: 100%;}
.subchead .sds1 { float: none; width: 100%;}
.subchead .sds2 { float: none; width: 100%; margin-left: 0px;} 
	.container.bex-main h1 { font-size: 25px; line-height: 25px;}
	.inblk.tooltipLPshow { display: block;}
	.summtxt { text-align: left;}
	.bex-most-popular-text { padding: 10px;}
	.bex-all-popular-bussiness-main .img-box-b img { height: 400px;}
	ul.loclist li a {    padding: 4px;
    font-size: 12px;     line-height: 12px;}
    ul.loclist li { margin: 0 2px 4px;     line-height: 12px;}
.myAccount .col-md-3.mdy-width-27 { width: 100%; float: none;}
	.sidenav .completed-profile.investor-detail { display: block;}
.sidenav .sidebar-menu.my-account-dropdown { display:block;}
	.myAccount .col-md-9.mdy-width-77 { width: 100%;}
 .navbar-default  .dropdown.myacc .dropdown-menu  { 
       width: 210px;
    left: -173px;
    top: 51px;}

.bx-insight .nav-tabs .nav-link {    margin-right: 20px;}
.nav-arrow-a .owl-arrow .owl-nav { text-align: center;}

.bex-view-all-section {    position: inherit !IMPORTANT;
    /* float: right; */
    /* width: 98%; */
    /* margin-top: -64px; */
    text-align: right;
    font-size: 16px;
    /* padding-right: 22px !important; */
    /* padding: 20px; */
    width: 100%;
    float: none;
    display: inline-block;
    padding-right: 13px!important;}
.title-box { margin-top: 0;    margin-left: 0px;}
.frmback label.frmtxt { float: left; text-align: left;}
.tobnavblk ul.tabScroll { text-align: center;}
.popimgleft { display: none;}
.flefthome { width: 30px; padding: 5px;}
.headblk .navbar-default { padding: 5px 0 0 0;}
.poptxtright {  padding-left: 10px;
    padding-top: 10px;  float: none;       padding-bottom: 10px; width: 100%;}
.tooltipfrm { display: none;}
ul.myaccountind { display: none;}
.marftop { margin-top: 30px;}
.pad-bottom  { padding-bottom: 10px;}
.formob { text-align: left;}
.mob1 { display: inline-block;}
.mob2 { display: inline-block;}
.fgts { margin-top: 30px;}
.mhidefrm { display: none;}
.tobnavblk ul.tabScroll li { margin-bottom: 5px;   margin-right:8px;}
.tobnavblk ul.tabScroll li a {    padding: 8px 7px;   font-size: 13px;    line-height: 13px;}
.tobnavblk ul.tabScroll li a span { display: none;}
.ds1 { float: none; width: 100%; display: block;}
.ds2 { float: none; width: 100%;}
.f1blk { float:none; width: 100%;}
.f2blk { float:none; width: 100%;}
#heroinner h2 {font-size: 17px; line-height: 20px;}
#heroinner p { font-size: 13px; line-height: 15px;}
#heroinner .carousel-container {      top: 50px;}
.news-sort-list ul li:first-child { display: none;}
ul.bxartlist { text-align: center; margin: 0 auto;}
.inimg { height: auto;}
.news-sort-list ul li { font-size: 12px;}
.comppro {    float: none;    margin: 0 auto;}
 .complog  {    float: none;    margin: 0 auto;} 
 .halkblk{ float: none; width: 100%;}
 ul.prefetxt.mdfy li,  ul.prefetxt li  { width: 100%;}
 .halkblk:nth-child(2n) { float: none; width: 100%;}
	.reghead { font-size: 12px; line-height: 12px;}
	.htset { font-size: 11px; height: 78px; display: block;}
.getindustryfirstinsights .getindustry-inner { width: 100%;     height: auto;
 background-size: cover; padding:30px 0;}
.priclist { text-align: center;}
ul.frmlist { margin-top: 20px; text-align: center;}
ul.frmlist li {  width: 94%;}
.section-b2.prics .card { margin-bottom: 40px;}
.navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl
{    display: inline-block;}
ul.mainul {    padding: 10px;}
ul.mainul li {    width: 48%;}
/*.owl-carousel.owl-drag .owl-item { width: 100%!important;}*/
.emailbg-inner {    padding: 12px 9px;}
.filter { display: block; text-align: center; width: 100%;     background: #126AB3;
    color: #fff;
    padding: 10px;
    font-weight: 700;
    font-size: 18px; padding:10px; margin-bottom: 0px;box-shadow: 0 0 10px 0 rgba(0,0,0,.15);
position:fixed; bottom:0px; width:100%;     z-index: 99999999999;   
cursor: pointer; }

.catleft { display: none;     position: fixed;
    z-index: 2;   width: 100%;
    bottom:-40px;
    left: 0px;     overflow: auto;
    height: 460px; width: 300px;
    padding-bottom: 85px;}



.closebtn {display: block; position: absolute; top:5px; right: 10px; }
ul.searchfilter li { width: 100%; margin:0 0 20px 0; }
ul.searchfilter li:last-child { margin:0 0 20px 0; }
.fblks, .fblks:nth-child(2n) {float: none; width: 100%;}
ul.searchfiltersmall { text-align: center; background: #f7f7f7;   padding: 10px 0;   }
ul.searchfiltersmall  li {    width: 44%;
    display: inline-block; margin-bottom: 10px;}
.filter { display: block; text-align: center; width: 100%;     background: #16A085;
    color: #fff;
    padding: 10px;
    font-weight: 700;
    font-size: 18px; padding:10px; margin-bottom: 0px;box-shadow: 0 0 10px 0 rgba(0,0,0,.15);
position:fixed; bottom:0px; width:100%;     z-index: 99999999999;   
cursor: pointer; }
.headblk .container { text-align: center;}
.headblk .navbar-default.navbar-reduce { text-align: center;}
ul.listop { text-align: center;}
.headblk .navbar-toggler  { float: left;left: 16px;}
.headblk .btn.btn-link  { float: right;}
.headblk .navbar-nav { text-align: left;     padding-left: 10px;}
.headblk .navbar-expand-lg .navbar-collapse {     width: 100%;
    clear: both;
    padding: 10px; }
#News .card-box-a, #News .card-box-b, #News .card-box-c, #News .card-box-d,
#Articles .card-box-a, #Articles .card-box-b, #Articles .card-box-c, #Articles.card-box-d
{margin-bottom: 0px!important;}
.circontent { float: none; margin-bottom: 10px;}
.bex-features-section-main img.rounded-circle.bex-mentor-img { width: 200px; margin:0 auto 20px;}    
.headblk .nav-link { display: inline-block;}
.bex-search-section { width: 100%; }
.bex-search-section span { display: block; }
.bxServices .img-box-b { width: 100%;}
ul.bex-trending-search-tab li { margin-bottom: 10px;}
.shosection  { padding:0  15px; }
.setct { margin: 0; padding: 0 15px;}

ul.mainsecdet li { width: 100%; text-align: center; margin-bottom: 20px;}
ul.bexshowdt li .b1 { display: block; width: 100%; font-weight: 700; font-size: 15px; margin-bottom: 5px;}
ul.bexshowdt li .b2 { display: none;}
.b3 {width: 100%; display: block; }
.frmsubhead { margin-top: 30px;}
ul.bexdlist li {display: block; font-size: 15px; margin-bottom: 10px;}
ul.bexdlist li.linesh { display: none;}
.df { width: 9%;}
.protxt { width: 89%;}
.mbox { width: 100%; margin-top: 10px;}
.pribox { width: 100%; text-align: left; margin-top: 10px;}
.notification-width {    width: 300px; left: 0px;}
.notification-width:before{    left: -4px;}
.bex-main { padding-top:50px;}
span.mhides { display: none!important;}
.inblk { text-align: left;}
li.folltxt span { display: none;}
.emailbg-inner {    padding: 12px 9px;
    background-size: cover;
    height: auto;}
    .trst { text-align: center;}
ul.mainul li { vertical-align: top;}
ul.mainul li a {    font-size: 13px; line-height: 16px;}  
ul.mainul li ul.submainul li a {    font-size: 12px;
    line-height: 14px;}  
ul.mainul li > ul.submainul {    margin-left: 0px;}  
#main {    margin-top: 20px;}  
.headblk {    font-size: 18px;
    line-height: 18px;}
    .tobnavblk.stickylandp {    position: static;     box-shadow: none;}
    .cinfolp { font-size: 14px;}
    ul.bex-footer-bottom-links li {    padding-right: 7px;
    border-right: 1px solid #dfdfdf;
    margin-right: 7px; font-size: 13px;}
    .priclist .priclistinner {     margin: 0 15px 50px;}
    .riads { margin: 0 auto;}
    .ttl { font-size: 20px; line-height: 22px;}
    .myAccount .left-menu { display: none;}
    .myAccount .col-md-6.mdy-width-49 { float: none; width: 100%;}
    .myAccount .business-sec .nav-tabs > li > a {font-size: 14px;
    padding: 6px 15px;}
.myAccount .mail-list {float: none;
    width: 100%;}
.myAccount .cat-list ul li .cat {    margin-top: 10px;}
.myAccount .col-md-3.mdy-width-27 .bdr {    width: 100%;}
.myAccount right-section { width: 100%;}
.ab { display: inline-block;}
.title-box h2 {    font-size: 25px;  line-height: 25px;}
#bx-insight {    padding: 0px 0;}
/*.section-business-ex .container  { padding: 0px;}*/
.owl-carousel.owl-drag .owl-item { padding: 0;}
/*.navbar-expand-lg>.container { padding: 0 15px;}*/
.breadcrumb-item { font-size: 13px;}
.flefthome {    width: 40px;
    padding: 0px;
    position: relative;
    left: -10px;
    top: 5px;}
    .bex-features-section-main {    padding: 10px;}
    .setnew {    padding:0;}
    .sectiont2.sec1 .owl-carousel { margin: 0px; padding:0; }
.sectiont2.sec3 .owl-carousel { margin: 0px; padding:0;}
.sectiont2.sec4 .owl-carousel { margin: 0px; padding:0;}
ul.mainsecdet li.linesh { display: none;}
label.frmtxt { margin-top: 10px;}
 .emailbg-inner { width: 100%;}
.frighthome {    padding-right: 10px;}
ul.pagination { text-align: left; display: inherit;}
ul.pagination li { display: inline-block;}
.selectpicker{    float: right;
    margin-bottom: 10px;}
#carouselExampleIndicators .carousel-item img { width: 100%;}    
.bex-all-popular-bussiness-main { padding: 0 15px;}
.tabcontact .nav-tabs { padding: 0px; margin: 0px;}
.tabcontact .nav-tabs .nav-link {     margin: 13px 0 0 0;   padding: 7px 3px;
    margin-right: 9px;
    font-size: 13px;}
ul.contblk li { width: 100%; min-height: auto; height: auto;}    
ul.contblk.incon li { width: 100%; min-height: auto; height: auto;}   
.t1 {    float: none; width: 100%;}
.t2{    float: none;  width: 100%;}

#bx-insight { padding-top: 40px; }
.bex-title-a { font-size: 25px; line-height: 27px; padding-top: 10px; }
.title-box { font-size: 25px; line-height: 27px; padding-top: 10px; }
  ul.weblist { text-align: center; }
    ul.weblist li { width:40%; margin-bottom: 10px; }
}
@media only screen and (min-device-width: 320px) and (max-device-width:359px) {
	a.navbar-brand.text-brand img { width: 166px; }
}
@media only screen and (min-device-width: 359px) and (max-device-width:374px) {
.tobnavblk ul.tabScroll li a { font-size: 12px;   padding: 4px 5px;}
.tobnavblk ul.tabScroll { border-radius: 0; background: transparent; text-align: center;     padding: 10px 4px;}
.myAccount .business-sec .nav-tabs > li > a {    padding: 6px 7px;}
.flefthome {    width: 35px;}
ul.socmedia li {margin-left: 12px;}
.tabcontact .nav-tabs .nav-link {padding: 7px 3px;
    margin-right: 2px;}
    .tabcontact .nav-tabs { margin: 0 -15px;}
}



@media only screen and (min-device-width: 375px) and (max-device-width:400px) {
.tobnavblk ul.tabScroll li a {    padding: 4px 5px;}
.tobnavblk ul.tabScroll { border-radius: 0; background: transparent; text-align: center;     padding: 10px 4px;}
}



@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
ul.serviceslist li { margin: 0 20px; height: 425px; width: 44%;}


	.container {    max-width: 768px;}
.getindustryfirstinsights .getindustry-inner { width: 100%;}
.f1blk {    width: 59%;}
.col-md-3.catsh {     flex: 0 0 40%;
    max-width: 40%;}
 
.col-md-9.mdy {  flex: 0 0 60%;
    max-width: 60%;} 
.col-md-9.setheading {     flex: 0 0 60%;
    max-width: 60%;}
.col-md-3.setmob {     flex: 0 0 40%;
    max-width: 40%;}


#main .container.bex-main { max-width: 760px;}
ul.bex-trending-search-tab li { margin-bottom: 20px;}
.frighthome {       float: right;
    width: 39%;
    text-align: right;}
.flefthome {    width: 40px;
    padding: 5px;}
.headblk .container {
    text-align: left;
        display: inline-block;
}
.headblk .navbar-toggler {
    float: left;
}
.headblk .navbar-expand-lg .navbar-collapse {
    width: 100%;
    clear: both;
    padding: 10px;
}


ul.frmlist li {    width: 43%;}

.nav-arrow-a .owl-arrow .owl-nav { text-align: center;}
.bex-view-all-section {    position: inherit !IMPORTANT;
   width: 98%;
    text-align: right;
    float: right;   }
.bex-most-popular-text {    color: #554580;
    font-size: 14px;
    padding: 10px;
}
.bex-card-info-main p {    line-height: 15px;}

.myAccount .col-md-6.mdy-width-49 {    max-width: 63.3%;
    flex: 0 0 62%;}

.slideblk {    width: 210px;height: 140px;}
.bx-insight .bex-card-padding-tblr {    height: 215px;}
.bxServices .img-box-b{ height:434px;}
.bxServices .img-box-b.tabsetw{ height: 134px;}
ul.loclist.settr { margin-top: 0px;}
.bex-all-popular-bussiness-main .img-box-b{ height:434px;}
.bex-all-popular-bussiness-main .img-box-b img { height:434px;}
.bxServices .bex-card-padding-tblr {    height: 300px;}
ul.loclist li {        width: 46.8%;
    vertical-align: top;
    line-height: 13px;}
ul.loclist li a {    font-size: 10px;}
.headblk .flefthome  .navbar-toggler {    margin-left: -15px;}

.row.catfull { margin: 0px;}
.filter { display: block; text-align: center; width: 100%;     background: #1B9F84;
    color: #fff;
    padding: 10px;
    font-weight: 700;
    font-size: 18px; padding:10px; margin-bottom: 0px;box-shadow: 0 0 10px 0 rgba(0,0,0,.15);
position:fixed; bottom:0px; width:100%;     z-index: 99999999999;   
cursor: pointer; }

.catleft { display: none;     position: fixed;
    z-index: 2;   width: 100%;
    bottom:-40px;
    left: 0px;     overflow: auto;
    height: 460px; width: 300px;
    padding-bottom: 85px;}

.bex-main.container  { width: 100%;    max-width: 100%;
    padding: 0px;}

.closebtn {display: block; position: absolute; top:5px; right: 10px; }
.col-md-9.mdy {     flex: 0 0 100%;
    max-width: 100%;}
ul.listop { text-align: center;}
 .emailbg-inner { width: 100%;}
   ul.weblist { text-align: center; }
    ul.weblist li { width: 20%; margin-bottom: 10px; }
    ul.socmedia li { margin-left: 1px; }

    .shosection {    padding: 40px;}
}
@media only screen and (min-device-width:1024px) and (max-device-width: 1215px) {
.getindustryfirstinsights .getindustry-inner { width: 100%;}
.headblk .container {     max-width: 1004px;     padding: 0px;
margin-right: inherit;  margin-left:inherit;}
.headblk .navbar-default.navbar-trans .nav-item,
 .headblk .navbar-default.navbar-reduce .nav-item {    margin-left: 9px;
    margin-right: 9px;}
    .emailbg-inner { width: 100%;}
    ul.frmlist li { width: 45%;}
    div.imgleft {    width: 88px;}
      ul.weblist { text-align: center; }
    ul.weblist li { width: 30%; margin-bottom: 10px; }
}


ul.setul {
   background-color: #ffffff; padding: 20px; margin:0;
}
    ul.setul li{list-style: none;
    margin-bottom: 20px;
    clear: both;
    overflow: hidden;
    width: 31.4%;
    display: inline-block;
    vertical-align: top;
    margin: 10px 5px; text-align: center; padding: 0px 20px; vertical-align: top;}
.imgblknew {
    height: 151px;
    width: 151px;
    text-align: center;
    margin: 0 auto;
}
ul.setul p{text-align: center;}

@media only screen and (min-width: 1px) and (max-width: 767px){
ul.setul{ background-color: transparent; 
    overflow: auto;
    scroll-behavior: smooth;
    white-space: nowrap; padding: 0px; margin-top: 10px;
}
ul.setul p{white-space: pre-wrap;}
ul.setul h4{white-space: pre-wrap;}
ul.setul li {
    width: 70.6%;
    padding: 0px;
	 background-color: #ffffff; 
}

.setul::-webkit-scrollbar {
  display: none;
}
ul.setul li {
    width: 72.6%;
    padding: 15px 19px;
    box-shadow: 0px 0px 9px 0px #e3e3e3;
    min-height: 434px;
    margin: 17px 15px;
}

}

/*  .showtxtc:nth-child(2n) { background: #f3f3f3;}*/
